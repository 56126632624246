import * as API from "../../configuration/apiconfig";
import swal from "sweetalert";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import constant from '../../configuration/config';
const format1 = "YYYY-MM-DD HH:mm:ss";

//new
const fieldName = {
  scheduled_date: "Publish",
  quantity: "Quantity in Legs",
  entry_price_end: "Max Price ",
  entry_price_start: "Min Price ",
  call_target: "Call Target",
  call_class: "Call Class",
  call_type: "Call Type",
  call_status: "Call Status",
  scripname: "Scrip Name",
  max_profit: "Max Profit",
  max_loss: "Max Loss",
  lot_size: "Lot Size",
  expected_profit: "Expected Profit",
  stop_loss: "Stop Loss",
  stop_loss_type: "Stop Loss Type",
  series: "Series",
  iv_condition: "IV Conditions",
  lot_size: "Lot size",
  strategy: "Strategy",
  capital_required: "Capital Required",
  action: "Action",
  strike_price: "Strike Price",
  maximum_profit: "Maximum Profit",
  max_loss: "Max Loss",
  capital_allocation: "Capital Allocation",
};

//old
// function to create a new Call (Equity / Options)
// export async function createCall(data, setScheduleDisable) {
//   // console.log("----------------- API Create Call -------------------");
//   return await API.callEndpoint(
//     "POST",
//     "Bearer",
//     "/api/calls",
//     await formartData(data[0])
//   )
//     .then((response) => {
//       try {
//         // console.log(response);
//         setScheduleDisable(false);
//         return true;
//       } catch (e) {
//         // console.log(e);

//         swal({
//           title: "An error occured, try again!",
//           text: e.error,
//           icon: "info",
//         });
//         setScheduleDisable(false);
//         return false;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       swal({
//         title: "An error occured, try again!",
//         text: error.error,
//         icon: "info",
//       });
//       setScheduleDisable(false);
//       return false;
//     });
// }

//new
export async function createCall(data, setScheduleDisable) {
  console.log("----------------- API Create Call -------------------", data);

  let data1 = await createFormartData(data[0]);
  if (data1?.call_class == "Equity") {
    delete data1?.info?.legs
  }
  let data2 = JSON.parse(JSON.stringify(data1));
  data2.now = data[0].now;

  if (checkIfAllFieldsArePresent(data2)) {
    setScheduleDisable(false);
    return false;
  } else {
  //   return await API.callEndpoint("POST", "Bearer", "/api/calls", data1)
  //     .then((response) => {
  //       try {
  //         setScheduleDisable(false);
  //         if (data1?.call_class !== "Equity") {
  //           const instruments = data1?.info?.legs?.map((elem) => elem?.instrument_id)
  //           instruments?.push(data1?.instrument_id);
  //           console.log('merge data', instruments)

  //           addActiveInstruments(instruments)
  //         } else {
  //           const instruments = data1?.instrument_id;
  //           const convertInstruments = [instruments];
  //           addActiveInstruments(convertInstruments)
  //         }
  //         return true;
  //       } catch (e) {
  //         console.log(e);
  //         swal({
  //           title: "An error occured, try again!",
  //           text: e.error,
  //           icon: "info",
  //         });
  //         setScheduleDisable(false);
  //         return false;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       swal({
  //         title: "An error occured, try again!",
  //         text: error.error,
  //         icon: "info",
  //       });
  //       setScheduleDisable(false);
  //       return false;
  //     });
  // }

  try {
    let getResponses = [];
    let apiResponse;
    let statusCode;
    let breakIndex = -1;
    const filterLegs = data1?.info?.legs?.filter((elem)=> elem?.leg_exited !== 1)

    for (let i = 0; i < filterLegs?.length; i++) {
        let response = await checkDuplicateLegs("", data[0]?.legsArray[0][i], filterLegs[i]?.instrument_id);
        if (response instanceof Error || response === null) {
            // Handle error and break the loop
            setScheduleDisable(false);
            swal({
                title: "An error occurred, try again!",
                text: response?.message || "Something went wrong!",
                icon: "info",
            });
            return false;
        }

        getResponses.push(response?.data?.hasDuplicate);
        // console.log("response", response);

        // if (response?.data?.hasDuplicate) {
        //     apiResponse = response?.data?.legs;
        //     break; // Exit the loop if any response is true
        // }
        if (response?.data?.hasDuplicate && response?.statusCode == 200) {
          apiResponse = response?.data?.legs;
          statusCode=response
          break; // Exit the loop if any response is true
      }else if(response?.statusCode == 100){
        apiResponse = response?.data?.legs;
        statusCode=response
        breakIndex = i;
        break;
      }else{
        apiResponse = response?.data?.legs;
        statusCode=response
        // break; // Exit the loop if any response is true
      }
        
    }

    const hasAnyDuplicate = getResponses.includes(true);
    if (statusCode?.statusCode == 100) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        text: statusCode?.message,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, proceed!',
      });
    
      setScheduleDisable(false);
    
      if (result.isConfirmed) {

        // const filterLegs = data1?.info?.legs?.filter((elem)=> elem?.leg_exited !== 1)
// if(breakIndex ==)
        for (let i = breakIndex; i < filterLegs?.length; i++) {
          // console.log("filterLegs[i]?.",data1?.info?.legs[i]);
    
            let response = await checkDuplicateLegs("", data[0]?.legsArray[0][i], filterLegs[i]?.instrument_id);
            if (response instanceof Error || response === null) {
                // Handle error and break the loop
                setScheduleDisable(false);
                swal({
                    title: "An error occurred, try again!",
                    text: response?.message || "Something went wrong!",
                    icon: "info",
                });
                return false;
            }
    
            getResponses.push(response?.data?.hasDuplicate);
            // console.log("response", response);
    
            if (response?.data?.hasDuplicate && response?.statusCode == 200) {
                apiResponse = response?.data?.legs;
                statusCode=response
                break; // Exit the loop if any response is true
            }else if(response?.statusCode == 100){
              apiResponse = response?.data?.legs;
              statusCode=response
              breakIndex = i;
              break;
            }else{
              apiResponse = response?.data?.legs;
              statusCode=response
              // break; // Exit the loop if any response is true
            }
        }

        let postResponse = await API.callEndpoint("POST", "Bearer", "/api/calls", data1);

        // Process the postResponse
        // setScheduleDisable(false);
        if (data1?.call_class !== "Equity") {
            const instruments = data1?.info?.legs?.map((elem) => elem?.instrument_id);
            instruments?.push(data1?.instrument_id);
            console.log('merge data', instruments);

            addActiveInstruments(instruments);
        } else {
            const instruments = data1?.instrument_id;
            const convertInstruments = [instruments];
            addActiveInstruments(convertInstruments);
        }
        
        return true;
      }
    
    } else 
    if (hasAnyDuplicate) {
        const findIndex = getResponses?.findIndex((elem) => elem == true);
        setScheduleDisable(false);

        swal({
            title: "An error occurred, try again!",
            content: {
                element: "p",
                attributes: {
                    innerHTML: `An instrument ${apiResponse[0]?.instrument_id} is opened in call id ${apiResponse[0]?.call_id} in the direction of ${apiResponse[0]?.action}`,
                    style: "line-height: 20px; text-align: center;",
                },
            },
            icon: "info",
        });

        return false;
    } else {
        // Assuming you need to do something with the responses before the POST

        let postResponse = await API.callEndpoint("POST", "Bearer", "/api/system_calls/upgrade", data1);

        // Process the postResponse
        // setScheduleDisable(false);
        if (data1?.call_class !== "Equity") {
            const instruments = data1?.info?.legs?.map((elem) => elem?.instrument_id);
            instruments?.push(data1?.instrument_id);
            console.log('merge data', instruments);

            addActiveInstruments(instruments);
        } else {
            const instruments = data1?.instrument_id;
            const convertInstruments = [instruments];
            addActiveInstruments(convertInstruments);
        }

        return true;
    }

} catch (error) {
    console.log(error);
    swal({
        title: "An error occurred, try again!",
        text: error.message || "Something went wrong!",
        icon: "info",
    });
    setScheduleDisable(false);
    return false;
}
}
}

//old
//function to update call
// export async function updateCall(data, id, setScheduleDisable) {
//   // console.log("----------------- API Update Call -------------------");
//   let Data = await formartData(data[0]);
//   Data["id"] = id;

//   return await API.callEndpoint("PATCH", "Bearer", "/api/calls", Data)
//     .then((response) => {
//       try {
//         // console.log(response);
//         setScheduleDisable(false);
//         return true;
//       } catch (e) {
//         console.log(e);

//         swal({
//           title: "An error occured, try again!",
//           text: e.error,
//           icon: "info",
//         });
//         setScheduleDisable(false);
//         return false;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       swal({
//         title: "An error occured, try again!",
//         text: error.error,
//         icon: "info",
//       });
//       setScheduleDisable(false);
//       return false;
//     });
// }

//new
export async function updateCall(data, id, setScheduleDisable, handlePublish) {
  console.log("----------------- API Update Call -------------------", data);
  let Data = await formartData(data[0]);
  Data["id"] = id;

  let data1 = JSON.parse(JSON.stringify(Data));
  data1.now = data[0].now;

  if (checkIfAllFieldsArePresent(data1)) {
    setScheduleDisable(false);
    return false;
  } else {
    // return await API.callEndpoint("PATCH", "Bearer", "/api/calls", Data)
    //   .then(async (response) => {
    //     try {
    //       // console.log(response);
    //       setScheduleDisable(false);
    //       // let callUpdateSwagger
    //       const instruments = data1?.info?.legs?.map((elem) => elem?.instrument_id)
    //       addActiveInstruments(instruments)
    //       await API.swaggerEndpoint("POST", 'System', "/system/mark_call_modifications", {}, `callId=${Data.id}`, constant.swaggerSystemToken)
    //       if (Data.call_status == 'closed') {
    //         await API.swaggerEndpoint("POST", 'System', "/system/mark_call_exit", {},
    //           `callId=${Data.id}`, constant.swaggerSystemToken)
    //       }
    //       return true;
    //     } catch (e) {
    //       console.log(e);
    //       swal({
    //         title: "An error occured, try again!",
    //         text: e.error,
    //         icon: "info",
    //       });
    //       setScheduleDisable(false);
    //       return false;
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     swal({
    //       title: "An error occured, try again!",
    //       text: error.error,
    //       icon: "info",
    //     });
    //     setScheduleDisable(false);
    //     return false;
    //   });


    
  try {
    let getResponses = [];
    let apiResponse;
    let statusCode;
    let breakIndex = -1;
    const filterLegs = data1?.info?.legs?.filter((elem)=> elem?.leg_exited !== 1)

    for (let i = 0; i < filterLegs?.length; i++) {
        let response = await checkDuplicateLegs(id, data[0]?.legsArray[0][i], filterLegs[i]?.instrument_id);
        if (response instanceof Error || response === null) {
            // Handle error and break the loop
            setScheduleDisable(false);
            swal({
                title: "An error occurred, try again!",
                text: response?.message || "Something went wrong!",
                icon: "info",
            });
            return false;
        }

        getResponses.push(response?.data?.hasDuplicate);
        // console.log("response", response);

        // if (response?.data?.hasDuplicate) {
        //     apiResponse = response?.data?.legs;
        //     break; // Exit the loop if any response is true
        // }
        if (response?.data?.hasDuplicate && response?.statusCode == 200) {
          apiResponse = response?.data?.legs;
          statusCode=response
          break; // Exit the loop if any response is true
      }else if(response?.statusCode == 100){
        apiResponse = response?.data?.legs;
        statusCode=response
        breakIndex = i;
        break;
      }else{
        apiResponse = response?.data?.legs;
        statusCode=response
        // break; // Exit the loop if any response is true
      }
    }
    
    // Optionally, you can check if getResponses contains true
    const hasAnyDuplicate = getResponses.includes(true);
    // Assuming you need to do something with the responses before the POST
    // console.log('GET responses:');

    if (statusCode?.statusCode == 100) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        text: statusCode?.message,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, proceed!',
      });
    
      setScheduleDisable(false);
    
      if (result.isConfirmed) {

        // const filterLegs = data1?.info?.legs?.filter((elem)=> elem?.leg_exited !== 1)

        for (let i = breakIndex; i < filterLegs?.length; i++) {
          // console.log("filterLegs[i]?.",data1?.info?.legs[i]);
    
            let response = await checkDuplicateLegs(id, data[0]?.legsArray[0][i], filterLegs[i]?.instrument_id);
            if (response instanceof Error || response === null) {
                // Handle error and break the loop
                setScheduleDisable(false);
                swal({
                    title: "An error occurred, try again!",
                    text: response?.message || "Something went wrong!",
                    icon: "info",
                });
                return false;
            }
    
            getResponses.push(response?.data?.hasDuplicate);
            // console.log("response", response);
    
            if (response?.data?.hasDuplicate && response?.statusCode == 200) {
                apiResponse = response?.data?.legs;
                statusCode=response
                break; // Exit the loop if any response is true
            }else if(response?.statusCode == 100){
              apiResponse = response?.data?.legs;
              statusCode=response
              breakIndex = i;
              break;
            }else{
              apiResponse = response?.data?.legs;
              statusCode=response
              // break; // Exit the loop if any response is true
            }
        }

        let postResponse = await API.callEndpoint("PATCH", "Bearer", "/api/system_calls", Data);
        
        // Process the postResponse
        const instruments = data1?.info?.legs?.map((elem) => elem?.instrument_id);
        addActiveInstruments(instruments);
        if(data[0].equityPlan == null && data[0].optionPlan == null){
          return true;
        }else{
          let payloadData = await createFormartData(data[0]);
          handlePublish(payloadData);
          return false;
        }
        
        // await API.swaggerEndpoint("POST", 'System', "/system/mark_call_modifications", {}, `callId=${Data.id}`, constant.swaggerSystemToken);
        
        // if (Data.call_status === 'closed') {
        //   await API.swaggerEndpoint("POST", 'System', "/system/mark_call_exit", {}, `callId=${Data.id}`, constant.swaggerSystemToken);
        // }
        
        return true;
      }
    
    } else if(hasAnyDuplicate){
      // Call the POST endpoint

      const findObj = getResponses?.findIndex((elem)=> elem == true)
      setScheduleDisable(false);

      swal({
        title: "An error occured, try again!",
        content: {
          element: "p",
          attributes: {
            innerHTML: `An instrument ${apiResponse[0]?.instrument_id} is opened in call id ${apiResponse[0]?.call_id} in the direction of ${apiResponse[0]?.action}`,
            style: "line-height: 20px; text-align: center;",
          },
        },
        // text: <p style={{lineHeight:"20px", textAlign:"center"}}>{`An instrument ${data1?.info?.legs[0]?.instrument_id} is opened in call id ${id} in the direction of ${data1?.info?.legs[0]?.action}`}</p>,
        icon: "info",
      });

      return false

    }else{

      let postResponse = await API.callEndpoint("PATCH", "Bearer", "/api/system_calls", Data);
      
      // Process the postResponse
      setScheduleDisable(false);
      // let callUpdateSwagger
      const instruments = data1?.info?.legs?.map((elem) => elem?.instrument_id)
      addActiveInstruments(instruments)
      if(data[0].equityPlan == null && data[0].optionPlan == null){
        return true;
      }else{
        let payloadData = await createFormartData(data[0]);
        handlePublish(payloadData);
        return false;
      }
      // await API.swaggerEndpoint("POST", 'System', "/system/mark_call_modifications", {}, `callId=${Data.id}`, constant.swaggerSystemToken)
      // if (Data.call_status == 'closed') {
      //   await API.swaggerEndpoint("POST", 'System', "/system/mark_call_exit", {},
      //     `callId=${Data.id}`, constant.swaggerSystemToken)
      // }
    }

  } catch(error) {
    console.log(error);
    swal({
      title: "An error occured, try again!",
      text: error.error || error.message,
      icon: "info",
    });
    setScheduleDisable(false);
    return false;
  };

  }
}

//old
//function to close call
// export async function closeCall(data) {
//   // console.log("----------------- API close Call -------------------");

//   return await API.callEndpoint("PATCH", "Bearer", "/api/calls", {
//     id: data.id,
//     publish: 2,
//   })
//     .then((response) => {
//       return API.callEndpoint("PUT", "Bearer", "/api/calls/closecall", data)
//         .then((response) => {
//           try {
//             // console.log(response);
//             return true;
//           } catch (e) {
//             console.log(e);

//             swal({
//               title: "An error occured, try again!",
//               text: e.error,
//               icon: "info",
//             });
//             return false;
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//           swal({
//             title: "An error occured, try again!",
//             text: error.error,
//             icon: "info",
//           });
//           return false;
//         });
//     })
//     .catch((error) => {
//       console.log(error);
//       swal({
//         title: "An error occured, try again!",
//         text: error.error,
//         icon: "info",
//       });
//       return false;
//     });
// }

//new
export async function closeCall(data) {
  console.log("----------------- API close Call -------------------");

  return API.callEndpoint("PUT", "Bearer", "/api/calls/closecall", data)
    .then(async (response) => {
      try {
        console.log(response);
        await API.swaggerEndpoint("POST", 'System', "/system/mark_call_exit", {},
          `callId=${data.id}`, constant.swaggerSystemToken)
        return true;
      } catch (e) {
        console.log(e);
        swal({
          title: "An error occured, try again!",
          text: e.error,
          icon: "info",
        });
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      swal({
        title: "An error occured, try again!",
        text: error.error,
        icon: "info",
      });
      return false;
    });
}


let searchInstrumentID = async (name) => {
  try {
    const response = await fetch(
      `${constant.swaggerUrl}instruments/search_instruments?tradingsymbol=${name?.toUpperCase()}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const instrumentData = data || "";

    return instrumentData[0];
  } catch (error) {
    return "";
  }
};



let checkDuplicateLegs = async (callId, legData, instrumentId) => {
  try {
    const response = await fetch(
       callId ? `${constant.baseUrl}/api/calls/checkDuplicateLegs?call_id=${callId}&instrument_id=${instrumentId}&action=${legData?.legSelect}&strike=${legData?.enterStrikePrice}&series=${legData?.series}&instrument_type=${legData?.legOption}` : `${constant.baseUrl}/api/calls/checkDuplicateLegs?instrument_id=${instrumentId}&action=${legData?.legSelect}&strike=${legData?.enterStrikePrice}&series=${legData?.series}&instrument_type=${legData?.legOption}`,
      {
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + API.getCookie('accessToken'),
        }
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    // const existingData = data || "";

    return data;
  } catch (error) {
    return new Error(error.message || "Something went wrong!");
}
  
};


// console.log("checkDuplicateLegs", checkDuplicateLegs()?.then((resp)=> console.log("resp", resp?.data?.hasDuplicate)));


let getInstrumentID = async (name, series, strike) => {
  try {
    const response = await fetch(
      `${constant.swaggerUrl}instruments/get_instrument?name=${name}&series=${series}&strike_price=${strike}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    let instrumentId = data?.instrument_id || "";

    return instrumentId;
  } catch (error) {
    return "";
  }
};

const processOptionsData = async (item, name) => {
  return Promise.all(item.map(async (leg) => {
    let strikePrice = (leg.enterStrikePrice ? leg.enterStrikePrice : "") + (leg.legOption ? leg.legOption : "CE");
    const instrument_id = await getInstrumentID(
      name,
      moment(leg.series).format("Do MMM'YY"),
      strikePrice
    );

    return {
      action: leg.legSelect,
      strike_price: `${leg.enterStrikePrice ? leg.enterStrikePrice : ""}${leg.legOption}`,
      entry_price_start: leg.enterPrice1,
      entry_price_end: leg.enterPrice2,
      quantity: leg.entryQty,
      showCMP: leg?.showCMP,
      leg_exited: leg?.leg_exited,
      series: moment(leg.series, "Do MMM'YY").local().format("Do MMM'YY"),
      exit_price: leg.exitPrice === undefined ? "" : leg.exitPrice,
      id: leg?.id,
      uuid: leg?.uuid,
      entry_version: leg?.entry_version,
      exit_version: leg?.exit_version,
      instrument_id: (leg.exitPrice === undefined || leg?.exitPrice === "") ? instrument_id : leg.instrument_id
    };
  }));
};

const addActiveInstruments = (instruments) => {
  let data = {
    id: instruments,
  };
  API.callEndpoint(
    "POST",
    "Bearer",
    "/api/activeinstrument",
    data
  )
    .then((response) => { })
    .catch((ex) => { });
};

//this function is used to format the data in the desired format for backend to injust the data
let formartData = async (data) => {

  console.log("data", data);
  let call_target = data.experienced
    ? "Experienced"
    : data.newbie
      ? "Newbie"
      : "Both";

  let outputData = {};
  if (data.option === "Option") {
    //option
    let call_type = "Neutral";
    if (data.optionsBulish !== "") {
      call_type = data.optionsBulish;
    } else if (data.optionsBearish !== "") {
      call_type = data.optionsBearish;
    } else if (data.optionsBidirectional !== "") {
      call_type = data.optionsBidirectional;
    }

    let trade_logics = [];
    trade_logics = data.optionTLPointArray[0].map((item, index) => {
      if (index === 0) {
        return {
          imageurl: item.img,
          smallImageurl: item.smallImageurl,
          notes: item.text,
        };
      }
    });

    let instrumentData = await searchInstrumentID(
      data.optionCompanyName?.toLowerCase() == "nifty"
        ? "Nifty 50"
        : data.optionCompanyName
    );

    let legs = await processOptionsData(data.legsArray[0], data.optionCompanyName);

    outputData = {
      call_target: call_target,
      call_class: "Options",
      call_type: call_type,
      call_status: data.callStatus,
      category_id:data.category_id,
      enter_status: data.enter_status,
      payoffcharturl: data?.payoffcharturl,
      sets: data.sets,
      show_legs: data.show_legs,
      scripname: data.optionCompanyName,
      scripname_price: await getPrice(data.optionCompanySymbol),
      scripname_symbols: data.optionCompanySymbol,
      call_version: data?.call_version,
      instrument_id: instrumentData?.instrument_id,
      info: {
        legs: legs,

        //old
        // max_profit: parseInt(data.parMaxProfit) || 0,
        // max_loss: parseInt(data.parMaxLoss) || 0,
        // lot_size: parseInt(data.parLotSize) || 0,
        // expected_profit: parseInt(data.parExpectedProfit) || 0,
        // stop_loss: parseInt(data.parStopLoss) || 0,

        //new
        max_profit: data.parMaxProfit,
        max_loss: data.parMaxLoss,
        lot_size: data.parLotSize,
        expected_profit: data.parExpectedProfit,
        stop_loss: data.parStopLoss,
        stop_loss_type: data.parSelectValue,
      },
      series: data.seriesSelect,
      iv_condition: data.conditionsSelect,
      //old
      // lot_size: parseInt(data.page2LotSize) || 0,

      //new and updated
      lot_size: parseInt(data.parLotSize) || 0,
      trade_logics:
        trade_logics[0] === null || trade_logics[0] === undefined
          ? []
          : [trade_logics[0]],
      // planid: data.optionPlan,
      publish: 1,
      scheduled_date:
        data.optionSchedule === ""
          ? ""
          : //old
          // : moment(data.optionSchedule)
          //     .utcOffset("-00:00")
          //     .format("YYYY-MM-DD hh:mm:ss"),

          //new
          moment(data.optionSchedule).format("YYYY-MM-DD HH:mm:ss"),
      notes: data.optionNotes,
      strategy: data.optionsSelect,
      //old
      // capital_required: parseInt(data.parCapitalRequied) || 0,

      //new
      capital_required: data.parCapitalRequied,
      is_notify: data.notify
    };
  }
  else {
    //equity
    let call_type = data.long === "Long" ? "Long" : "Short";
    let targets = data.target[0].map((item) => {
      return { value: item };
    });
    let trade_logics = [];

    trade_logics = data.equityTLPointArray[0].map((item, index) => {
      if (index === 0) {
        return {
          imageurl: item.img,
          smallImageurl: item.smallImageurl,
          notes: item.text,
        };
      }
    });

    let instrumentData = await searchInstrumentID(data.whichscripName);

    let legs = [];
    legs = data.legsArray[0].map((item) => {

      return {
        action: item.legSelect ? item.legSelect : 'Buy',
        strike_price: `${item.enterStrikePrice}${item.legOption}`,
        entry_price_start: data.entryPrice,
        entry_price_end: data.entryPriceQuantity,
        quantity: data.exitPriceQuantity,
        leg_exited: item?.leg_exited || 0,
        // series: moment(item.series).format("Do MMM'YY"),
        // series:item.series? moment(item.series, "Do MMM'YY").local().format("Do MMM'YY"):null,
        exit_price: item.exitPrice === undefined ? "" : item.exitPrice,
        // datastrcture
        id: item?.id,
        uuid: item?.uuid,
        entry_version: item?.entry_version,
        exit_version: item?.exit_version,
      };
    });
    outputData = {
      call_target: call_target,
      call_class: "Equity",
      call_status: data.callStatus,
      enter_status: data.enter_status,
      payoffcharturl: data?.payoffcharturl,
      show_legs: data.show_legs,
      call_type: call_type,
      scripname: data.whichscripName,
      scripname_price: await getPrice(data.whichScripSymbol),
      scripname_symbols: data.whichScripSymbol,
      instrument_id: instrumentData?.instrument_id,
      info: {
        entry_price_start: data.entryPrice,
        entry_price_end: data.entryPriceQuantity,
        legs: legs,
        //old
        // exit_price: data.exitPrice,

        //new
        exit_price: data.exitPrice === undefined ? "" : data.exitPrice,
        quanity: data.exitPriceQuantity,
        stop_loss: data.stopLoss,
        stop_loss_type: "Value",
        maximum_profit: data.maxProfit,
        max_loss: data.maxLoss,
        targets: targets,
      },
      capital_required: data.capitalRequd,
      trade_logics:
        trade_logics[0] === null || trade_logics[0] === undefined
          ? []
          : [trade_logics[0]],
      capital_allocation: data.capitalAllocation,
      planid: data.equityPlan,
      publish: 1,
      scheduled_date:
        data.schedule === ""
          ? ""
          : // old

          // : moment(data.schedule)
          //     .utcOffset("-00:00")
          //     .format("YYYY-MM-DD hh:mm:ss"),

          // new
          moment(data.schedule).format("YYYY-MM-DD HH:mm:ss"),
      is_notify: data.notify,
      notes: data.equityNotes,
    };
  }

  // console.log(outputData);
  return outputData;
};

let createFormartData = async (data) => {

  console.log("data", data);
  let call_target = data?.experienced !== undefined
    ? "Experienced"
    : data?.newbie
      ? "Newbie"
      : "Both";

  let outputData = {};
  if (data.option === "Option") {
    //option
    let call_type = "Neutral";
    if (data.optionsBulish !== "") {
      call_type = data.optionsBulish;
    } else if (data.optionsBearish !== "") {
      call_type = data.optionsBearish;
    } else if (data.optionsBidirectional !== "") {
      call_type = data.optionsBidirectional;
    }

    let trade_logics = [];
    trade_logics = data.optionTLPointArray[0].map((item, index) => {
      if (index === 0) {
        return {
          imageurl: item.img,
          smallImageurl: item.smallImageurl,
          notes: item.text,
        };
      }
    });

    let instrumentData = await searchInstrumentID(
      data.optionCompanyName?.toLowerCase() == "nifty"
        ? "Nifty 50"
        : data.optionCompanyName
    );

    let legs = await processOptionsData(data.legsArray[0], data.optionCompanyName);

    outputData = {
      call_target: call_target,
      call_class: "Options",
      call_type: call_type,
      call_status: data.callStatus,
      category_id:data.category_id,
      enter_status: data.enter_status,
      payoffcharturl: data?.payoffcharturl,
      sets: data.sets,
      show_legs: data.show_legs,
      scripname: data.optionCompanyName,
      scripname_price: await getPrice(data.optionCompanySymbol),
      scripname_symbols: data.optionCompanySymbol,
      call_version: data?.call_version,
      instrument_id: instrumentData?.instrument_id,
      info: {
        legs: legs,

        //old
        // max_profit: parseInt(data.parMaxProfit) || 0,
        // max_loss: parseInt(data.parMaxLoss) || 0,
        // lot_size: parseInt(data.parLotSize) || 0,
        // expected_profit: parseInt(data.parExpectedProfit) || 0,
        // stop_loss: parseInt(data.parStopLoss) || 0,

        //new
        max_profit: data.parMaxProfit,
        max_loss: data.parMaxLoss,
        lot_size: data.parLotSize,
        expected_profit: data.parExpectedProfit,
        stop_loss: data.parStopLoss,
        stop_loss_type: data.parSelectValue,
      },
      series: data.seriesSelect,
      iv_condition: data.conditionsSelect,
      //old
      // lot_size: parseInt(data.page2LotSize) || 0,

      //new and updated
      lot_size: parseInt(data.parLotSize) || 0,
      trade_logics:
        trade_logics[0] === null || trade_logics[0] === undefined
          ? []
          : [trade_logics[0]],
      planid: data.optionPlan,
      publish: 1,
      scheduled_date:
        data.optionSchedule === ""
          ? ""
          : //old
          // : moment(data.optionSchedule)
          //     .utcOffset("-00:00")
          //     .format("YYYY-MM-DD hh:mm:ss"),

          //new
          moment(data.optionSchedule).format("YYYY-MM-DD HH:mm:ss"),
      notes: data.optionNotes,
      strategy: data.optionsSelect,
      //old
      // capital_required: parseInt(data.parCapitalRequied) || 0,

      //new
      capital_required: data.parCapitalRequied,
      is_notify: data.notify
    };
  }
  else {
    //equity
    let call_type = data.long === "Long" ? "Long" : "Short";
    let targets = data.target[0].map((item) => {
      return { value: item };
    });
    let trade_logics = [];

    trade_logics = data.equityTLPointArray[0].map((item, index) => {
      if (index === 0) {
        return {
          imageurl: item.img,
          smallImageurl: item.smallImageurl,
          notes: item.text,
        };
      }
    });

    let instrumentData = await searchInstrumentID(data.whichscripName);

    let legs = [];
    legs = data.legsArray[0].map((item) => {

      return {
        action: item.legSelect ? item.legSelect : 'Buy',
        strike_price: `${item.enterStrikePrice}${item.legOption}`,
        entry_price_start: data.entryPrice,
        entry_price_end: data.entryPriceQuantity,
        quantity: data.exitPriceQuantity,
        leg_exited: item?.leg_exited || 0,
        // series: moment(item.series).format("Do MMM'YY"),
        // series:item.series? moment(item.series, "Do MMM'YY").local().format("Do MMM'YY"):null,
        exit_price: item.exitPrice === undefined ? "" : item.exitPrice,
        // datastrcture
        id: item?.id,
        uuid: item?.uuid,
        entry_version: item?.entry_version,
        exit_version: item?.exit_version,
      };
    });
    outputData = {
      call_target: call_target,
      call_class: "Equity",
      call_status: data.callStatus,
      enter_status: data.enter_status,
      payoffcharturl: data?.payoffcharturl,
      show_legs: data.show_legs,
      call_type: call_type,
      scripname: data.whichscripName,
      scripname_price: await getPrice(data.whichScripSymbol),
      scripname_symbols: data.whichScripSymbol,
      instrument_id: instrumentData?.instrument_id,
      info: {
        entry_price_start: data.entryPrice,
        entry_price_end: data.entryPriceQuantity,
        legs: legs,
        //old
        // exit_price: data.exitPrice,

        //new
        exit_price: data.exitPrice === undefined ? "" : data.exitPrice,
        quanity: data.exitPriceQuantity,
        stop_loss: data.stopLoss,
        stop_loss_type: "Value",
        maximum_profit: data.maxProfit,
        max_loss: data.maxLoss,
        targets: targets,
      },
      capital_required: data.capitalRequd,
      trade_logics:
        trade_logics[0] === null || trade_logics[0] === undefined
          ? []
          : [trade_logics[0]],
      capital_allocation: data.capitalAllocation,
      planid: data.equityPlan,
      publish: 1,
      scheduled_date:
        data.schedule === ""
          ? ""
          : // old

          // : moment(data.schedule)
          //     .utcOffset("-00:00")
          //     .format("YYYY-MM-DD hh:mm:ss"),

          // new
          moment(data.schedule).format("YYYY-MM-DD HH:mm:ss"),
      is_notify: data.notify,
      notes: data.equityNotes,
    };
  }

  // console.log(outputData);
  return outputData;
};

export let getPrice = async (symbol) => {
  return await API.callEndpoint("GET", "Bearer", `/api/quote?symbols=${symbol}`)
    .then((response) => {
      try {
        return response.data[0].regularMarketPrice;
      } catch (e) {
        console.log(e);
        return 0;
      }
    })
    .catch((error) => {
      console.log(error);
      return 0;
    });
};

let checkIfAllFieldsArePresent = (data) => {
  console.log(data);
  for (const prop in data) {
    if (prop === "info") {
      for (const prop1 in data[prop]) {
        if (prop1 === "legs") {
          if (checkLegs(data[prop]["legs"], data)) {
            return true;
          }
        } else if (isEmpty(data[prop][prop1], prop1, data)) {
          return true;
        }
      }
    } else if (isEmpty(data[prop], prop, data)) {
      return true;
    }
  }
  return false;
};

let isEmpty = (field, property, data) => {
  console.log(field, property);
  if (
    property === "scheduled_date" ||
    property === "now" ||
    property === "notes" ||
    property === "exit_price" ||
    property === "scripname_price" ||
    property === "trade_logics" ||
    property === "payoffcharturl" ||
    property === "exit_version"
  ) {
    return false;
  } else if (property === "strike_price") {
    let strike_price = field.substring(0, field.length - 2);
    let strike_price_option = field.slice(-2);
    console.log(strike_price, strike_price_option);
    if (strike_price === "") {
      swal({
        text: `Strike Price field cannot be empty`,
        icon: "error",
      });
      return true;
    } else if (strike_price_option === "") {
      swal({
        text: `Strike Price Option field cannot be empty`,
        icon: "error",
      });
      return true;
    }
  } else if (field === undefined || field === null || field === "") {
    swal({
      text: `${fieldName[property] === undefined ? property : fieldName[property]
        } field cannot be empty`,
      icon: "error",
    });
    return true;
  }
  return false;
};

let checkLegs = (legs, data) => {
  for (let i = 0; i < legs.length; i++) {
    for (const prop2 in legs[i]) {
      if (isEmpty(legs[i][prop2], prop2, data)) {
        return true;
      }
    }
  }
  return false;
};

export async function createIntradayCall(data) {
  let data1 = await formartData(data[0]);

  API.callEndpoint("POST", "Bearer", "api/intraday/createIntraday", data1)
    .then((response) => {
      try {
        console.log(response, "create");
        const instruments = data1?.info?.legs?.map((elem) => elem?.instrument_id)
        addActiveInstruments(instruments)
        return true;
      } catch (e) {
        console.log(e);
        swal({
          title: "An error occured, try again!",
          text: e.error,
          icon: "info",
        });

        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      swal({
        title: "An error occured, try again!",
        text: error.error,
        icon: "info",
      });

      return false;
    });
}
