import React, { Component, useRef } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Datatable from "react-bs-datatable";
import * as API from "../../configuration/apiconfig";
import swal from "sweetalert";
import { red } from "@material-ui/core/colors";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import OrdersTable from "./OrdersTable";
import constant from "../../configuration/config";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      usersOption: [],
      TradeIdOptions: [],
      brokers: [],
      list_Of_positions: [],
      ordersData: [],
      currentPage: 1,
      paginationSize: 15,
      currentLtpPrice: null,

      status: [
        { value: "DRAFT", label: "DRAFT" },
        { value: "AWAITING_ENTRY", label: "AWAITING_ENTRY" },
        { value: "ENTRY_ORDER_PLACED", label: "ENTRY_ORDER_PLACED" },
        { value: "ENTRY_ORDER_FAILED", label: "ENTRY_ORDER_FAILED" },
        { value: "PARTIAL_ENTRY", label: "PARTIAL_ENTRY" },
        { value: "ENTERED", label: "ENTERED" },
        { value: "AWAITING_EXIT", label: "AWAITING_EXIT" },
        { value: "EXIT_ORDER_PLACED", label: "EXIT_ORDER_PLACED" },
        { value: "EXIT_ORDER_FAILED", label: "EXIT_ORDER_FAILED" },
        { value: "PARTIAL_EXIT", label: "PARTIAL_EXIT" },
        { value: "EXITED", label: "EXITED" },
      ],
      typeList: [
        { id: 1, tabname: "Hedged Ideas", value: "callId", navStatus: true },
        {
          id: 2,
          tabname: " 'Simply Hedged' Funds",
          value: "bundleId",
          navStatus: false,
        },
      ],
      periods: [
        { id: 1, tabname: "Today", value: "today", navStatus: true },
        { id: 2, tabname: "This Week", value: "thisWeek", navStatus: false },
        { id: 3, tabname: "This Month", value: "thisMonth", navStatus: false },
        { id: 4, tabname: "Last Month", value: "lastMonth", navStatus: false },
        {
          id: 5,
          tabname: "Last 3 Months",
          value: "last3Months",
          navStatus: false,
        },
        { id: 6, tabname: "Custom", value: "custom", navStatus: false },
      ],
      header: [
        { title: "Broker", prop: "broker" },
        { title: "User", prop: "email" },
        { title: "Call Id", prop: "callId" },
        {
          title: "Instrument",
          prop: "instrumentSymbol",
          cell: (row) => {
            return <>{row?.instrumentSymbol ? row?.instrumentSymbol : "-"}</>;
          },
        },
        {
          prop: "leg_status",
          title: "Status",
          cell: (row) => {
            return (
              <>
                <p>{row?.leg_status ? row?.leg_status : "-"}</p>
              </>
            );
          },
        },
        {
          title: "Remarks",
          prop: "failReason",
          cell: (row) => {
            return <>{row?.failReason ? row?.failReason : "-"}</>;
          },
        },
        {
          title: "Entry At",
          prop: "entryAt",
          cell: (row) => {
            return (
              <>
                <p>
                  {row?.entryAt
                    ? moment(row?.entryAt)?.format("DD/MM/YYYY HH:MM A")
                    : "-"}
                </p>
              </>
            );
          },
        },
        {
          title: "Entry price",
          prop: "avgEntryPrice",
          cell: (row) => {
            return <>{row?.avgEntryPrice ? row?.avgEntryPrice : "-"}</>;
          },
        },
        {
          title: "Entry Qty",
          prop: "entryQtyFilled",
          cell: (row) => {
            return <>{row?.entryQtyFilled ? row?.entryQtyFilled : "-"}</>;
          },
        },
        {
          title: "Exit At",
          prop: "exitAt",
          cell: (row) => {
            return (
              <>
                <p>
                  {row?.exitAt
                    ? moment(row?.exitAt)?.format("DD/MM/YYYY HH:MM A")
                    : "-"}
                </p>
              </>
            );
          },
        },
        {
          title: "Exit Price",
          prop: "avgExitPrice",
          cell: (row) => {
            return <p> {row?.avgExitPrice ? row?.avgExitPrice : "-"}</p>;
          },
        },
        {
          title: "Exit Qty",
          prop: "exitQtyFilled",
          cell: (row) => {
            return (
              <>
                <p>{row?.exitQtyFilled ? row?.exitQtyFilled : "-"}</p>
              </>
            );
          },
        },
        {
          title: "MTM",
          prop: "",
          cell: (row) => {
            return <p>{row?.mtm !== "" ? row?.mtm?.toFixed(2) : "-"}</p>;
          },
        },
        {
          title: "PNL",
          prop: "",
          cell: (row) => {
            return <p>{row?.pnl !== "" ? row?.pnl?.toFixed(2) : "-"}</p>;
          },
        },
      ],
      curTab: { id: 1, tabname: "Hedged Ideas", value: "callId" },
      curPeriodTab: {
        id: 1,
        tabname: "Today",
        value: "today",
        navStatus: true,
      },
      showHide: false,
      fromdate: "",
      todate: "",
      selectedValue: null,
      selectedError: {},
      filterData: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.tableRef = React.createRef();
  }

  getInputValue = (keyName, value) => {
    this.setState({
      selectedValue: { ...this.state.selectedValue, [keyName]: value },
    });
  };

  componentDidMount = async () => {
    await this.getLtp();
  };

  // fetchData = async () => {
  //   const { selectedValue, fromdate, todate } = this.state;

  //   // Construct the API endpoint with query parameters based on a switch case
  //   let apiUrl = "/api/brokerdashboard";

  //   switch (true) {
  //     case !!selectedValue?.userId:
  //       apiUrl += `?userid=${selectedValue?.userId}`;
  //       break;
  //     case !!selectedValue?.callId:
  //       apiUrl += `?callid=${selectedValue?.tradeId}`;
  //       break;
  //     case !!fromdate && !!todate:
  //       apiUrl += `?from=${fromdate}&to=${todate}`;
  //       break;
  //     default:
  //       break;
  //   }

  //   await this.getOrders(apiUrl);
  // };

  getLtp = async () => {
    try {
      const response = await fetch(
        `${constant?.swaggerUrl}instruments/get_active_instruments_ltp`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      this.setState({
        currentLtpPrice: data,
      });
      this.getOrders(data);
    } catch (error) {
      swal({
        text: "An error occured, try again!",
        icon: "info",
      });
    }
  };

  getOrders = async (ltpData) => {
    await API.callEndpoint("GET", "Bearer", `/api/brokerdashboard`)
      .then((response) => {
        // return response.data.data;
        const calculateData = response.data.data.map((obj) => {
          const ltp = ltpData[`NFO:${obj?.instrumentSymbol}`] || 0;

          if (ltp !== undefined) {
            const pnl =
              obj?.avgExitPrice !== null && obj.avgEntryPrice !== null
                ? (obj.avgEntryPrice - obj?.avgExitPrice) * obj.exitQtyFilled
                : "";
            const mtm =
              obj?.leg_status == "ENTERED"
                ? obj?.avgEntryPrice !== null
                  ? (obj.avgEntryPrice - ltp) * obj.entryQtyFilled
                  : ""
                : obj.avgEntryPrice !== null && obj.avgExitPrice !== null
                  ? (obj.avgEntryPrice - obj.avgExitPrice) * obj.exitQtyFilled
                  : "";

            obj.pnl = pnl;
            obj.mtm = mtm;

            return obj;
          } else {
            return obj; // or return null, or handle the error in a different way based on your needs
          }
        });

        const getActiveResponce = calculateData?.filter((elem) =>
          elem?.hasOwnProperty(this.state.curTab.value)
        );

        const getUserEmail = calculateData?.map((elem) => {
          return { label: elem?.email, value: elem?.userId };
        });
        const uniqueUserData = Array.from(
          new Set(getUserEmail.map((obj) => obj.value))
        ).map((value) => getUserEmail.find((obj) => obj.value === value));

        const getTradeId = calculateData?.map((elem) => {
          return { value: elem?.callId, label: elem?.callId };
        });

        const uniqueTradeIdData = Array.from(
          new Set(getTradeId?.map((item) => item.value))
        ).map((value) => {
          return {
            value: value,
            label: value,
          };
        });

        const getBrokers = calculateData?.map((elem) => {
          return { value: elem?.broker, label: elem?.broker };
        });
        const uniqueBrokerData = Array.from(
          new Set(getBrokers?.map((item) => item.value))
        ).map((value) => {
          return {
            value: value,
            label: value,
          };
        });

        const today = new Date();
        const filteredData = getActiveResponce?.filter((item) => {
          const createdAtDate =
            item?.createdAt !== null && new Date(item.createdAt);
          return (
            item?.createdAt !== null &&
            createdAtDate.toDateString() === today.toDateString()
          );
        });
        this.setState({
          tableData: filteredData,
          ordersData: getActiveResponce,
          list_Of_positions: getActiveResponce,
          usersOption: uniqueUserData,
          TradeIdOptions: uniqueTradeIdData,
          brokers: uniqueBrokerData,
        });
      })
      .catch((error) => {
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  changemenuTab = (menuname, index) => {
    let newMen = [...this.state.typeList];
    newMen.map((items) => {
      return (items.navStatus = false);
    });
    newMen[index].navStatus = true;
    this.setState({
      typeList: newMen,
      curTab: {
        id: index + 1,
        tabname: menuname?.tabname,
        value: menuname.value,
      },
    });
  };

  changePeriodMenuTab = (menuname, index) => {
    let newMen = [...this.state.periods];
    newMen.map((items) => {
      return (items.navStatus = false);
    });
    newMen[index].navStatus = true;
    this.setState({
      periods: newMen,
      curPeriodTab: {
        id: index + 1,
        tabname: menuname?.tabname,
        value: menuname?.value,
        navStatus: true,
      },
      selectedValue:
      // menuname?.id !== 6
      //   ? 
      {
        ...this.state.selectedValue,
        curPeriodTab: {
          id: index + 1,
          tabname: menuname?.tabname,
          value: menuname?.value,
          navStatus: true,
        },
      }
      // : null,
    });
  };

  onShowHide = () => {
    this.setState({
      showHide: !this.state.showHide,
    });
  };
  onClear = () => {
    this.setState({
      selectedValue: null,
      curPeriodTab: {
        id: 1,
        tabname: "Today",
        value: "today",
        navStatus: true,
      }
    });
  };

  filterItemsByDate = (timeInterval) => {
    const currentDate = new Date();

    const data = this.state.ordersData;

    const filtered = data.filter((item) => {
      const itemDate = new Date(item.createdAt);

      switch (timeInterval) {
        case "today":
          return itemDate.toDateString() === currentDate.toDateString();

        case "thisWeek":
          const startOfWeek = new Date(currentDate);
          startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
          return itemDate >= startOfWeek;

        case "thisMonth":
          return itemDate.getMonth() === currentDate.getMonth();

        case "lastMonth":
          const lastMonth = new Date(currentDate);
          lastMonth.setMonth(currentDate.getMonth() - 1);
          return itemDate.getMonth() === lastMonth.getMonth();

        case "last3Months":
          const threeMonthsAgo = new Date(currentDate);
          threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
          return itemDate >= threeMonthsAgo;

        default:
          return true; // No filter applied
      }
    });

    this.setState({
      list_Of_positions: filtered,
      filterData: filtered,
    });
  };

  onSearch = () => {
    const fromSelectedDate = new Date(this.state.fromdate); // Adjust the date accordingly
    const toSelectedDate = new Date(this.state.todate); // Adjust the date accordingly

    const data = this.state.ordersData;
    const filteredResult = data?.filter((obj) => {
      const createdAtDate = new Date(obj.createdAt);
      return (
        createdAtDate >= fromSelectedDate && createdAtDate <= toSelectedDate
      );
    });

    this.setState({
      filterData: filteredResult,
    });
  };

  componentDidUpdate(prevProps, prevState) {

    if (
      prevState.selectedValue?.userId !== this.state.selectedValue?.userId ||
      prevState.selectedValue?.broker !== this.state.selectedValue?.broker ||
      prevState.selectedValue?.status !== this.state.selectedValue?.status ||
      prevState.selectedValue?.tradeId !== this.state.selectedValue?.tradeId
    ) {

      let userData = this.state.list_Of_positions?.filter((elem) => {
        const userIdMatch =
          this.state.selectedValue?.userId === undefined ||
          elem?.userId === this.state.selectedValue?.userId;
        const brokerMatch =
          this.state.selectedValue?.broker === undefined ||
          elem?.broker === this.state.selectedValue?.broker;
        const statusMatch =
          this.state.selectedValue?.status === undefined ||
          elem?.leg_status === this.state.selectedValue?.status;
        const tradeIdMatch =
          this.state.selectedValue?.tradeId === undefined ||
          elem?.callId === this.state.selectedValue?.tradeId;

        return userIdMatch && brokerMatch && statusMatch && tradeIdMatch;
      });

      this.setState({ filterData: userData });
    }

    if (prevState.curPeriodTab?.value !== this.state.curPeriodTab?.value) {
      if (this.state.curPeriodTab?.id !== 6) {
        this.filterItemsByDate(this.state.curPeriodTab?.value);
      }
    }

    if (prevState.curTab?.value !== this.state.curTab?.value) {
      this.getOrders(this.state.currentLtpPrice);
      this.setState({
        curPeriodTab: {
          id: 1,
          tabname: "Today",
          value: "today",
          navStatus: true,
        },
        filterData: [],
        list_Of_positions: [],
        tableData: [],
      });
    }
  }

  handlePageChange = (pgno) => {
    this.setState({ currentPage: pgno });

  };

  pagination = (totalPages) => {
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <div
          key={i}
          // href="#"
          className={this.state.currentPage === i ? "active" : ""}
          onClick={(e) => {
            e.preventDefault();
            this.handlePageChange(i);
          }}
        >
          {i}
        </div>
      );
    }
    return pages;
  };

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id),
    });
  }

  handlePrev() {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  }

  handleNext() {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  }

  render() {
    const { filterData, currentPage, paginationSize, tableData } = this.state;
    const indexOfLastItem = currentPage * paginationSize;
    const indexOfFirstItem = indexOfLastItem - paginationSize;
    const currentItems = this.state.selectedValue !== null ? filterData.slice(indexOfFirstItem, indexOfLastItem) : tableData?.slice(indexOfFirstItem, indexOfLastItem);

    // Generate page numbers
    const pageNumbers = [];

    if (this.state.selectedValue !== null) {

      for (
        let i = Math.max(1, currentPage - 2);
        i <=
        Math.min(
          currentPage + 2,
          Math.ceil(filterData.length / paginationSize)
        );
        i++
      ) {
        pageNumbers.push(i);
      }
    } else {
      for (
        let i = Math.max(1, currentPage - 2);
        i <=
        Math.min(
          currentPage + 2,
          Math.ceil(tableData.length / paginationSize)
        );
        i++
      ) {
        pageNumbers.push(i);
      }
    }

    // Render pagination component
    const renderPageNumbers = pageNumbers.map((number) => (
      <li
        key={number}
        id={number}
        onClick={this.handleClick}
        className={currentPage === number ? "active" : ""}
      >
        {number}
      </li>
    ));

    console.log("test", this.state.currentPage, Math.ceil(
      currentItems?.length /
      this.state.currentPage,
    ), pageNumbers?.length, currentPage + 2,
      Math.ceil(filterData.length / paginationSize))


    return (
      <section className="admin-order-tracking-page">
        <h1 className="head text-uppercase m-0">Order Tracking</h1>
        <section className="vid-mainblk d-lg-block tab-head">
          <div className="tab-wrap">
            <ul className="vid-navblk mb-3">
              {this.state.typeList?.map((menu, index) => {
                return (
                  <li
                    key={menu.id}
                    className={`d-inline-block menu-item ${menu.navStatus ? "active" : ""
                      }`}
                    onClick={() => this.changemenuTab(menu, index)}
                  >
                    <span className="d-block py-2 px-4">{menu.tabname}</span>
                  </li>
                );
              })}
            </ul>
            <div>
              <button
                type="button"
                className="filter-btn"
                onClick={this?.onClear}
                style={{ marginRight: "10px" }}
              >
                {"Clear Filters"}
              </button>
              <button
                type="button"
                className="filter-btn"
                onClick={this?.onShowHide}
              >
                {this?.state?.showHide ? "Hide Filters" : "Show Filters"}
              </button>

            </div>
          </div>
        </section>
        <section className="col-lg-12">
          <div className="period-wrap">
            <ul className="vid-navblk tab-head">
              {this.state.periods?.map((menu, index) => {
                return (
                  <li
                    key={menu.id}
                    className={`d-inline-block menu-item period-item ${this.state.curPeriodTab?.id == menu?.id &&
                        this.state.curPeriodTab.navStatus
                        ? "active"
                        : ""
                      }`}
                    onClick={() => this.changePeriodMenuTab(menu, index)}
                    value={this.state.curPeriodTab?.value}
                  >
                    <span className="d-block py-1 px-2">{menu.tabname}</span>
                  </li>
                );
              })}

              {/* <li className="d-inline-block"> */}

              {/* </li> */}
            </ul>
            <div className="orders-count">
              <div>
                <span className="order-label">Total Orders:</span>
                <span className="order-value">
                  {currentItems?.length}
                </span>
              </div>
              <div>
                <span className="order-label">Users:</span>
                <span className="order-value">
                  {this?.state?.filterData
                    ? Array.from(
                      new Set(currentItems?.map((obj) => obj.userId))
                    ).map((value) =>
                      currentItems?.find(
                        (obj) => obj.userId === value
                      )
                    )?.length
                    : Array.from(
                      new Set(currentItems?.map((obj) => obj.userId))
                    ).map((value) =>
                      currentItems?.find((obj) => obj.userId === value)
                    )?.length}
                </span>
              </div>
            </div>
          </div>
        </section>
        {this?.state?.curPeriodTab?.id == 6 && (
          <section className="col-lg-12">
            <div className="filter-dropdown date-wrap">
              <section className="col-lg-3 m-0 p-0 bs-date">
                <label className={`pe-2`}>From Date</label>
                <br />
                <DatePicker
                  placeholderText="Select Date"
                  selected={this.state.fromdate}
                  onChange={(date) => this.setState({ fromdate: date })}
                  className="date-input"
                />
              </section>
              <section className="col-lg-3 m-0 p-0 bs-date">
                <label className={`pe-2`}>To Date</label>
                <br />
                <DatePicker
                  placeholderText="Select Date"
                  selected={this.state.todate}
                  onChange={(date) => this.setState({ todate: date })}
                  className="date-input"
                />
              </section>

              {/* <section className="col-lg-2"> */}
              <button
                type="button"
                className="filter-btn"
                onClick={this.onSearch}
              >
                Search
              </button>
              {/* </section> */}
            </div>
          </section>
        )}
        {this?.state?.showHide && (
          <section className="col-lg-12">
            <div className="filter-dropdown">
              <section className="col-lg-3">
                <section className="dropdown">
                  <p>Subscription</p>
                  <aside className="member-select">
                    <Select
                      onChange={(e) => {
                        this.getInputValue("subscription", e.value);
                      }}
                      name="subscription"
                      // options={this.props.membershipSelectOptions}
                      placeholder="Select"
                    // value={this.props.membershipSelectOptions.filter(
                    //   (item) => item.value === this.props.state.subscription
                    // )}
                    />
                  </aside>
                </section>
              </section>
              <section className="col-lg-3">
                <section className="dropdown">
                  <p>Broker</p>
                  <aside className="member-select">
                    <Select
                      onChange={(e) => {
                        this.getInputValue("broker", e.value);
                      }}
                      options={this.state.brokers}
                      placeholder="Select"
                      value={this.state.brokers?.filter(
                        (item) => item.value === this.state.selectedValue?.broker
                      )}
                    />
                  </aside>
                </section>
                <p
                  className="error"
                  style={{ fontSize: "10px", color: "red", fontWeight: "500" }}
                >
                  {this.state.selectedError.broker &&
                    this.state.selectedError.broker}
                </p>
              </section>
              <section className="col-lg-3">
                <section className="dropdown">
                  <p>Users</p>
                  <aside className="member-select">
                    <Select
                      onChange={(e) => {
                        this.getInputValue("userId", e.value);
                      }}
                      options={this.state.usersOption}
                      placeholder="Select"
                      value={this.state.usersOption?.filter(
                        (item) =>
                          item.value === this.state.selectedValue?.userId
                      )}
                    />
                  </aside>
                </section>
                <p
                  className="error"
                  style={{ fontSize: "10px", color: "red", fontWeight: "500" }}
                >
                  {this.state.selectedError.userId &&
                    this.state.selectedError.userId}
                </p>
              </section>
              <section className="col-lg-3">
                <section className="dropdown">
                  <p>Trade Id</p>
                  <aside className="member-select">
                    <Select
                      onChange={(e) => {
                        this.getInputValue("tradeId", e.value);
                      }}
                      options={this.state.TradeIdOptions}
                      placeholder="Select"
                      value={this.state.TradeIdOptions?.filter(
                        (item) =>
                          item.value === this.state.selectedValue?.tradeId
                      )}
                    />
                  </aside>
                </section>
                <p
                  className="error"
                  style={{ fontSize: "10px", color: "red", fontWeight: "500" }}
                >
                  {this.state.selectedError.tradeId &&
                    this.state.selectedError.tradeId}
                </p>
              </section>
              <section className="col-lg-3">
                <section className="dropdown">
                  <p>status</p>
                  <aside className="member-select">
                    <Select
                      onChange={(e) => {
                        this.getInputValue("status", e.value);
                      }}
                      options={this.state.status}
                      placeholder="Select"
                      value={this.state.status?.filter(
                        (item) =>
                          item.value === this.state.selectedValue?.status
                      )}
                    />
                  </aside>
                </section>
                <p
                  className="error"
                  style={{ fontSize: "10px", color: "red", fontWeight: "500" }}
                >
                  {this.state.selectedError.status &&
                    this.state.selectedError.status}
                </p>
              </section>
            </div>
          </section>
        )}
        <section className={`row m-0 p-0 mt-1`}>
          <span className="order-table-title">Order Details</span>
          <aside className={`col-12 m-0 p-0`}>
            <div className="scroll-x">
              {this.state.selectedValue !== null ? (
                <>
                  {currentItems?.length > 0 &&
                    <DownloadTableExcel
                      filename="Hedged Ideas Orders Report"
                      sheet="Hedged Ideas"
                      currentTableRef={this.tableRef.current}
                    >
                      <div className="btn-wrap">
                        <button className="export-btn"> Export excel </button>
                      </div>
                    </DownloadTableExcel>
                  }

                  <div className="table-wrap" ref={this.tableRef} >
                    <OrdersTable tableBody={currentItems} />
                  </div>

                  {this.state.filterData?.length > 15 && (
                    <div class="pagination">

                      <button
                        className="pagination-btn"
                        onClick={this.handlePrev}
                        disabled={this.state.currentPage === 1}
                      >
                        &laquo; Prev
                      </button>

                      {/* Render pagination controls */}
                      <ul id="page-numbers" className="pagination-wrap">
                        {renderPageNumbers}
                      </ul>
                      <button
                        className="pagination-btn"
                        onClick={this.handleNext}
                        disabled={
                          this.state.currentPage ===
                          Math.min(
                            currentPage + 2,
                            Math.ceil(filterData.length / paginationSize)
                          )
                        }
                      >
                        Next &raquo;
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {this.state.tableData?.length > 0 &&
                    <DownloadTableExcel
                      filename="Hedged Ideas Orders Report"
                      sheet="Hedged Ideas"
                      currentTableRef={this.tableRef.current}
                    >
                      <div className="btn-wrap">
                        <button className="export-btn"> Export excel </button>
                      </div>
                    </DownloadTableExcel>
                  }
                  <div className="table-wrap" ref={this.tableRef} >
                    <OrdersTable tableBody={currentItems?.length > 0 ? currentItems : this.state.tableData} />
                  </div>

                  {this?.state?.tableData?.length > 15 && (
                    <div class="pagination">

                      <button
                        className="pagination-btn"
                        onClick={this.handlePrev}
                        disabled={this.state.currentPage === 1}
                      >
                        &laquo; Prev
                      </button>

                      {/* Render pagination controls */}
                      <ul id="page-numbers" className="pagination-wrap">
                        {renderPageNumbers}
                      </ul>
                      <button
                        className="pagination-btn"
                        onClick={this.handleNext}
                        disabled={
                          this.state.currentPage ===
                          Math.min(
                            currentPage + 2,
                            Math.ceil(this.state.tableData.length / paginationSize)
                          )
                        }
                      >
                        Next &raquo;
                      </button>
                    </div>
                  )}
                </>
              )}
              {/* )} */}
            </div>
          </aside>
        </section>
      </section>
    );
  }
}

export default Dashboard;
