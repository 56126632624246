import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import Body from "../body/body";
import Dashboard from "../dashboard/dashboard";
import Calls from "../calls/calls";

import Newcall from "../calls/newcall";
import Hedgeometer from "../hedgeometer/hedgeometer";
import RightComponent from "../calls/rightComponent";
import Bundles from "../bundles/bundles";
import CallDetails from "../calls/callDetails";
import CloseCall from "../calls/closeCall";
import Showcase from "../showcase/index";
import Videos from "../Videos/index";
// import Usermanagement from "../Usermanage/index";
import FreeUsers from "../Usermanage/index";
import PaidUsers from "../Usermanage/index2";
import ResetPage from "../resetPage/ResetPage";
import UserDetails from "../Usermanage/userDetails";
import UserTrades from "../Usermanage/userTrades";
import UserCreate from "../Usermanage/userCreate";
import Events from "../events/index";
import Sales from "../sales/index";
import * as API from "../../configuration/apiconfig";
import AdminList from "../admin/adminList";
import AdminCreate from "../admin/createAdmin";
import AdminUpdate from "../admin/updateAdmin";
import RoadBlock from "../roadblock/index";
import IntradayCall from "../intradaycalls/IntradayCall";

import CloseIntradayCall from "../intradaycalls/CloseIntradayCall";
import IntradayCallDetailsPage from "../intradaycalls/IntradayCallDetailsPage";
import ModifyIntradayCall from "../intradaycalls/ModifyIntradayCall";
import IntradayCalls from "../intradaycalls/calls";
import AuditCalls from "../audit/AuditCalls";
import PnLReport from "../pnlreport/pnlreport";
import RequestCallBack from "../request-callback/RequestCallBack";
import AuditCallDetails from "../audit/AuditCallDetails";
import LiveWebinar from "../LiveWebinar/livewebinar";
import WebinarCreate from "../LiveWebinar/webinarCreate"
import ErrorMessages from "../ErrorMessage/errorMessages"
import OrderDashboard from "../orders/Dashboard"
import SystemCalls from "../systemcalls/SystemCalls";
import SystemNewcall from "../systemcalls/SystemNewCall";


class Routing extends React.Component {
  render() {
    return (
      <Switch>
        {API.getCookie("role") === "CMSUSER" && (
          <>
            {/* <Redirect
              to={{
                pathname: "/",
              }}
            /> */}
            <Route
              exact
              path="/"
              component={(props) => <Dashboard {...props} />}
            />
            <Route
              exact
              path="/admin/list"
              component={(props) => <AdminList {...props} />}
            />
            <Route
              exact
              path="/admin/create"
              component={(props) => <AdminCreate {...props} />}
            />
            <Route
              exact
              path="/admin/update"
              component={(props) => <AdminUpdate {...props} />}
            />
            <Route
              exact
              path="/calls"
              component={(props) => <Calls {...props} />}
            />
             <Route
              exact
              path="/system_calls"
              component={(props) => <SystemCalls {...props} />}
            />
            <Route
              exact
              path="/systemcall/:calltype"
              component={(props) => <SystemNewcall {...props} />}
            />
            <Route
              exact
              path="/newcall/:calltype"
              component={(props) => <Newcall {...props} />}
            />
            <Route
              exact
              path="/intradaycall"
              component={(props) => <IntradayCalls {...props} />}
            />
            {/* <Route
              exact
              path="/audit"
              component={(props) => <AuditCalls {...props} />}
            /> */}
            <Route
              exact
              path="/audit"
              component={(props) => <AuditCalls {...props} />}
            />
            <Route
              exact
              path="/pnl-report"
              component={(props) => <PnLReport {...props} />}
            />
            <Route
              exact
              path="/intradaycall/:calltype"
              component={(props) => <IntradayCall {...props} />}
            />
            <Route exact path="/calldetails" component={CallDetails} />
            <Route exact path="/closecall" component={CloseCall} />{" "}
            <Route exact path="/auditcalldetails" component={AuditCallDetails} />
            <Route exact path="/closecall" component={CloseCall} />{' '}
            <Route
              exact
              path="/close_intradayCall"
              component={CloseIntradayCall}
            />
            <Route
              exact
              path="/modify_intradayCall"
              component={ModifyIntradayCall}
            />
            <Route
              exact
              path="/intracall_details"
              component={IntradayCallDetailsPage}
            />
            <Route exact path="/hedge-results" component={Showcase} />
            <Route exact path="/hedgeometer" component={Hedgeometer} />
            <Route exact path="/videos" component={Videos} />
            <Route
              exact
              path="/bundles/:bundletype"
              component={(props) => <Bundles {...props} />}
            />
            <Route exact path="/events" component={Events} />
            <Route exact path="/request-callback" component={RequestCallBack} />
            {/* <Route exact path="/usermanagement" component={Usermanagement} /> */}
            <Route exact path="/free-users" component={FreeUsers} />
            <Route exact path="/paid-users" component={PaidUsers} />
            <Route
              exact
              path="/usermanagement/details"
              component={(props) => <UserDetails {...props} />}
            />
            <Route
              exact
              path="/usermanagement/trades"
              component={(props) => <UserTrades {...props} />}
            />
             <Route
              exact
              path="/order-dashboard"
              component={(props) => <OrderDashboard {...props} />}
            />
            <Route
              exact
              path="/usermanagement/create"
              component={(props) => <UserCreate {...props} />}
            />
            <Route exact path="/rightcomponent" component={RightComponent} />
            <Route exact path="/reset-password" component={ResetPage} />
            <Route exact path="/road-block" component={RoadBlock} />
            <Route exact path="/live-webinar" component={LiveWebinar} />
            <Route
              exact
              path="/live-webinar/create"
              component={(props) => <WebinarCreate {...props} />}
            />
            <Route exact path="/create-errormsg" component={ErrorMessages} />

          </>
        )}
        {API.getCookie("role") === "CMSREASEARCHADMINUSER" && (
          <>
            {/* <Redirect
              to={{
                pathname: "/",
              }}
            /> */}
            <Route
              exact
              path="/"
              component={(props) => <Dashboard {...props} />}
            />
            <Route
              exact
              path="/calls"
              component={(props) => <Calls {...props} />}
            />
            <Route
              exact
              path="/newcall/:calltype"
              component={(props) => <Newcall {...props} />}
            />
            <Route
              exact
              path="/systemcall/:calltype"
              component={(props) => <SystemNewcall {...props} />}
            />
            <Route exact path="/calldetails" component={CallDetails} />
            <Route exact path="/closecall" component={CloseCall} />
            <Route
              exact
              path="/intradaycall"
              component={(props) => <IntradayCalls {...props} />}
            />
               <Route
              exact
              path="/system_calls"
              component={(props) => <SystemCalls {...props} />}
            />
            <Route
              exact
              path="/audit"
              component={(props) => <AuditCalls {...props} />}
            />

<Route exact path="/auditcalldetails" component={AuditCallDetails} />

            <Route
              exact
              path="/pnl-report"
              component={(props) => <PnLReport {...props} />}
            />

            <Route
              exact
              path="/intradaycall/:calltype"
              component={(props) => <IntradayCall {...props} />}
            />
            <Route
              exact
              path="/close_intradayCall"
              component={CloseIntradayCall}
            />
            <Route
              exact
              path="/modify_intradayCall"
              component={ModifyIntradayCall}
            />
            <Route
              exact
              path="/intracall_details"
              component={IntradayCallDetailsPage}
            />
            <Route exact path="/hedge-results" component={Showcase} />
            <Route exact path="/hedgeometer" component={Hedgeometer} />
            <Route exact path="/videos" component={Videos} />

            <Route
              exact
              path="/bundles/:bundletype"
              component={(props) => <Bundles {...props} />}
            />

            <Route exact path="/rightcomponent" component={RightComponent} />
            <Route exact path="/request-callback" component={RequestCallBack} />
            <Route exact path="/live-webinar" component={LiveWebinar} />
            <Route
              exact
              path="/live-webinar/create"
              component={(props) => <WebinarCreate {...props} />}
            />
              <Route
              exact
              path="/order-dashboard"
              component={(props) => <OrderDashboard {...props} />}
            />
          </>
        )}
        {API.getCookie("role") === "CMSSALESADMINUSER" && (
          <>
            {/* <Redirect
              to={{
                pathname: "/",
              }}
            /> */}
            <Route
              exact
              path="/"
              component={(props) => <Dashboard {...props} />}
            />
            {/* <Route exact path="/usermanagement" component={Usermanagement} /> */}

            <Route exact path="/free-users" component={FreeUsers} />
            <Route exact path="/paid-users" component={PaidUsers} />
            <Route
              exact
              path="/usermanagement/details"
              component={(props) => <UserDetails {...props} />}
            />
            <Route
              exact
              path="/usermanagement/trades"
              component={(props) => <UserTrades {...props} />}
            />
            <Route exact path="/request-callback" component={RequestCallBack} />
          </>
        )}
        {API.getCookie("role") === "CMSAUDITUSER" && (
          <>
             <Route
              exact
              path="/audit"
              component={(props) => <AuditCalls {...props} />}
            />

<Route exact path="/auditcalldetails" component={AuditCallDetails} />

          </>
        )}
      </Switch>
    );
  }
}

export default Routing;
