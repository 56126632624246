import React, { Component } from "react";
import { FaChevronLeft } from "react-icons/fa";
import IntradayLeftComponent from "./IntradayLeftComponent";
import IntradayRightComp from "./IntradayRightComp";
import { createIntradayCall } from "./ApiCalls";
import PreviewIntradayData from "./PreviewIntradayData";
import * as API from "../../configuration/apiconfig";
import Swal from "sweetalert2";
import CloseIntradayCall from "./CloseIntradayCall";
import moment from "moment";
class IntradayCall extends Component {
  constructor(props) {
    // intraday_version
    super(props);
    this.state = {
      option: false,
      long: false,
      // new
      short: false,
      payoffurl: false,
      getpayoffchartUrl: "",
      longWhichScripInput: false,
      longWhichScripGray: false,
      leftComponent: true,
      rightComponent: false,
      callTargetStatus: "",
      callStatus: "",
      scripname: "",
      // enter_status: 0,
      payoffcharturl: "",
      companyName: "",
      companyNameId: "",
      priceVal: "",
      entryPrice: "",
      entryPriceQuantity: "",
      exitPrice: "",
      exitPriceQuantity: "",
      capitalRequd: "",
      freeChecked: false,
      crownChecked: false,
      primeChecked: false,
      bulishPriceComponent: false,
      proceedBtnActive: false,
      equitySaveProceedLeftComponent: false,
      equitySaveProceedRightComponent: false,
      seriesSelect: null,
      conditionsSelect: "",
      now: "",
      schedule: "",
      equityTradeLogic: "",
      equityNotes: "",
      equityTLPointArray: [],
      // whichScrip1: false,
      optionStrategySelect: false,
      optionStrategyGray: false,
      whichScrip1Input: false,
      whichScrip1Gray: false,
      equityData: [],
      optionsSelect: "",
      strategy: false,
      optionsBulish: false,
      optionsBearish: false,
      bulishRightComp: true,
      bulishSaveProceedLeft: false,
      privewIntradaycallData: false,
      bulishSaveProceedRight: false,
      parMaxProfit: "",
      parMaxLoss: "",
      parLotSize: "",
      parExpectedProfit: "",
      parCapitalRequied: "",
      parStopLoss: "",
      parSelectValue: "",
      tradelogic: "",
      tradeLogicUrl: "",
      tradeLogicNotes: "",
      legsArray: [
        {
          action: "",
          legOption: "",
          strike_price: "",
          entry_price_start: "",
          entry_price_end: "",
          quantity: "",
          series: "",
          exitToggle: false,
          exit_price: "",
          entry_price: "",
        },
      ],
      legsSelectValue: "",
      optionCompanyName: "",
      optionPriceValue: "",
      totalCallsBlk: false,
      totalLegssBlk: false,
      optionFreeChecked: false,
      optionCrownChecked: false,
      optionPrimeChecked: false,
      optionNow: "",
      optionSchedule: "",
      optionData: [],
      optionTLPointArray: [],
      optionTradeLogic: "",
      optionNotes: "",
      callDetails: false,
      closeCall: false,
      closeProfitChecked: true,
      closeLossChecked: false,
      closeBrokenEvenChecked: false,
      callDetailsAPI: [],
      legs1SelectModify: false,
      parSelectModify: false,
      activeComponent: this.props.match.params.calltype,
      capitalAllocation: "",
      istarget: false,
      equityPlan: null,
      optionPlan: null,
      equityScrip: [],
      optionScrip: [],
      page2LotSize: "",
      modifyHeader: false,
      show_legs: 0,
      equityId: 0,
      optionId: 0,
      equityTLPointArrayNew: [],
      optionTLPointArrayNew: [],
      closeId: 0,
      backBtn: false,
      equityTLPointArrayLength: 0,
      optionTLPointArrayLength: 0,
      scheduleBtnDisable: false,
      stopLossTypeEquity: "",
      tempLoad: false,
      tradeImageURL: "",
      planid: 1,
      intraday_version: 1,
      notify: true,
    };
  }

  //call status
  updateCallStatus = (value) => {
    this.setState({ callStatus: value });
  };

  updatePlan = (value) => {
    this.setState({ planid: value });
  };
  updateScripname = (value) => {
    let lot_size = 0;
    let cap_req = 0;

    switch (value) {
      case "nifty":
        // lot_size = 50;
        lot_size = 25;
        break;
      case "banknifty":
        lot_size = 15;
        break;
      case "Finnifty":
        lot_size = 40;
        break;

      default:
        break;
    }

    switch (this.state.optionsSelect) {
      case "Long call":
      case "Long Put":
        if (
          lot_size &&
          this.state?.legsArray[0]?.quantity &&
          this.state?.legsArray[0]?.entry_price
        ) {
          cap_req =
            lot_size *
            this.state?.legsArray[0]?.quantity *
            this.state?.legsArray[0]?.entry_price;
        }

        // Lot Size * Quantity * Entry Price
        break;

      case "Bear Call":
      case "Bull Put":
        cap_req =
          value == "nifty"
            ? 32000
            : value == "banknifty"
              ? 35000
              : value == "Finnifty"
                ? 28000
                : 0;
        break;

      default:
        break;
    }
    this.setState({
      scripname: value,
      parLotSize: lot_size,
      parCapitalRequied: cap_req,
    });
  };

  optionsTradeLogic = (value) => {
    let obj = {
      tradeLogicUrl: "",
      tradeLogicNotes: "",
      tradelogic: "",
    };
    if (value === 1) {
      obj = {
        tradeLogicUrl:
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/images/uploads/e93031489feb3446161ce98a0e165bf4b2543fd890d965923d557f56cbd50c29.jpeg",
        tradeLogicNotes: "welcome to hedged",
        tradelogic: value,
      };
    } else if (value === 2) {
      obj = {
        tradeLogicUrl:
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/images/uploads/e93031489feb3446161ce98a0e165bf4b2543fd890d965923d557f56cbd50c29.jpeg",
        tradeLogicNotes: "welcome to hedged",
        tradelogic: value,
      };
    } else if (value === 3) {
      obj = {
        tradeLogicUrl:
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/images/uploads/e93031489feb3446161ce98a0e165bf4b2543fd890d965923d557f56cbd50c29.jpeg",
        tradeLogicNotes: "welcome to hedged",
        tradelogic: value,
      };
    } else if (value === 4) {
      obj = {
        tradeLogicUrl:
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/images/uploads/e93031489feb3446161ce98a0e165bf4b2543fd890d965923d557f56cbd50c29.jpeg",
        tradeLogicNotes: "welcome to hedged",
        tradelogic: value,
      };
    }
    this.setState(obj);
  };

  updateCallEnterStatus = (value) => {
    this.setState({ enter_status: value });
  };
  typeofCall = (txt) => {
    let long = false;
    let short = false;
    if (txt === "long") {
      long = true;
    } else {
      short = true;
    }

    let longWhichScripInput = true;
    if (this.state.modifyHeader) {
      longWhichScripInput = false;
    } else if (this.state.companyName !== "") {
      longWhichScripInput = false;
    }

    this.setState({
      long,
      short,
      longWhichScripInput,
    });
  };

  optionsTypeofCall = (txt) => {
    if (txt === "optionsBulish") {
      this.setState({
        optionsBulish: true,
        optionsBearish: false,
      });
    } else if (txt === "optionsBearish") {
      this.setState({ optionsBearish: true, optionsBulish: false });
    }
    let optionStrategySelect;
    let optionStrategyGray;

    if (this.state.optionsSelect === "") {
      optionStrategySelect = true;
      optionStrategyGray = false;
    } else {
      optionStrategySelect = false;
      optionStrategyGray = true;
    }

    this.setState({
      // optionsSelect: "",
      optionStrategySelect,
      optionStrategyGray,
    });

    let url = "";

    if (this.state.optionsSelect === "Long call" && txt == "optionsBulish") {
      url =
        "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Long_Call.jpg";
    }
    if (this.state.optionsSelect === "Short Call" && txt == "optionsBulish") {
      url =
        "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Bull_Call.jpg";
    }
    if (this.state.optionsSelect === "Long Put" && txt == "optionsBulish") {
      url =
        "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Bull_Put.jpg";
    }
    if (this.state.optionsSelect === "Short Put" && txt == "optionsBulish") {
      url =
        "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Short_Put.jpg";
    }
    if (this.state.optionsSelect === "Long call" && txt == "optionsBearish") {
      url =
        "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Bear_Call.jpg";
    }
    if (this.state.optionsSelect === "Short Call" && txt == "optionsBearish") {
      url =
        "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Short_Call.jpg";
    }
    if (this.state.optionsSelect === "Long Put" && txt == "optionsBearish") {
      url =
        "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Long_Put.jpg";
    }
    if (this.state.optionsSelect === "Short Put" && txt == "optionsBearish") {
      url =
        "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Bear_Put.jpg";
    }
    this.setState({
      getpayoffchartUrl: url,
    });
  };

  legsSelectOnchange = (index, getParameter) => (legsSelectValue) => {
    this.state.legsArray[index][getParameter] = legsSelectValue.value;
    const getValue = legsSelectValue.value;

    // old
    // this.setState({
    //   legsArray: this.state.legsArray,
    //   getValue,
    // });

    // new
    this.setState(
      {
        legsArray: this.state.legsArray,
        getValue,
      },
      () => {
        console.log(JSON.stringify(this.state.legsArray));
      }
    );
  };

  // Options > Legs > select onchange
  legsSelectOptionOnchange = (index, getParameter) => (legsSelectValue) => {
    this.state.legsArray[index][getParameter] = legsSelectValue.value;

    const getValue = legsSelectValue.value;
    this.setState({
      legsArray: this.state.legsArray,
      getValue,
    });
  };

  legsSelectOptionOnchange1 = (index, getParameter) => (legsSelectValue) => {
    this.state.legsArray[index][getParameter] = legsSelectValue;
    const getValue = legsSelectValue;
    this.setState({
      legsArray: this.state.legsArray,
      getValue,
    });
  };

  // Participation Data > select value
  parSelectOnChange = (parSelectValue) => {
    this.setState({
      parSelectValue: parSelectValue.value,
    });
  };

  // Option > RightComponent > input onChange > function
  legFieldsOnchange = (e, index) => {
    const { name, value } = e.target;
    this.state.legsArray[index][name] = value
      .split("")
      .filter((item) => item.match(/[0-9\\.]/i))
      .join("");
    this.setState({
      legsArray: this.state.legsArray,
      value,
    });

    if (
      (name == "entry_price" || name == "quantity") &&
      (this.state?.optionsSelect == "Long call" ||
        this.state?.optionsSelect == "Long Put")
    ) {
      let cap_req = 0;
      cap_req =
        this.state?.parLotSize *
        this.state?.legsArray[0]?.quantity *
        this.state?.legsArray[0]?.entry_price;
      this.setState({
        parCapitalRequied: cap_req,
      });
    }
  };

  legFieldsOnchangeBoolean = (e, index) => {
    const { name, checked } = e.target;
    this.state.legsArray[index][name] = checked;
    if (!checked) {
      this.state.legsArray[index]["exitPrice"] = "";
    }
    this.setState({
      legsArray: this.state.legsArray,
    });
  };

  showCmpOnchangeBoolean = (e, index) => {
    const { name, checked } = e.target;
    this.state.legsArray[index][name] = checked;
    this.setState({
      legsArray: this.state.legsArray,
    });
  };
  changeshowlegs = (e, index) => {
    const { name, checked } = e.target;
    this.setState({
      show_legs: checked == true ? 0 : 1,
    });
  };
  // Legs delete button
  legDeleteClick = (index) => {
    const getArray = [...this.state.legsArray];
    getArray.splice(index, 1);
    this.setState({
      legsArray: getArray,
    });
  };

  legsEdit = () => {
    this.setState({
      totalCallsBlk: false,
    });
  };

  totalCallEditBtn = () => {
    this.setState({
      totalCallsBlk: false,
      totalLegssBlk: true,
    });
  };

  legsDoneBtn = (e) => {
    e.preventDefault();
    this.setState({
      totalCallsBlk: true,
      totalLegssBlk: false,
    });
  };

  parDataOnChange = (e, index) => {
    // const { name, value } = e.target;
    // this.state.parData[index][name] = value
    // this.setState({
    //   parData: this.state.parData, value
    // });

    const { name, value } = e.target;
    this.setState({
      [name]: value
        .split("")
        .filter((item) => item.match(/[0-9\\.]/i))
        .join(""),
    });

    if (
      name == "parLotSize" &&
      (this.state?.optionsSelect == "Long call" ||
        this.state?.optionsSelect == "Long Put")
    ) {
      let cap_req = 0;
      cap_req =
        value *
        this.state?.legsArray[0]?.quantity *
        this.state?.legsArray[0]?.entry_price;
      this.setState({
        parCapitalRequied: cap_req,
      });
    }
  };

  optionsSelectType = (e) => {
    this.setState({
      optionsSelect: e.target.value,
      optionStrategySelect: false,
      optionStrategyGray: true,
      whichScrip1Input: true,
    });
  };
  optionsDeselect = () => {
    this.setState({
      optionsSelect: "",
      optionStrategyGray: false,
      optionStrategySelect: true,
    });
  };

  optionAddBtn = (e) => {
    // this.setState({
    //   legsArray: [...this.state.legsArray, '']
    // })

    this.setState({
      legsArray: [
        ...this.state.legsArray,
        {
          action: "",
          strike_price: "",
          entry_price_start: "",
          entry_price_end: "",
          quantity: "",
          series: "",
        },
      ],
    });
  };

  optionsSelectOnChange = (optionsSelect) => {
    let url = "";
    let optionBull = false;
    let optionBear = false;

    // optionsSelect

    switch (optionsSelect?.value) {
      case "Long call":
        optionBull = true;
        optionBear = false;
        url =
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Long_Call.jpg";
        break;
      case "Long Put":
        optionBull = false;
        optionBear = true;
        url =
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Long_Put.jpg";

        break;
      case "Bear Call":
        optionBull = false;
        optionBear = true;
        url =
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Bear_Call.jpg";

        break;
      case "Bull Put":
        optionBull = true;
        optionBear = false;
        url =
          "https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/payoffchart-images/Bull_Put.jpg";

        break;

      default:
        break;
    }
    this.setState({
      optionsBulish: optionBull,
      optionsBearish: optionBear,
    });
    if (optionsSelect.value === "Long Put") {
      this.setState({
        legsArray: [
          {
            action: "BUY",
            legOption: "PE",
            strike_price: "",
            quantity: 1,
            series: "",
          },
        ],
      });
    } else if (optionsSelect.value === "Long call") {
      this.setState({
        legsArray: [
          {
            action: "BUY",
            legOption: "CE",
            strike_price: "",
            quantity: 1,
            series: "",
          },
        ],
      });
    } else if (optionsSelect.value === "Bull Put") {
      this.setState({
        legsArray: [
          {
            action: "SELL",
            legOption: "PE",
            strike_price: "",
            quantity: 1,
            series: "",
          },
          {
            action: "BUY",
            legOption: "PE",
            strike_price: "",
            quantity: 1,
            series: "",
          },
        ],
      });
    } else if (optionsSelect.value === "Bear Call") {
      this.setState({
        legsArray: [
          {
            action: "SELL",
            legOption: "CE",
            strike_price: "",
            quantity: 1,
            series: "",
          },
          {
            action: "BUY",
            legOption: "CE",
            strike_price: "",
            quantity: 1,
            series: "",
          },
        ],
      });
    }
    // else if (optionsSelect.value === "Short Call") {
    //   this.setState({
    //     legsArray: [
    //       {
    //         action: "BUY",
    //         legOption: "CE",
    //         strike_price: "",
    //         entry_price_start: "",
    //         entry_price_end: "",
    //         quantity: 1,
    //         series: "",
    //       },
    //     ],
    //   });
    // } else if (optionsSelect.value === "Short Put") {
    //   this.setState({
    //     legsArray: [
    //       {
    //         action: "BUY",
    //         legOption: "PE",
    //         strike_price: "",
    //         entry_price_start: "",
    //         entry_price_end: "",
    //         quantity: 1,
    //         series: "",
    //       },
    //     ],
    //   });
    // }

    this.setState({
      getpayoffchartUrl: url,
      payoffurl: true,
      optionsSelect: optionsSelect.value,
      // strategy: true,  payoffchartUrl
      // optionsBulish: true,
      optionStrategySelect: false,
      optionStrategyGray: true,
      whichScrip1Input: true,

      // legsArray: [
      //   {
      //     action: 'BUY',
      //     legOption: 'CE',
      //     strike_price: '',
      //     entry_price_start: '',
      //     entry_price_end: '',
      //     quantity: 1,
      //     series: '',
      //   },
      // ],
    });
  };
  optionSaveProceedBtn = async () => {
    this.setState({
      privewIntradaycallData: true,
      bulishSaveProceedLeft: true,
      bulishSaveProceedRight: true,
      leftComponent: false,
      bulishRightComponent: false,
      backBtn: true,
    });

    let legs = [];
    for (let index = 0; index < this.state.legsArray?.length; index++) {
      legs.push({
        ...this.state.legsArray[index],
        series: moment(this.state.legsArray[index]?.series).format("Do MMM'YY"),
      });
    }

    let data = {
      optionData: {
        scripname: this.state.scripname,
        call_type: this.state.optionsBulish ? "Bullish" : "Bearish",
        strategy: this.state.optionsSelect,
        scripname_price: 3000,
        info: {
          legs: legs,
          stop_loss: this.state.parStopLoss,
          max_loss: this.state.parMaxLoss,
          lot_size: this.state.parLotSize,
          max_profit: this.state.parMaxProfit,
          stop_loss_type: this.state.parSelectValue,
          expected_profit: this.state.parExpectedProfit,
          entry_price: this?.state?.entry_price,
          target_price: this.state?.target_price,
        },

        capital_required: this.state.parCapitalRequied,
        status: this.state.callStatus,
        planid: this?.state?.planid,
        // tradelogic_note: this.state.tradeLogicNotes,
        // tradelogic_img: this.state.tradeLogicUrl,
        // tradelogic_option: this.state.tradelogic,
        is_notify:this.state.notify,
        current_price: 500,
        payoffchart_url: this.state.getpayoffchartUrl,
        intraday_version: this.state.intraday_version
      },
    };

    this.setState({
      optionData: data.optionData,
      // privewIntradaycallData: true,
    });
  };

  optionTradeLogicSubmit = (img, smallImage) => {
    if (this.state.optionTradeLogic !== "") {
      this.setState({
        // optionTLPointArray: [...this.state.optionTLPointArray, this.state.optionTradeLogic],
        optionTLPointArray: [
          ...this.state.optionTLPointArray,
          {
            img: img,
            smallImageurl: smallImage,
            text: this.state.optionTradeLogic,
          },
        ],
        optionTLPointArrayNew: [
          {
            img: img,
            smallImageurl: smallImage,
            text: this.state.optionTradeLogic,
          },
        ],
      });
      document.getElementById("trade-note").value = "";
      this.state.optionTradeLogic = "";
    }
  };

  submitDisabled = () => {
    var ifDisabled = true;
    if (
      this.state.equityTradeLogic.length === 0 &&
      this.state.optionTradeLogic.length === 0
    ) {
      ifDisabled = true;
    } else {
      ifDisabled = false;
    }
    return ifDisabled;
  };

  // option right component > show function
  optionRightComponentShowFun = (event, value) => {
    this.setState({
      bulishRightComponent: true,
      optionCompanyName: value,
      optionCompanyNameId: event.target.id,
      // optionPriceValue: child2,
      whichScrip1Input: false,
      whichScrip1Gray: true,
      optionScrip: [],
    });

    const searchElem = document.getElementById("search1");
    searchElem.style.display = "block";
    searchElem.value = "";
    // const autoCompleteElem = document.getElementById("autocomplete1");
    // autoCompleteElem.style.display = "block";
    // autoCompleteElem.innerHTML = "";
    // document.getElementById("which-scrip-show1").style.display = "block";
  };
  optionCallEdit = () => {
    this.setState({
      strategy: false,
      optionStrategySelect: true,
      optionStrategyGray: false,
    });
  };

  optionsPage2LotSize = (e) => {
    this.setState({
      page2LotSize: e.target.value
        .split("")
        .filter((item) => item.match(/[0-9\\.]/i))
        .join(""),
    });
  };

  tradeNoteOnchange = (e, parTxt) => {
    this.setState({
      [parTxt]: e.target.value,
    });
  };

  optionToggleClass = (txt, value) => {
    this.setState({
      optionNow: "",
      optionSchedule: "",
    });
    this.setState({
      [txt]: value,
    });
  };

  saveData = async () => {
    // console.log("data",this.state.optionData)
    await API.callEndpoint(
      "post",
      "Bearer",
      "/api/intraday/createIntraday",
      this.state.optionData
    )
      .then((response) => {
        if (response?.data?.status == 200) {
          this.props.history.push("/intradaycall");
        }
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  callOnChange = (parameterTxt) => {
    this.setState({
      closeProfitChecked: false,
      closeLossChecked: false,
      closeBrokenEvenChecked: false,
    });
    this.setState({
      [parameterTxt]: true,
    });
  };
  optionBackBtn = () => {
    this.setState({
      bulishSaveProceedLeft: false,
      bulishSaveProceedRight: false,
      leftComponent: true,
      bulishRightComponent: true,
      backBtn: false,
    });
  };

  updateNotify = (value) => {
    this.setState({ notify: value })
  }

  render() {
    return (
      <>
        <section className="newcall-parent">
          <h1
            className={`head text-uppercase m-0 ${this.state.closeCall ? "d-none" : "d-block"
              } ${this.state.callDetails ? "d-none" : ""}`}
          >
            <>
              {this.state.backBtn ? (
                <>
                  <FaChevronLeft
                    className="back-btn"
                    onClick={() => {
                      if (this.state.equity) {
                        this.backBtn();
                      } else {
                        this.optionBackBtn();
                      }
                    }}
                  />
                  &nbsp;
                </>
              ) : null}
              Intraday call
            </>
          </h1>
          {this.state.bulishSaveProceedLeft &&
            this.state.bulishSaveProceedRight &&
            this.state.privewIntradaycallData ? (
            <PreviewIntradayData state={this.state} />
          ) : (
            <>
              <section className="row">
                <section className="col-lg-4 leftComponentWidth">
                  <IntradayLeftComponent
                    state={this.state}
                    callClass={this.callClass}
                    typeofCall={this.typeofCall}
                    updatePlanId={this.updatePlanId}
                    autoCompleteFun={this.autoCompleteFun}
                    autoCompleteFun1={this.autoCompleteFun1}
                    updateEquityPlan={this.updateEquityPlan}
                    rightComponentShowFun={this.rightComponentShowFun}
                    editBtn={this.editBtn}
                    callTarget={this.callTarget}
                    optionsSelectOnChange={this.optionsSelectOnChange}
                    optionsSelectType={this.optionsSelectType}
                    optionsDeselect={this.optionsDeselect}
                    optionRightComponentShowFun={
                      this.optionRightComponentShowFun
                    }
                    callUserType={this.callUserType}
                    optionCallEdit={this.optionCallEdit}
                    optionWSEdit={this.optionWSEdit}
                    optionsTypeofCall={this.optionsTypeofCall}
                    updateCallStatus={this.updateCallStatus}
                    updatePlan={this.updatePlan}
                    updateScripname={this.updateScripname}
                    updateCallEnterStatus={this.updateCallEnterStatus}
                    updateCallTarget={this.updateCallTarget}
                  />
                </section>
                <section className="col-lg-6 rightComponentWidth">
                  <IntradayRightComp
                    state={this.state}
                    callsPutsSelectOnchange={this.callsPutsSelectOnchange}
                    legsSelectOnchange={this.legsSelectOnchange}
                    legsSelectOptionOnchange={this.legsSelectOptionOnchange}
                    legsSelectOptionOnchange1={this.legsSelectOptionOnchange1}
                    legFieldsOnchange={this.legFieldsOnchange}
                    legFieldsOnchangeBoolean={this.legFieldsOnchangeBoolean}
                    showCmpOnchangeBoolean={this.showCmpOnchangeBoolean}
                    changeshowlegs={this.changeshowlegs}
                    legsEdit={this.legsEdit}
                    legsDoneBtn={this.legsDoneBtn}
                    parDataOnChange={this.parDataOnChange}
                    totalCallEditBtn={this.totalCallEditBtn}
                    optionAddBtn={this.optionAddBtn}
                    legDeleteClick={this.legDeleteClick}
                    parSelectOnChange={this.parSelectOnChange}
                    submitDisabled={this.submitDisabled}
                    optionsPage2LotSize={this.optionsPage2LotSize}
                    tradeNoteOnchange={this.tradeNoteOnchange}
                    optionToggleClass={this.optionToggleClass}
                    optionsTradeLogic={this.optionsTradeLogic}
                    updateNotify={this.updateNotify}
                  />
                </section>
              </section>

              <section className="btm-strip d-block ">
                <aside className="proceed-btn-group">
                  <a
                    title="SAVE &amp; PROCEED"
                    onClick={this.optionSaveProceedBtn}
                  >
                    Save &amp; proceed
                  </a>
                </aside>
              </section>
            </>
          )}

          <section
            className={`btm-strip ${this.state.bulishSaveProceedLeft ? "d-block" : "d-none"
              } ${this.state.bulishSaveProceedRight ? "d-block" : "d-none"}`}
          >
            <aside className="proceed-btn-group">
              <a
                title="Publish"
                onClick={this.saveData}
                className={this.state.scheduleBtnDisable ? "disabled" : ""}
              >
                Publish
              </a>
            </aside>
          </section>
        </section>
      </>
    );
  }
}
export default IntradayCall;
