import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import * as API from "../../configuration/apiconfig";

const NewCallNavLink = () => {
  const { pathname } = useLocation();
  return (
    <NavLink
      exact
      activeClassName="active"
      to={"/calls"}
      isActive={() =>
        [
          "/calls",
          "/newcall/new",
          "/newcall/calldetails",
          "/newcall/modify_option",
          "/newcall/modify_equity",
        ].includes(pathname)
      }
      className="list-group-item border-0"
      title="Calls"
    >
      <span>Calls</span>
    </NavLink>
  );
};

const SystemCallLink = () => {
  const { pathname } = useLocation();
  return (
    <NavLink
      exact
      activeClassName="active"
      to={"/system_calls"}
      isActive={() =>
        [
          "/system_calls",
          // "/newcall/new",
          "/systemcall/calldetails",
          "/systemcall/modify_option",
          "/systemcall/modify_equity",
        ].includes(pathname)
      }
      className="list-group-item border-0"
      title="System Calls"
    >
      <span>System Calls</span>
    </NavLink>
  );
};

const IntradayCallNavLink = () => {
  const { pathname } = useLocation();
  return (
    <NavLink
      exact
      activeClassName="active"
      to={"/intradaycall"}
      isActive={() =>
        [
          "/intradaycall",
          "/newintradaycall/new",
          "/newintradaycall/calldetails",
          "/newintradaycall/modify_option",
          "/newintradaycall/modify_equity",
        ].includes(pathname)
      }
      className="list-group-item border-0"
      title="Intradaycalls"
    >
      <span>Intraday Calls</span>
    </NavLink>
  );
};

const BundleNavLink = () => {
  const { pathname } = useLocation();
  return (
    <NavLink
      exact
      to={"/bundles/table"}
      isActive={() =>
        [
          "/bundles/table",
          "/bundles/tabs",
          "/bundles/details",
          "/bundles/modify",
        ].includes(pathname)
      }
      className="list-group-item border-0"
      title="Bundles"
      id="bundle"
    >
      <span>Live Funds</span>
    </NavLink>
  );
};
const AdminNavLink = () => {
  const { pathname } = useLocation();
  return (
    <NavLink
      exact
      to={"/admin/list"}
      isActive={() =>
        ["/admin/list", "/admin/create", "/admin/update"].includes(pathname)
      }
      className="list-group-item border-0"
      title="Admin"
      id="admin"
    >
      <span>Admin Management</span>
    </NavLink>
  );
};

const Sales = () => {
  const { pathname } = useLocation();
  return (
    <NavLink
      exact
      activeClassName="active"
      to={"/sales"}
      className="list-group-item border-0"
      title="Events"
      isActive={() => ["/sales"].includes(pathname)}
    >
      <span>Sales</span>
    </NavLink>
  );
};

const AuditCallNavLink = () => {
  const { pathname } = useLocation();
  return (
    <NavLink
      exact
      activeClassName="active"
      to={"/audit"}
      isActive={() => ["/audit"].includes(pathname)}
      className="list-group-item border-0"
      title="Audit"
    >
      <span>Audit</span>
    </NavLink>
  );
};

const PnLNavLink = () => {
  const { pathname } = useLocation();
  return (
    <NavLink
      exact
      activeClassName="active"
      to={"/pnl-report"}
      isActive={() =>
        [
          "/pnl-report"
        ].includes(pathname)
      }
      className="list-group-item border-0"
      title="PnL Report"
    >
      <span>PnL Report</span>
    </NavLink>
  );
};

class Sidebar extends React.Component {
  render() {
    return (
      <section className="admin-sidebar">
        <div className="list-group">
          {API.getCookie("role") === "CMSUSER" && (
            <>
              <NavLink
                exact
                activeClassName="active"
                to={"/"}
                className="list-group-item border-0"
                title="Dashboard"
              >
                <span>Dashboard</span>
              </NavLink>
              <AuditCallNavLink />
              <IntradayCallNavLink />
              <NewCallNavLink />
              <SystemCallLink/>
              <PnLNavLink />
              <NavLink
                exact
                activeClassName="active"
                to={"/request-callback"}
                className="list-group-item border-0"
                title="Events"
              >
                <span>Request Callbacks</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/hedge-results"}
                className="list-group-item border-0"
                title="Hedged results"
              >
                <span>Hedged results</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/hedgeometer"}
                className="list-group-item border-0"
                title="Hedgeometer"
              >
                <span>Hedgeometer</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/videos"}
                className="list-group-item border-0"
                title="Videos"
              >
                <span>Videos</span>
              </NavLink>
              {/* <Sales /> */}
              <BundleNavLink />
              <NavLink
                exact
                activeClassName="active"
                to={"/events"}
                className="list-group-item border-0"
                title="Events"
              >
                <span>Events</span>
              </NavLink>

              <NavLink
                exact
                activeClassName="active"
                to={"/order-dashboard"}
                className="list-group-item border-0"
                title="Orders Dashboard"
              >
                <span>Orders Dashboard</span>
              </NavLink>

              <AdminNavLink />
              {/* <NavLink
                exact
                activeClassName="active"
                to={"/usermanagement"}
                className="list-group-item border-0"
                title="User Management"
              >
                <span>User Management</span>
              </NavLink> */}
              <NavLink
                exact
                activeClassName="active"
                to={"/free-users"}
                className="list-group-item border-0"
                title="User Management"
              >
                <span>Free Users</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/paid-users"}
                className="list-group-item border-0"
                title="User Management"
              >
                <span>Paid Users</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/road-block"}
                className="list-group-item border-0"
                title="User Management"
              >
                <span>Road Block</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/live-webinar"}
                className="list-group-item border-0"
                title="Live Webinar"
              >
                <span>Live Webinar</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/create-errormsg"}
                className="list-group-item border-0"
                title="Error Messages"
              >
                <span>Error Message</span>
              </NavLink>
              <a
                href="#"
                className="list-group-item border-0"
                title="Analytics"
              >
                <span>Analytics</span>
              </a>
              <a href="#" className="list-group-item border-0" title="Settings">
                <span>Settings</span>
              </a>
            </>
          )}
          {API.getCookie("role") === "CMSREASEARCHADMINUSER" && (
            <>
              <NavLink
                exact
                activeClassName="active"
                to={"/"}
                className="list-group-item border-0"
                title="Dashboard"
              >
                <span>Dashboard</span>
              </NavLink>
              <AuditCallNavLink />
              <IntradayCallNavLink />
              <NewCallNavLink />
              <SystemCallLink/>
              <PnLNavLink />
              <NavLink
                exact
                activeClassName="active"
                to={"/hedge-results"}
                className="list-group-item border-0"
                title="Hedged results"
              >
                <span>Hedged results</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/hedgeometer"}
                className="list-group-item border-0"
                title="Hedgeometer"
              >
                <span>Hedgeometer</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/videos"}
                className="list-group-item border-0"
                title="Videos"
              >
                <span>Videos</span>
              </NavLink>
              {/* <Sales /> */}
              <BundleNavLink />
              <NavLink
                exact
                activeClassName="active"
                to={"/order-dashboard"}
                className="list-group-item border-0"
                title="Orders Dashboard"
              >
                <span>Orders Dashboard</span>
              </NavLink>
            </>
          )}
          {API.getCookie("role") === "CMSSALESADMINUSER" && (
            <>
              <NavLink
                exact
                activeClassName="active"
                to={"/"}
                className="list-group-item border-0"
                title="Dashboard"
              >
                <span>Dashboard</span>
              </NavLink>
              {/* <NavLink
                exact
                activeClassName="active"
                to={"/usermanagement"}
                className="list-group-item border-0"
                title="User Management"
              >
                <span>User Management</span>
              </NavLink> */}
              <NavLink
                exact
                activeClassName="active"
                to={"/request-callback"}
                className="list-group-item border-0"
                title="Events"
              >
                <span>Request Callbacks</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/free-users"}
                className="list-group-item border-0"
                title="User Management"
              >
                <span>Free Users</span>
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                to={"/paid-users"}
                className="list-group-item border-0"
                title="User Management"
              >
                <span>Paid Users</span>
              </NavLink>
            </>
          )}
            {API.getCookie("role") === "CMSAUDITUSER" && (
            <>
              <NavLink
                exact
                activeClassName="active"
                to={"/audit"}
                className="list-group-item border-0"
                title="Audit"
              >
                <span>Audit</span>
              </NavLink>
            </>
          )}
        </div>
      </section>
    );
  }
}

export default Sidebar;
