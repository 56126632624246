import React from "react";
import Select from "react-select";
import * as API from "../../configuration/apiconfig";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FiPlusCircle } from "react-icons/fi";
import Form from 'react-bootstrap/Form';

const DateCustomInput = ({ onClick, value }) => {
  return (
    <React.Fragment>
      <input
        value={value === "" ? "" : moment(value).format("Do MMM'YY")}
        className="legs-series-livefunds"
        onClick={onClick}
        placeholder="Select Date"
      ></input>
    </React.Fragment>
  );
};

class BundlesTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scripList: [],
      strategyTypeToggle: true,
    };
  }

  searchScrip = async (searchItem) => {
    try {
      if (searchItem.length > 3) {
        await API.callEndpoint(
          "GET",
          "Bearer",
          "/api/search?text=" + searchItem
        )
          .then((response) => {
            try {
              // console.log(response.data);
              let scripList = response.data.map((item) => {
                return {
                  value: item.symbol,
                  label: item.company_name,
                };
              });
              //console.log(scripList);
              this.setState({ scripList });
            } catch (error) {
              console.log(error);
              this.setState({ scripList: [] });
            }
          })
          .catch((e) => {
            console.log(e.error);
            this.setState({ scripList: [] });
          });
      } else {
        this.setState({ scripList: [] });
      }
    } catch (e) {
      this.setState({ scripList: [] });
    }
  };

  render() {
    return (
      <section className="admin-bundlesTabs-page">
        <h1 className="head text-uppercase m-0">
          LIVE FUNDS {this.props.state.equity ? "Equity" : ""}{" "}
          {this.props.state.option ? "Options" : ""} Tab:
        </h1>
        <section className="select-options-blk">
          <section className="row">
            <section className="col-lg-6">
              <section className="forms-blk">
                <div className="head-1">Live Fund Title</div>
                <div className="form-group">
                  <input
                    id="bundleTitle"
                    type="text"
                    className="form-control"
                    name="addBundle"
                    placeholder="Add Live Fund Title"
                    autoComplete="off"
                    onChange={(e) =>
                      this.props.updateState("bundleTitle", e.target.value)
                    }
                    value={this.props.state.bundleTitle}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
                {/* <div className="head-1">Live Fund Label</div> */}
                {/* <div className="form-group">
                  <input
                    id="bundleLabel"
                    type="text"
                    className="form-control"
                    name="addLabel"
                    placeholder="Add Live Fund Label"
                    autoComplete="off"
                    onChange={(e) =>
                      this.props.updateState("bundleLabel", e.target.value)
                    }
                    value={this.props.state.bundleLabel}
                  />
                  <span className="b-editbtn">&nbsp;</span>
                </div> */}
              </section>
            </section>
            <section className="col-lg-4">
              <section className="membership-blk">
                <p>Subscription</p>
                <aside className="member-select">
                  <Select
                    isDisabled={this.props.state.modify}
                    onChange={(e) => {
                      this.props.updateState("subscription", e.value);
                    }}
                    options={this.props.membershipSelectOptions}
                    placeholder="Select"
                    value={this.props.membershipSelectOptions.filter(
                      (item) => item.value === this.props.state.subscription
                    )}
                  />
                </aside>
              </section>
              <section className="membership-blk">
                <p>Type of Live fund</p>
                <aside className="member-select">
                  <Select
                    isDisabled={this.props.state.modify}
                    onChange={(e) => {
                      this.props.updateState("type", e.value);
                    }}
                    options={this.props.typesOfLiveFund}
                    placeholder="Select"
                    value={this.props.typesOfLiveFund.filter(
                      (item) => item.value === this.props.state.type
                    )}
                  />
                </aside>
              </section>
              <section className="membership-blk">
                <p>Expected Return (%)</p>

                <input
                  type="text"
                  className="text-box"
                  placeholder=""
                  autoComplete="off"
                  onChange={(e) =>
                    this.props.updateState(
                      "expectedReturn",
                      e.target.value
                        .split("")
                        .filter((item) => item.match(/[0-9\\.]/i))
                        .join("")
                    )
                  }
                  value={this.props.state.expectedReturn}
                />
              </section>
              <section className="membership-blk">
                <p>Current Return</p>

                <input
                  type="text"
                  className=" text-box"
                  placeholder="+/- Value"
                  autoComplete="off"
                  onChange={(e) =>
                    this.props.updateState(
                      "currentReturn",
                      e.target.value
                        .split("")
                        .filter((item) => item.match(/[0-9\\.-]/i))
                        .join("")
                    )
                  }
                  value={this.props.state.currentReturn}
                />
              </section>
              <section className="membership-blk">
                <p>Should use old roc formula</p>
                {console.log(this.props.state?.use_old_formula, "this.props.state.")}
                <aside className="member-select">
                  <Select
                    onChange={(e) => {
                      this.props.updateState("use_old_formula", e.value);
                    }}
                    options={this.props.shouldUseOldRocFormulaSelectOptions}
                    placeholder="Select"
                    value={this.props.shouldUseOldRocFormulaSelectOptions.filter(
                      (item) => item.value == this.props.state.use_old_formula
                    )}
                  />
                </aside>
              </section>
              <section className="membership-blk">
                <p>Can Enter Status</p>
                {console.log(this.props.state?.enter_status, "this.props.state.")}
                <aside className="member-select">
                  <Select
                    onChange={(e) => {
                      this.props.updateState("enter_status", e.value);
                    }}
                    options={this.props.canEnterStatusSelectOptions}
                    placeholder="Select"
                    value={this.props.canEnterStatusSelectOptions.filter(
                      (item) => item.value == this.props.state.enter_status
                    )}
                  />
                </aside>
              </section>
            </section>
          </section>
        </section>
        <section className="bundle-tabs-blk">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${this.props.state.equityTabActive ? "active" : ""
                  }`}
                id="equity-tab"
                data-bs-toggle="tab"
                data-bs-target="#equity"
                type="button"
                role="tab"
                aria-controls="equity"
                aria-selected="true"
                onClick={() => this.props.tabsChange("equity")}
              >
                Equity
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${this.props.state.optionsTabActive ? "active" : ""
                  }`}
                id="options-tab"
                data-bs-toggle="tab"
                data-bs-target="#options"
                type="button"
                role="tab"
                aria-controls="options"
                aria-selected="false"
                onClick={() => this.props.tabsChange("option")}
              >
                Options
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade equity-tab ${this.props.state.equityTabActive ? "show active" : ""
                }`}
              id="equity"
              role="tabpanel"
              aria-labelledby="equity-tab"
            >
              <div className="row">
                <div className="col-lg-10">
                  <aside className="build-bundle notify-action">
                    <div className="bundle-head">
                      Build Live Funds &nbsp; | &nbsp;
                      <span
                        className=" text-decoration-scrip"
                        onClick={(e) =>
                          this.props.updateState("equityAddScripArray", [
                            {
                              alreadyPresent: 0,
                              scripNameValue: "",
                              minPriceValue: "",
                              maxPriceValue: "",
                              quantityValue: "",
                              quantityPercentValue: "",
                              stopLossTypeEquity: "",
                              call_type: "",
                              call_status: "",
                              closeType: "",
                              closeTotal: "",
                              exit_price: "",
                            },
                            ...this.props.state.equityAddScripArray,
                          ])
                        }
                      >
                        Add Scrip
                      </span>
                      {/* <i
                      style={{
                        fontStyle: "normal",
                        borderRadius: "50%",
                        textDecoration: "none !important",
                        border: "1px solid #0d6efd",
                        padding: "0 4px",
                        marginLeft:"5px",
                        color: "#0d6efd",
                      }}
                    >
                      +
                    </i> */}
                    </div>

                    <section className="list-blk">
            <section className="participt-blk">
              {/* <h2>Notify User</h2> */}
              <Form.Check
                onChange={(event) => {
                  this.props.updateNotify(event.target.checked);
                  // console.log(event.target.checked, 'check value')
                }}
                label="Notify User"
                name="group1"
                type={"checkbox"}
                checked={this.props.state.notify}
                id={`reverse-checkbox-1`}
                className="notifycheck"
              />

            </section>

          </section>

                  </aside>
                </div>
                {this.props.state.equityAddScripArray.length !== 0 ? (
                  <div className="col-lg-7">
                    <aside className="req-capital">
                      <div className="form-group">
                        <p style={{ width: "230px" }}>
                          Equity Capital Required
                        </p>
                        <span className="rupeeSymbol">&#8377; </span>
                        <input
                          type="text"
                          style={{ width: "230px" }}
                          className="form-control"
                          name="equityCapital"
                          autoComplete="off"
                          onChange={(e) =>
                            this.props.updateState(
                              "equityCapital",
                              e.target.value
                                .split("")
                                .filter((item) => item.match(/[0-9\\.]/i))
                                .join("")
                            )
                          }
                          value={this.props.state.equityCapital}
                        />
                      </div>
                      {/* <div className="form-group pt-2">
                            <p style={{ width: "300px" }}>Curent Status:</p>
                            <input
                              type="text"
                              className="form-control"
                              name="currentstatus"
                              autoComplete="off"
                              onChange={(e) => this.props.capitalOnchange(e)}
                              value={this.props.state.currentstatus}
                            />
                          </div> */}
                      {/* <div className="form-group pt-2">
                            <p style={{ width: "300px" }}>Note</p>
                            <textarea
                              autoComplete="off"
                              className="form-control"
                              name="optionsnote"
                              onChange={(e) => this.props.capitalOnchange(e)}
                              value={this.props.state.optionsnote}
                            ></textarea>
                          </div> */}
                    </aside>
                  </div>
                ) : null}
              </div>
              <br />
              <section className="row">
                <section className="col-lg-12">
                  <section className="equity-form-blk">
                    {this.props.state.equityAddScripArray.map(
                      (addScrip, index) => {
                        return (
                          <section className="equity-form-blk mb-3" key={index}>
                            <div className="form-group bundles-w-30 pb-0">
                              <p className="bundle-scrip-head">Scrip Name</p>
                              {/* <input
                                type="text"
                                className="form-control"
                                name="scripNameValue"
                                placeholder="Scrip Name"
                                autoComplete="off"
                                onChange={(e) =>
                                  this.props.equityIOChange(e, index)
                                }
                                value={addScrip.scripNameValue}
                              /> */}

                              <Select
                                isDisabled={
                                  this.props.state.equityAddScripArray[index][
                                  "alreadyPresent"
                                  ] === 1
                                }
                                onFocus={(e) => {
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index]["scripNameValue"] =
                                    "";
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                onInputChange={(e) => {
                                  // console.log(e);
                                  this.searchScrip(e);
                                }}
                                onChange={(e) => {
                                  // console.log(e);
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index]["scripNameValue"] =
                                    e;
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                options={this.state.scripList}
                                placeholder="Enter Scrip Name"
                                autoComplete="off"
                                value={
                                  this.props.state.equityAddScripArray[index][
                                  "scripNameValue"
                                  ] || ""
                                }
                              />
                            </div>

                            <div className="form-group bundles-w-10 pb-0">
                              <p className="bundle-scrip-head">Min Price</p>
                              <input
                                type="text"
                                className="form-control"
                                name="minPriceValue"
                                placeholder="Min Price"
                                autoComplete="off"
                                onChange={(e) => {
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index]["minPriceValue"] =
                                    e.target.value
                                      .split("")
                                      .filter((item) => item.match(/[0-9\\.]/i))
                                      .join("");
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                value={
                                  this.props.state.equityAddScripArray[index][
                                  "minPriceValue"
                                  ]
                                }
                              />
                            </div>
                            <div className="form-group bundles-w-10 pb-0">
                              <p className="bundle-scrip-head">Max Price</p>
                              <input
                                type="text"
                                className="form-control"
                                name="maxPriceValue"
                                placeholder="Max Price"
                                autoComplete="off"
                                onChange={(e) => {
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index]["maxPriceValue"] =
                                    e.target.value
                                      .split("")
                                      .filter((item) => item.match(/[0-9\\.]/i))
                                      .join("");
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                value={
                                  this.props.state.equityAddScripArray[index][
                                  "maxPriceValue"
                                  ]
                                }
                              />
                            </div>
                            <div className="form-group bundles-w-10 pb-0">
                              <p className="bundle-scrip-head">Quantity</p>
                              <input
                                type="text"
                                className="form-control"
                                name="quantityValue"
                                placeholder="Quantity"
                                autoComplete="off"
                                onChange={(e) => {
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index]["quantityValue"] =
                                    e.target.value
                                      .split("")
                                      .filter((item) => item.match(/[0-9\\.]/i))
                                      .join("");
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                value={
                                  this.props.state.equityAddScripArray[index][
                                  "quantityValue"
                                  ]
                                }
                              />
                            </div>
                            <div className="form-group bundles-w-10 pb-0">
                              <p className="bundle-scrip-head">Capital %</p>
                              <input
                                type="text"
                                className="form-control"
                                name="quantityValue"
                                placeholder="Capital %"
                                autoComplete="off"
                                onChange={(e) => {
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index][
                                    "quantityPercentValue"
                                  ] = e.target.value
                                    .split("")
                                    .filter((item) => item.match(/[0-9\\.]/i))
                                    .join("");
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                value={
                                  this.props.state.equityAddScripArray[index][
                                  "quantityPercentValue"
                                  ]
                                }
                              />
                            </div>
                            {/* <div className="form-group stg-bundles-w-10 pb-0">
                              <p className="bundle-scrip-head1">
                                Stop Loss Type
                              </p>
                              <Select
                                onChange={(e) => {
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index][
                                    "stopLossTypeEquity"
                                  ] = e;
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                options={this.props.stopLossTypeSelect}
                                placeholder="Select"
                                value={
                                  this.props.state.equityAddScripArray[index][
                                    "stopLossTypeEquity"
                                  ]
                                }
                              />
                            </div> */}
                            <div className="form-group bundles-w-10 pb-0">
                              <p className="bundle-scrip-head">Call Type</p>
                              <Select
                                onChange={(e) => {
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index]["call_type"] = e;
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                options={this.props.equityType}
                                placeholder="Select"
                                value={
                                  this.props.state.equityAddScripArray[index][
                                  "call_type"
                                  ]
                                }
                              />
                              {/* <input
                                type="text"
                                className="form-control"
                                name="quantityValue"
                                placeholder="Call Type"
                                autoComplete="off"
                                onChange={(e) =>
                                  this.props.equityIOChange(e, index)
                                }
                                value={addScrip.quantityValue}
                              /> */}
                            </div>
                            <div className="form-group bundles-w-10 pb-0 ">
                              <p className="bundle-scrip-head">Call Status</p>
                              <Select
                                onChange={(e) => {
                                  let equityAddScripArray =
                                    this.props.state.equityAddScripArray;
                                  equityAddScripArray[index]["call_status"] = e;
                                  this.props.updateState(
                                    "equityAddScripArray",
                                    equityAddScripArray
                                  );
                                }}
                                options={this.props.callStatus}
                                placeholder="Select"
                                value={
                                  this.props.state.equityAddScripArray[index][
                                  "call_status"
                                  ]
                                }
                              />
                              {/* <input
                                type="text"
                                className="form-control"
                                name="quantityValue"
                                placeholder="Call Status"
                                autoComplete="off"
                                onChange={(e) =>
                                  this.props.equityIOChange(e, index)
                                }
                                value={addScrip.quantityValue}
                              /> */}
                            </div>
                            {this.props.state.equityAddScripArray[index][
                              "call_status"
                            ]["value"] === "closed" ? (
                              <>
                                <div className="form-group bundles-w-10 pb-0">
                                  <p className="bundle-scrip-head">
                                    Close Type
                                  </p>
                                  <Select
                                    onChange={(e) => {
                                      let equityAddScripArray =
                                        this.props.state.equityAddScripArray;
                                      equityAddScripArray[index]["closeType"] =
                                        e;
                                      this.props.updateState(
                                        "equityAddScripArray",
                                        equityAddScripArray
                                      );
                                    }}
                                    options={this.props.closeType}
                                    placeholder="Select"
                                    value={
                                      this.props.state.equityAddScripArray[
                                      index
                                      ]["closeType"]
                                    }
                                  />
                                  {/* <input
                                type="text"
                                className="form-control"
                                name="quantityValue"
                                placeholder="Close Type"
                                autoComplete="off"
                                onChange={(e) =>
                                  this.props.equityIOChange(e, index)
                                }
                                value={addScrip.quantityValue}
                              /> */}
                                </div>
                                <div className="form-group bundles-w-10 pb-0 ">
                                  <p className="bundle-scrip-head">
                                    Close Total
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantityValue"
                                    placeholder="Close Total"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      let equityAddScripArray =
                                        this.props.state.equityAddScripArray;
                                      equityAddScripArray[index]["closeTotal"] =
                                        e.target.value
                                          .split("")
                                          .filter((item) =>
                                            item.match(/[0-9\\.]/i)
                                          )
                                          .join("");
                                      this.props.updateState(
                                        "equityAddScripArray",
                                        equityAddScripArray
                                      );
                                    }}
                                    value={
                                      this.props.state.equityAddScripArray[
                                      index
                                      ]["closeTotal"]
                                    }
                                  />
                                </div>
                                <div className="form-group bundles-w-10 pb-0 ">
                                  <p className="bundle-scrip-head">
                                    Exit Price
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantityValue"
                                    placeholder="Exit Price"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      let equityAddScripArray =
                                        this.props.state.equityAddScripArray;
                                      equityAddScripArray[index]["exit_price"] =
                                        e.target.value
                                          .split("")
                                          .filter((item) =>
                                            item.match(/[0-9\\.]/i)
                                          )
                                          .join("");
                                      this.props.updateState(
                                        "equityAddScripArray",
                                        equityAddScripArray
                                      );
                                    }}
                                    value={
                                      this.props.state.equityAddScripArray[
                                      index
                                      ]["exit_price"]
                                    }
                                  />
                                </div>
                              </>
                            ) : null}
                            <div className="form-group cls-bundles">
                              <div
                                className={"close-btn4"}
                                onClick={() =>
                                  this.props.targetDeleteBtn(index)
                                }
                              >
                                <span>&nbsp;</span>
                              </div>
                            </div>
                          </section>
                        );
                      }
                    )}
                  </section>
                </section>
              </section>
              {this.props.state.equityAddScripArray.length !== 0 ? (
                <div className="row notesTopMargin">
                  <p className="bundles-notes-head">Equity Notes: </p>
                  <textarea
                    autoComplete="off"
                    className="bundles-notes-textarea"
                    name="optionsnote"
                    onChange={(e) =>
                      this.props.updateState("eqtynote", e.target.value)
                    }
                    value={this.props.state.eqtynote}
                  ></textarea>
                </div>
              ) : null}
            </div>

            <div
              className={`tab-pane fade options-tab ${this.props.state.optionsTabActive ? "show active" : ""
                }`}
              id="options"
              role="tabpanel"
              aria-labelledby="options-tab"
            >
              <div className="row">
                <div className="col-lg-10">
                  <aside className="build-bundle notify-action">
                    {/* <h2>
                  Build Bundle{" "}
                  <span
                    className="float-end text-decoration-none"
                    onClick={(e) => this.props.optionsAddScrip(e)}
                  >
                    Add Script{" "}
                    <i
                      style={{
                        fontStyle: "normal",
                        borderRadius: "50%",
                        textDecoration: "none",
                        border: "1px solid #000",
                        padding: "0 4px",
                      }}
                    >
                      +
                    </i>
                  </span>
                </h2> */}
                    <div className="bundle-head">
                      Build Live Funds &nbsp; | &nbsp;
                      <span
                        className=" text-decoration-scrip"
                        onClick={(e) =>
                          this.props.updateState("optionsAddScripArray", [
                            {
                              alreadyPresent: 0,
                              scripNameValue: "",
                              capitalReq: "",
                              call_type: "",
                              call_status: "",
                              closeType: "",
                              closeTotal: "",
                              exit_price: "",
                              strategy: "",
                              maxLoss: "",
                              stopLoss: "",
                              stopLossTypeOption: "",
                              exitPrice: "",
                              legs: [
                                {
                                  action: "",
                                  option: "",
                                  expiry: "",
                                  strikePrice: "",
                                  qtyValue: "",
                                  Price: "",
                                  exitPriceToggle: false,
                                  exitPrice: "",
                                },
                              ],
                            },
                            ...this.props.state.optionsAddScripArray,
                          ])
                        }
                      >
                        Add Scrip
                      </span>
                      {/* <i
                      style={{
                        fontStyle: "normal",
                        borderRadius: "50%",
                        textDecoration: "none !important",
                        border: "1px solid #0d6efd",
                        padding: "0 4px",
                        marginLeft:"5px",
                        color: "#0d6efd",
                      }}
                    >
                      +
                    </i> */}
                    </div>

                    <section className="list-blk">
            <section className="participt-blk">
              {/* <h2>Notify User</h2> */}
              <Form.Check
                onChange={(event) => {
                  this.props.updateNotify(event.target.checked);
                  // console.log(event.target.checked, 'check value')
                }}
                label="Notify User"
                name="group1"
                type={"checkbox"}
                checked={this.props.state.notify}
                id={`reverse-checkbox-1`}
                className="notifycheck"
              />

            </section>

          </section>
                  </aside>
                </div>
                {this.props.state.optionsAddScripArray.length !== 0 ? (
                  <div className="col-lg-7">
                    <aside className="req-capital">
                      {/* <p>Overall required Capital: <span>Rs. 2,50,000</span></p> */}
                      <div className="form-group">
                        <p style={{ width: "250px" }}>
                          Hedged Capital Required
                        </p>
                        <span className="rupeeSymbol">&#8377; </span>
                        <input
                          type="text"
                          style={{ width: "230px" }}
                          className="form-control"
                          name="optionsCapital"
                          autoComplete="off"
                          onChange={(e) =>
                            this.props.updateState(
                              "optionsCapital",
                              e.target.value
                                .split("")
                                .filter((item) => item.match(/[0-9\\.]/i))
                                .join("")
                            )
                          }
                          value={this.props.state.optionsCapital}
                        />
                      </div>
                      {/* <div className="form-group pt-2">
                        <p style={{ width: "300px" }}>Curent Status:</p>
                        <input
                          type="text"
                          className="form-control"
                          name="optcurstatus"
                          autoComplete="off"
                          onChange={(e) => this.props.capitalOnchange(e)}
                          value={this.props.state.optcurstatus}
                        />
                      </div>
                      <div className="form-group pt-2">
                        <p style={{ width: "300px" }}>Note</p>
                        <textarea
                          autoComplete="off"
                          className="form-control"
                          name="optionalnote"
                          onChange={(e) => this.props.capitalOnchange(e)}
                          value={this.props.state.optionalnote}
                        ></textarea>
                      </div> */}
                    </aside>
                  </div>
                ) : null}
              </div>

              <section className="row">
                <section className="col-lg-12">
                  {this.props.state.optionsAddScripArray.map(
                    (options, scripIndex) => {
                      return (
                        <React.Fragment>
                          <div className="row">
                            <div className="col-lg-12">
                              <section
                                className="equity-form-blk mb-3 bundles-option-margintop"
                                key={scripIndex}
                              >
                                {/* <section className="options-blk" key={scripIndex}> */}
                                {/* <section className="form-lft"> */}
                                <div className="form-group bundles-w-30 pb-0">
                                  <p className="bundle-scrip-head">
                                    Scrip Name
                                  </p>
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    name="scripNameValue"
                                    placeholder="Scrip Name"
                                    autoComplete="off"
                                    onChange={(e) =>
                                      this.props.optionsIOChange(e, scripIndex)
                                    }
                                    value={options.scripNameValue}
                                  /> */}

                                  <Select
                                    isDisabled={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["alreadyPresent"] === 1
                                    }
                                    onFocus={(e) => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "scripNameValue"
                                      ] = "";
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    onInputChange={(e) => {
                                      // console.log(e);
                                      this.searchScrip(e);
                                    }}
                                    onChange={(e) => {
                                      // console.log(e);

                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "scripNameValue"
                                      ] = e;
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    options={this.state.scripList}
                                    placeholder="Enter Scrip Name"
                                    autoComplete="off"
                                    value={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["scripNameValue"] || ""
                                    }
                                  />
                                </div>
                                {/* <div className="form-group w-50">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="reqdCapitalValue"
                                  placeholder="Required Capital"
                                  autoComplete="off"
                                  onChange={(e) =>
                                    this.props.optionsIOChange(e, scripIndex)
                                  }
                                  value={options.reqdCapitalValue}
                                />
                              </div> */}
                                <div className="form-group bundles-w-10 pb-0">
                                  <p className="bundle-scrip-head">
                                    Capital Req
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="capital_req"
                                    placeholder="Capital Req"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "capitalReq"
                                      ] = e.target.value
                                        .split("")
                                        .filter((item) =>
                                          item.match(/[0-9\\.]/i)
                                        )
                                        .join("");
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    value={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["capitalReq"]
                                    }
                                  />
                                </div>
                                {this.state.strategyTypeToggle ? (
                                  <div className="form-group stg-bundles-w-10 pb-0">
                                    <p className="bundle-scrip-head ">
                                      Strategy &nbsp;&nbsp;
                                      <FiPlusCircle
                                        size={22}
                                        className="add-strategy-button"
                                        onClick={() => {
                                          this.setState({
                                            strategyTypeToggle: false,
                                          });
                                          let optionsAddScripArray =
                                            this.props.state
                                              .optionsAddScripArray;
                                          optionsAddScripArray[scripIndex][
                                            "strategy"
                                          ] = "";
                                          this.props.updateState(
                                            "optionsAddScripArray",
                                            optionsAddScripArray
                                          );
                                        }}
                                      />
                                    </p>
                                    {/* <input
                                    type="text"
                                    className="form-control"
                                    name="strategy"
                                    placeholder="Strategy"
                                    autoComplete="off"
                                    onChange={(e) =>
                                      this.props.optionsIOChangee(e, scripIndex)
                                    }
                                    value={options[""]}
                                  /> */}
                                    <Select
                                      className="strategy-live-fund-1"
                                      onChange={(e) => {
                                        let optionsAddScripArray =
                                          this.props.state.optionsAddScripArray;
                                        optionsAddScripArray[scripIndex][
                                          "strategy"
                                        ] = e;
                                        this.props.updateState(
                                          "optionsAddScripArray",
                                          optionsAddScripArray
                                        );
                                      }}
                                      value={
                                        this.props.state.optionsAddScripArray[
                                        scripIndex
                                        ]["strategy"]
                                      }
                                      options={this.props.optionsValue}
                                      placeholder="Select"
                                    />
                                  </div>
                                ) : (
                                  <div className="form-group stg-bundles-w-10 pb-0">
                                    <p className="bundle-scrip-head">
                                      Strategy
                                    </p>
                                    <input
                                      type="text"
                                      className="form-control strategy-close-padding"
                                      name="strategy"
                                      placeholder="Strategy"
                                      autoComplete="off"
                                      onChange={(e) => {
                                        let optionsAddScripArray =
                                          this.props.state.optionsAddScripArray;
                                        optionsAddScripArray[scripIndex][
                                          "strategy"
                                        ] = {
                                          value: e.target.value,
                                          label: e.target.value,
                                        };
                                        this.props.updateState(
                                          "optionsAddScripArray",
                                          optionsAddScripArray
                                        );
                                      }}
                                      value={
                                        this.props.state.optionsAddScripArray[
                                          scripIndex
                                        ]["strategy"].value
                                      }
                                    />
                                  </div>
                                )}
                                {this.state.strategyTypeToggle ? null : (
                                  <div className="form-group close-btn-width ">
                                    <div
                                      class="close-btn2"
                                      onClick={() => {
                                        this.setState({
                                          strategyTypeToggle: true,
                                        });
                                        let optionsAddScripArray =
                                          this.props.state.optionsAddScripArray;
                                        optionsAddScripArray[scripIndex][
                                          "strategy"
                                        ] = "";
                                        this.props.updateState(
                                          "optionsAddScripArray",
                                          optionsAddScripArray
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                                <div className="form-group bundles-w-10 pb-0">
                                  <p className="bundle-scrip-head">Max Loss</p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="max_loss"
                                    placeholder="Max Loss"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "maxLoss"
                                      ] = e.target.value
                                        .split("")
                                        .filter((item) =>
                                          item.match(/[0-9\\.]/i)
                                        )
                                        .join("");
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    value={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["maxLoss"]
                                    }
                                  />
                                </div>
                                <div className="form-group bundles-w-10 pb-0">
                                  <p className="bundle-scrip-head">Stop Loss</p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="stop_loss_value"
                                    placeholder="Stop Loss"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "stopLoss"
                                      ] = e.target.value
                                        .split("")
                                        .filter((item) =>
                                          item.match(/[0-9\\.]/i)
                                        )
                                        .join("");
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    value={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["stopLoss"]
                                    }
                                  />
                                </div>
                                <div className="form-group stg-bundles-w-10 pb-0">
                                  <p className="bundle-scrip-head1">
                                    Stop Loss Type
                                  </p>
                                  <Select
                                    onChange={(e) => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "stopLossTypeOption"
                                      ] = e;
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    options={this.props.stopLossTypeSelect}
                                    placeholder="Select"
                                    value={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["stopLossTypeOption"]
                                    }
                                  />
                                </div>
                                <div className="form-group bundles-w-10 pb-0">
                                  <p className="bundle-scrip-head">
                                    Exit Price
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="stop_loss_value"
                                    placeholder="Exit Price"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "exitPrice"
                                      ] = e.target.value
                                        .split("")
                                        .filter((item) =>
                                          item.match(/[0-9\\.]/i)
                                        )
                                        .join("");
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    value={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["exitPrice"]
                                    }
                                  />
                                </div>
                                <div className="form-group stg1-bundles-w-10 pb-0 bundleTopMargin">
                                  <p className="bundle-scrip-head">Call Type</p>
                                  <Select
                                    onChange={(e) => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "call_type"
                                      ] = e;
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    options={this.props.optionsType}
                                    placeholder="Select"
                                    value={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["call_type"]
                                    }
                                  />
                                </div>
                                <div className="form-group stg1-bundles-w-10 pb-0 bundleTopMargin">
                                  <p className="bundle-scrip-head">
                                    Call Status
                                  </p>
                                  <Select
                                    onChange={(e) => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray[scripIndex][
                                        "call_status"
                                      ] = e;
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                    }}
                                    options={this.props.callStatus}
                                    placeholder="Select"
                                    value={
                                      this.props.state.optionsAddScripArray[
                                      scripIndex
                                      ]["call_status"]
                                    }
                                  />
                                </div>
                                {this.props.state.optionsAddScripArray[
                                  scripIndex
                                ]["call_status"]["value"] === "closed" ? (
                                  <>
                                    <div className="form-group stg1-bundles-w-10 pb-0 bundleTopMargin">
                                      <p className="bundle-scrip-head">
                                        Close Type
                                      </p>
                                      <Select
                                        onChange={(e) => {
                                          let optionsAddScripArray =
                                            this.props.state
                                              .optionsAddScripArray;
                                          optionsAddScripArray[scripIndex][
                                            "closeType"
                                          ] = e;
                                          this.props.updateState(
                                            "optionsAddScripArray",
                                            optionsAddScripArray
                                          );
                                        }}
                                        options={this.props.closeType}
                                        placeholder="Select"
                                        value={
                                          this.props.state.optionsAddScripArray[
                                          scripIndex
                                          ]["closeType"]
                                        }
                                      />
                                    </div>
                                    <div className="form-group bundles-w-10 pb-0 bundleTopMargin">
                                      <p className="bundle-scrip-head">
                                        Close Total
                                      </p>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="close_total"
                                        placeholder="Close Total"
                                        autoComplete="off"
                                        onChange={(e) => {
                                          let optionsAddScripArray =
                                            this.props.state
                                              .optionsAddScripArray;
                                          optionsAddScripArray[scripIndex][
                                            "closeTotal"
                                          ] = e.target.value
                                            .split("")
                                            .filter((item) =>
                                              item.match(/[0-9\\.]/i)
                                            )
                                            .join("");
                                          this.props.updateState(
                                            "optionsAddScripArray",
                                            optionsAddScripArray
                                          );
                                        }}
                                        value={
                                          this.props.state.optionsAddScripArray[
                                          scripIndex
                                          ]["closeTotal"]
                                        }
                                      />
                                    </div>
                                    {/* <div className="form-group bundles-w-10 pb-0 bundleTopMargin">
                                      <p className="bundle-scrip-head">
                                        Exit Price
                                      </p>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="exit_price"
                                        placeholder="Exit Price"
                                        autoComplete="off"
                                        onChange={(e) => {
                                          let optionsAddScripArray =
                                            this.props.state
                                              .optionsAddScripArray;
                                          optionsAddScripArray[scripIndex][
                                            "exit_price"
                                          ] = e.target.value
                                            .split("")
                                            .filter((item) =>
                                              item.match(/[0-9\\.]/i)
                                            )
                                            .join("");
                                          this.props.updateState(
                                            "optionsAddScripArray",
                                            optionsAddScripArray
                                          );
                                        }}
                                        value={
                                          this.props.state.optionsAddScripArray[
                                          scripIndex
                                          ]["exit_price"]
                                        }
                                      />
                                    </div> */}

                                  </>
                                ) : null}

                                <div class="form-group">
                                  <div
                                    class="close-btn1"
                                    onClick={() => {
                                      let optionsAddScripArray =
                                        this.props.state.optionsAddScripArray;
                                      optionsAddScripArray.splice(
                                        scripIndex,
                                        1
                                      );
                                      // console.log(optionsAddScripArray);
                                      this.props.updateState(
                                        "optionsAddScripArray",
                                        optionsAddScripArray
                                      );
                                      if (optionsAddScripArray.length === 0) {
                                        this.props.updateState(
                                          "optionalnote",
                                          null
                                        );
                                        this.props.updateState(
                                          "optionsCapital",
                                          null
                                        );
                                      }
                                    }}
                                  >
                                    <span>&nbsp;</span>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <span
                                style={{ color: "#0d6efd" }}
                                className="float-end text-decoration-none"
                                onClick={(e) => {
                                  let optionsAddScripArray =
                                    this.props.state["optionsAddScripArray"];
                                  // console.log(optionsAddScripArray);
                                  optionsAddScripArray[scripIndex]["legs"] = [
                                    ...optionsAddScripArray[scripIndex]["legs"],
                                    {
                                      selectValue: "",
                                      qtyValue: "",
                                      minPrice: "",
                                      maxPrice: "",
                                      exitPriceToggle: false,
                                      exitPrice: "",
                                      legExit: false,
                                    },
                                  ];
                                  // console.log(optionsAddScripArray);
                                  this.props.updateState(
                                    "optionsAddScripArray",
                                    optionsAddScripArray
                                  );
                                }}
                              >
                                Add Legs{" "}
                                <i
                                  style={{
                                    fontStyle: "normal",
                                    borderRadius: "50%",
                                    textDecoration: "none",
                                    border: "1px solid #0d6efd",
                                    padding: "0 4px",
                                  }}
                                >
                                  +
                                </i>
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <section>
                                {options.legs.map((leg, legIndex) => {
                                  legIndex =
                                    options.legs.length - (legIndex + 1);
                                  return (
                                    <section
                                      className={`form-blk bundles-legs-blk padd-bot ${this.props.state.optionsAddScripArray[
                                        scripIndex
                                      ]["legs"][legIndex]["exitPriceToggle"]
                                        ? `gray-leg-bg`
                                        : ``
                                        }`}
                                      key={legIndex}
                                    >
                                      <h2
                                        className={`padd-top ${this.props.state.optionsAddScripArray[
                                          scripIndex
                                        ]["legs"][legIndex]["exitPriceToggle"]
                                          ? `head-gray-bg`
                                          : ``
                                          }`}
                                      >
                                        Leg {legIndex + 1}
                                      </h2>
                                      <div
                                        className={`form-group bundles-legs pb-0 mb-3 ${this.props.state.optionsAddScripArray[
                                          scripIndex
                                        ]["legs"][legIndex]["exitPriceToggle"]
                                          ? `gray-bg`
                                          : ``
                                          }`}
                                      >
                                        <p
                                          className={`bundle-scrip-head ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `head-gray-bg`
                                            : ``
                                            }`}
                                        >
                                          Action
                                        </p>
                                        <Select
                                          isDisabled={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["exitPriceToggle"]
                                          }
                                          onChange={(e) => {
                                            let optionsAddScripArray =
                                              this.props.state
                                                .optionsAddScripArray;
                                            optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["action"] = e;
                                            this.props.updateState(
                                              "optionsAddScripArray",
                                              optionsAddScripArray
                                            );
                                          }}
                                          value={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["action"]
                                          }
                                          options={this.props.legselect}
                                          placeholder="Select"
                                        //value={this.props.state.legsSelectValue}
                                        // value={this.props.state['legs'+[scripIndex+1]+[legIndex+1]]}
                                        />
                                      </div>

                                      {/* <div className="form-group  bundles-legs pb-0 mb-3">
                                        <p className="bundle-scrip-head">
                                          Series
                                        </p>
                                        <input
                                          type="text"
                                          name="minPrice"
                                          className="form-control"
                                          placeholder="Series"
                                          autoComplete="off"
                                          onChange={(e) => {
                                            let optionsAddScripArray =
                                              this.props.state
                                                .optionsAddScripArray;
                                            optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["expiry"] =
                                              e.target.value;

                                            this.props.updateState(
                                              "optionsAddScripArray",
                                              optionsAddScripArray
                                            );
                                          }}
                                          value={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["expiry"]
                                          }
                                        />
                                      </div> */}

                                      <div
                                        className={`form-group bundles-legs-select pb-0 mb-3 ${this.props.state.optionsAddScripArray[
                                          scripIndex
                                        ]["legs"][legIndex]["exitPriceToggle"]
                                          ? `gray-bg`
                                          : `black-text`
                                          }`}
                                      >
                                        <p
                                          className={`bundle-scrip-head ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `head-gray-bg`
                                            : ``
                                            }`}
                                        >
                                          Series
                                        </p>
                                        {/* <Select
                                          onChange={(e) => {
                                            let optionsAddScripArray =
                                              this.props.state
                                                .optionsAddScripArray;
                                            optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["expiry"] = e;
                                            this.props.updateState(
                                              "optionsAddScripArray",
                                              optionsAddScripArray
                                            );
                                          }}
                                          value={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["expiry"]
                                          }
                                          options={
                                            this.props.listOfLastThursdays
                                          }
                                          placeholder="Select"
                                          //value={this.props.state.legsSelectValue}
                                          // value={this.props.state['legs'+[scripIndex+1]+[legIndex+1]]}
                                        /> */}
                                        <DatePicker
                                          readOnly={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["exitPriceToggle"]
                                          }
                                          showMonthDropdown
                                          showYearDropdown
                                          onChange={(e) => {
                                            let optionsAddScripArray =
                                              this.props.state
                                                .optionsAddScripArray;
                                            optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["expiry"] = e;
                                            this.props.updateState(
                                              "optionsAddScripArray",
                                              optionsAddScripArray
                                            );
                                          }}
                                          selected={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["expiry"]
                                          }
                                          customInput={<DateCustomInput />}
                                        />
                                      </div>
                                      <div className="form-group  bundles-legs pb-0 mb-3">
                                        <p
                                          className={`bundle-scrip-head ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `head-gray-bg`
                                            : ``
                                            }`}
                                        >
                                          Strike Price
                                        </p>
                                        <input
                                          disabled={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["exitPriceToggle"]
                                          }
                                          type="text"
                                          name="minPrice"
                                          className={`form-control ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `leg-input-gray`
                                            : ``
                                            }`}
                                          placeholder="Strike Price"
                                          autoComplete="off"
                                          onChange={(e) => {
                                            let optionsAddScripArray =
                                              this.props.state
                                                .optionsAddScripArray;
                                            optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["strikePrice"] =
                                              e.target.value;

                                            this.props.updateState(
                                              "optionsAddScripArray",
                                              optionsAddScripArray
                                            );
                                          }}
                                          value={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["strikePrice"]
                                          }
                                        />
                                      </div>
                                      <div
                                        className={`form-group bundles-legs pb-0 mb-3 ${this.props.state.optionsAddScripArray[
                                          scripIndex
                                        ]["legs"][legIndex]["exitPriceToggle"]
                                          ? `gray-bg`
                                          : ``
                                          }`}
                                      >
                                        <p
                                          className={`bundle-scrip-head ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `head-gray-bg`
                                            : ``
                                            }`}
                                        >
                                          Option
                                        </p>
                                        <Select
                                          isDisabled={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["exitPriceToggle"]
                                          }
                                          onChange={(e) => {
                                            let optionsAddScripArray =
                                              this.props.state
                                                .optionsAddScripArray;
                                            optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["option"] = e;
                                            this.props.updateState(
                                              "optionsAddScripArray",
                                              optionsAddScripArray
                                            );
                                          }}
                                          value={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["option"]
                                          }
                                          options={this.props.optionselect}
                                          placeholder="Select"
                                        //value={this.props.state.legsSelectValue}
                                        // value={this.props.state['legs'+[scripIndex+1]+[legIndex+1]]}
                                        />
                                      </div>
                                      <div className="form-group  bundles-legs pb-0 mb-3">
                                        <p
                                          className={`bundle-scrip-head ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `head-gray-bg`
                                            : ``
                                            }`}
                                        >
                                          Price
                                        </p>
                                        <input
                                          disabled={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["exitPriceToggle"]
                                          }
                                          type="text"
                                          name="minPrice"
                                          className={`form-control ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `leg-input-gray`
                                            : ``
                                            }`}
                                          placeholder="Price"
                                          autoComplete="off"
                                          onChange={(e) => {
                                            let optionsAddScripArray =
                                              this.props.state
                                                .optionsAddScripArray;
                                            optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["Price"] =
                                            e.target.value;

                                            this.props.updateState(
                                              "optionsAddScripArray",
                                              optionsAddScripArray
                                            );
                                          }}
                                          value={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["Price"]
                                          }
                                        />
                                      </div>

                                      <div className="form-group  bundles-legs pb-0 mb-3">
                                        <p
                                          className={`bundle-scrip-head ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `head-gray-bg`
                                            : ``
                                            }`}
                                        >
                                          Quantity
                                        </p>
                                        <input
                                          disabled={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["exitPriceToggle"]
                                          }
                                          type="text"
                                          name="qtyValue"
                                          className={`form-control ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["exitPriceToggle"]
                                            ? `leg-input-gray`
                                            : ``
                                            }`}
                                          placeholder="Qty"
                                          autoComplete="off"
                                          onChange={(e) => {
                                            let optionsAddScripArray =
                                              this.props.state
                                                .optionsAddScripArray;
                                            optionsAddScripArray[scripIndex][
                                              "legs"
                                            ][legIndex]["qtyValue"] =
                                              e.target.value;

                                            this.props.updateState(
                                              "optionsAddScripArray",
                                              optionsAddScripArray
                                            );
                                          }}
                                          value={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["qtyValue"]
                                          }
                                        />
                                      </div>
                                      <div class="form-group">
                                        <div
                                          class="close-btn3"
                                          disabled={
                                            this.props.state
                                              .optionsAddScripArray[scripIndex][
                                            "legs"
                                            ][legIndex]["exitPriceToggle"]
                                          }
                                          onClick={() => {
                                            if (
                                              !this.props.state
                                                .optionsAddScripArray[
                                              scripIndex
                                              ]["legs"][legIndex][
                                              "exitPriceToggle"
                                              ]
                                            ) {
                                              let optionsAddScripArray =
                                                this.props.state
                                                  .optionsAddScripArray;
                                              if (
                                                optionsAddScripArray[
                                                  scripIndex
                                                ]["legs"].length > 1
                                              ) {
                                                optionsAddScripArray[
                                                  scripIndex
                                                ]["legs"].splice(legIndex, 1);
                                              }

                                              // console.log(optionsAddScripArray);
                                              this.props.updateState(
                                                "optionsAddScripArray",
                                                optionsAddScripArray
                                              );
                                            }
                                          }}
                                        >
                                          <span>&nbsp;</span>
                                        </div>
                                      </div>
                                      <div
                                        className={`form-group  bundles-legs pb-0 mb-3 toggle-slider-left ${this.props.state.optionsAddScripArray[
                                          scripIndex
                                        ]["legs"][legIndex]["legExit"]
                                          ? `gray-bg`
                                          : ``
                                          }`}
                                      >
                                        <p
                                          className={`bundle-scrip-head ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["legExit"]
                                            ? `head-gray-bg`
                                            : ``
                                            }`}
                                        >
                                          Exit
                                        </p>
                                        <div
                                          className={`toggle-slider ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["legExit"]
                                            ? `leg-input-gray-toggle`
                                            : ``
                                            }`}
                                        >
                                          <label className="switch">
                                            <input
                                              disabled={
                                                this.props.state
                                                  .optionsAddScripArray[
                                                scripIndex
                                                ]["legs"][legIndex]["legExit"]
                                              }
                                              type="checkbox"
                                              onChange={(e) => {
                                                let optionsAddScripArray =
                                                  this.props.state
                                                    .optionsAddScripArray;
                                                optionsAddScripArray[
                                                  scripIndex
                                                ]["legs"][legIndex][
                                                  "exitPriceToggle"
                                                ] = e.target.checked;
                                                optionsAddScripArray[
                                                  scripIndex
                                                ]["legs"][legIndex][
                                                  "exitPrice"
                                                ] = "";

                                                this.props.updateState(
                                                  "optionsAddScripArray",
                                                  optionsAddScripArray
                                                );
                                              }}
                                              checked={
                                                this.props.state
                                                  .optionsAddScripArray[
                                                scripIndex
                                                ]["legs"][legIndex][
                                                "exitPriceToggle"
                                                ]
                                              }
                                            />
                                            <span className="slider"></span>
                                          </label>
                                        </div>
                                      </div>
                                      {this.props.state.optionsAddScripArray[
                                        scripIndex
                                      ]["legs"][legIndex]["exitPriceToggle"] ? (
                                        <div
                                          className={`form-group  bundles-legs ${this.props.state
                                            .optionsAddScripArray[scripIndex][
                                            "legs"
                                          ][legIndex]["legExit"]
                                            ? `gray-bg`
                                            : ``
                                            }`}
                                        >
                                          <p
                                            className={`bundle-scrip-head ${this.props.state
                                              .optionsAddScripArray[
                                              scripIndex
                                            ]["legs"][legIndex]["legExit"]
                                              ? `head-gray-bg`
                                              : ``
                                              }`}
                                          >
                                            Exit Price
                                          </p>
                                          <input
                                            className={`form-control ${this.props.state
                                              .optionsAddScripArray[
                                              scripIndex
                                            ]["legs"][legIndex]["legExit"]
                                              ? `leg-input-gray`
                                              : `white-bg`
                                              }`}
                                            disabled={
                                              this.props.state
                                                .optionsAddScripArray[
                                              scripIndex
                                              ]["legs"][legIndex]["legExit"]
                                            }
                                            type="text"
                                            name="exitPrice"
                                            placeholder="Exit Price"
                                            autoComplete="off"
                                            onChange={(e) => {
                                              let optionsAddScripArray =
                                                this.props.state
                                                  .optionsAddScripArray;
                                              optionsAddScripArray[scripIndex][
                                                "legs"
                                              ][legIndex]["exitPrice"] =
                                                e.target.value
                                                  .split("")
                                                  .filter((item) =>
                                                    item.match(/[0-9\\.]/i)
                                                  )
                                                  .join("");

                                              this.props.updateState(
                                                "optionsAddScripArray",
                                                optionsAddScripArray
                                              );
                                            }}
                                            value={
                                              this.props.state
                                                .optionsAddScripArray[
                                              scripIndex
                                              ]["legs"][legIndex]["exitPrice"]
                                            }
                                          />
                                        </div>
                                      ) : null}
                                    </section>
                                  );
                                })}

                                <aside
                                  className="plus-btn"
                                  onClick={(e) =>
                                    this.props.legsAddBtn(e, scripIndex)
                                  }
                                >
                                  &nbsp;
                                </aside>
                              </section>
                            </div>
                          </div>
                          {/* </section> */}
                        </React.Fragment>
                      );
                    }
                  )}
                </section>
              </section>
              {this.props.state.optionsAddScripArray.length !== 0 ? (
                <div className="row notesTopMargin">
                  <p className="bundles-notes-head">Hedge Notes: </p>
                  <textarea
                    autoComplete="off"
                    className="bundles-notes-textarea"
                    name="optionsnote"
                    onChange={(e) =>
                      this.props.updateState("optionalnote", e.target.value)
                    }
                    value={this.props.state.optionalnote}
                  ></textarea>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default BundlesTabs;
