import React from 'react';
import { NavLink } from 'react-router-dom';
import * as API from '../../configuration/apiconfig';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

const format1 = 'DD/MM/YYYY hh:mm A';

class SystemCalls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      newcall: null,
      plans: [],
      plansData: [],
      deleted_calls: [],
      close_calls: [],
      close_intraday_call: [],
      intraday_calls: [],
      closeCall: false,
      CloseIntradayCall: false,
      currentPage: 1,
      selectedPlan: 0,
      activePlan: null,
      activeCloseCalls: false,
      sortOrder:'desc',
      sortKey:""
    };
  }

  newCallFunction = (getTxt) => {
    this.setState({
      newcall: getTxt,
    });
  };

  // getData = async () => {
  //   let plans = [];
  //   let plansData = [];
  //   //get all plans
  //   await API.callEndpoint(
  //     'GET',
  //     'Bearer',
  //     '/api/plans?include=id&include=planName'
  //   )
  //     .then(async (response) => {
  //       try {
  //         plans = response.data;
  //         this.setState({ plans });
  //         //iterate all plans
  //         await plans.map(async (item, index) => {
  //           //fetch details of all plans
  //           return await API.callEndpoint(
  //             'GET',
  //             'Bearer',
  //             `/api/calls?planid=${item.id}&childtables=history`
  //           )
  //             .then(async (response) => {
  //               plansData[index] = response.data;
  //               await this.setState({ plansData });
  //             })
  //             .catch((error) => {
  //               console.log(error.error);
  //             });
  //         });
  //       } catch (e) {
  //         console.log(e.error);
  //         this.setState({ plans: [], plansData: [] });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.error);
  //       this.setState({ plans: [], plansData: [] });
  //     });
  //   // get deleted calls
  //   await API.callEndpoint(
  //     'GET',
  //     'Bearer',
  //     '/api/plans?include=id&include=planName'
  //   )
  //     .then(async (response) => {
  //       try {
  //         plans = response.data;
  //         this.setState({ plans });
  //         //iterate all plans
  //         await plans.map(async (item, index) => {
  //           //fetch details of all plans
  //           return await API.callEndpoint(
  //             'GET',
  //             'Bearer',
  //             `/api/calls/deleted-calls`
  //           )
  //             .then(async (response) => {
  //               // console.log(response?.data)
  //               this.setState({
  //                 deleted_calls: response?.data,
  //               });
  //             })
  //             .catch((error) => {
  //               console.log(error.error);
  //             });
  //         });
  //       } catch (e) {
  //         console.log(e.error);
  //         this.setState({ plans: [], plansData: [] });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.error);
  //       this.setState({ plans: [], plansData: [] });
  //     });



  //   await API.callEndpoint('GET', 'Bearer', `/api/intraday`)
  //     .then(async (response) => {
  //       this.setState({
  //         intraday_calls: response?.data,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error.error);
  //     });
  // };

  // componentDidMount = async () => {
  //   await this.getData();
  // };


  getData = async (pgno) => {
    let plans = [];
    console.log("getdata");
    // let plansData = [];
    //get all plans
    await API.callEndpoint(
      "GET",
      "Bearer",
      "/api/system_calls/categories"
    )
      .then(async (response) => {
        try {
          plans = response.data;
          this.setState({ plans });
          this.setState({ activePlan: plans[0] });
          // this.getDataByPlan(this.state.currentPage)
          //iterate all plans
          // await plans.map(async (item, index) => {
          //   //fetch details of all plans
          //   return await API.callEndpoint(
          //     "GET",
          //     "Bearer",
          //     `/api/calls?planid=${item.id}&childtables=history&limit=10&offset=${pgno}`
          //   )
          //     .then(async (response) => {
          //       plansData[index] = response.data;
          //       await this.setState({ plansData });
          //       console.log("plansData",plansData)
          //     })
          //     .catch((error) => {
          //       console.log(error.error);
          //     });
          // });
        } catch (e) {
          console.log(e.error);
          this.setState({ plans: [], plansData: [] });
        }
      })
      .catch((error) => {
        console.log(error.error);
        this.setState({ plans: [], plansData: []});
      });
    // get deleted calls
    // await API.callEndpoint(
    //   'GET',
    //   'Bearer',
    //   '/api/plans?include=id&include=planName'
    // )
    //   .then(async (response) => {
    //     try {
    //       plans = response.data;
    //       this.setState({ plans });
    //       //iterate all plans
    //       await plans.map(async (item, index) => {
    //         //fetch details of all plans
    //         return await API.callEndpoint(
    //           'GET',
    //           'Bearer',
    //           `/api/calls/deleted-calls`
    //         )
    //           .then(async (response) => {
    //             // console.log(response?.data)
    //             this.setState({
    //               deleted_calls: response?.data,
    //             });
    //           })
    //           .catch((error) => {
    //             console.log(error.error);
    //           });
    //       });
    //     } catch (e) {
    //       console.log(e.error);
    //       this.setState({ plans: [], plansData: [] });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error.error);
    //     this.setState({ plans: [], plansData: [] });
    //   });

    // await API.callEndpoint('GET', 'Bearer', `/api/calls/closecall`)
    //   .then(async (response) => {
    //     // console.log(response?.data)
    //     this.setState({
    //       close_calls: response?.data,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error.error);
    //   });

    await API.callEndpoint('GET', 'Bearer', `/api/intraday`)
      .then(async (response) => {
        this.setState({
          intraday_calls: response?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  getDeletedCalls = () => {
    // this.state.plans.map(async (item, index) => {
    //fetch details of all plans
    API.callEndpoint(
      'GET',
      'Bearer',
      `/api/calls/deleted-calls`
    )
      .then(async (response) => {
        // console.log(response?.data)
        this.setState({
          deleted_calls: response?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });

    // });
  }

  getCloseCalls = async (pgno) => {
    await API.callEndpoint('GET', 'Bearer', `/api/calls/closecall?limit=10&pageno=${pgno}`)
      .then(async (response) => {
        // console.log(response?.data)
        this.setState({
          close_calls: response?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
  }

  getDataByPlan = async (pgno) => {
    console.log("getdatabyplan");

    //iterate all plans
    // await this.state.plans.map(async (item, index) => {
    //fetch details of all plans
    await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/system_calls?category_id=${this.state.activePlan.id}`
      //  `/api/calls?planid=${this.state.activePlan.id}&childtables=history&limit=10&offset=${pgno}`
    )
      .then((response) => {
        // plansData[index] = response.data;
        this.setState({ plansData: response.data, loading:false });
        // console.log("plansData",plansData)
      })
      .catch((error) => {
        console.log(error.error);
        this.setState({ plansData: [], loading:false });

      });
    // });
  };

  componentDidMount = async () => {
    await this.getData(this.state.currentPage);
  };

  handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const bodyData = {id}
        API.callEndpoint('PUT', 'Bearer', `/api/system_calls/closecall`, bodyData)
          .then(async (response) => {
            if (response.status === 200) {
              Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
              await this.getDataByPlan();
            }
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    });
  };

  handleIntradayCallDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        API.callEndpoint('DELETE', 'Bearer', `/api/intraday/${id}`)
          .then(async (response) => {
            if (response.status === 200) {
              await this.getData(this.state.currentPage);
            }
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    });
  };

  // handlePageChange = (pgno) => {
  //   this.setState({ currentPage: pgno });
  //   console.log("pgno", pgno);
  // };

  // pagination = () => {
  //   console.log(this.state.plansData.totalpage, "this");
  //   let pages = [];
  //   for (let i = 1; i <= this.state?.plansData?.totalpage; i++) {
  //     pages.push(
  //       <div
  //         key={i}
  //         // href="#"
  //         className={this.state.currentPage === i ? "active" : ""}
  //         onClick={(e) => {
  //           e.preventDefault();
  //           this.handlePageChange(i);
  //         }}
  //       >
  //         {i}
  //       </div>
  //     );
  //   }
  //   return pages;
  // };

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevState.currentPage !== this.state.currentPage ||
  //     prevState.activePlan !== this.state.activePlan
  //   ) {
  //     this.getDataByPlan(this.state.currentPage);
  //   }
  // }

  handlePageChange = (pgno) => {
    this.setState({ currentPage: pgno });

    // console.log("pgno", pgno);
  };
  handleCloseCalls = () => {
    this.setState({ activeCloseCalls: true })
    this.getCloseCalls(this.state.currentPage)
  }

  pagination = (totalPages) => {
    // console.log(this.state.plansData.totalpage, "this");
    let pages = [];
    // for (let i = 1; i <= this.state?.plansData?.totalpage; i++) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <div
          key={i}
          // href="#"
          className={this.state.currentPage === i ? "active" : ""}
          onClick={(e) => {
            e.preventDefault();
            this.handlePageChange(i);
          }}
        >
          {i}
        </div>
      );
    }
    return pages;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.activePlan !== this.state.activePlan
    ) {
      if (this.state.activeCloseCalls) {
        this.getCloseCalls(this.state.currentPage)
      } else {

        this.getDataByPlan(this.state.currentPage);
      }

    }

  }

  sortData = (key) => {
    let sortedData;
    if (this.state.sortOrder === 'asc') {
      sortedData = [...this.state.plansData].sort((a, b) => (a[key] > b[key] ? 1 : -1));
      this.setState({
        sortOrder:'desc'
      });
    } else {
      sortedData = [...this.state.plansData].sort((a, b) => (a[key] < b[key] ? 1 : -1));
      this.setState({
        sortOrder:'asc'
      });
    }
    this.setState({
      plansData: sortedData,
      sortKey:key
    });
  };

  getSortIcon = (key) => {
    // if (this.state.sortKey !== key) {
    //   return <FaSort />;
    // }
    return this.state.sortOrder === 'asc' && this.state.sortKey == key ? <FaSortUp style={{marginTop:"5px", marginLeft:"3px"}} /> : <FaSortDown style={{marginBottom:"5px", marginLeft:"3px"}} />;
  };

  render() {
    return (
      <section className="admin-calls">
        <h1 className="head text-uppercase m-0">System Calls</h1>
        <div className="row mb-4">
          {/* <div className="col-md-8 d-flex align-items-center">
            <p className="common-para mb-0">Calls</p>
          </div> */}
          <div className="col-md-4 text-end calls-dropdown">
            {/* <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle px-4 py-2 text-start" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">New Call</button>
                <ul className="dropdown-menu p-0 border-top-0" aria-labelledby="dropdownMenuButton1">
                  <li><NavLink exact to={'/newcall/new'} onClick={() => this.newCallFunction('All')}  className="dropdown-item py-2 px-4" title="All">All</NavLink></li>
                  <li><NavLink exact to={'/newcall/new'} onClick={() => this.newCallFunction('Prime')}  className="dropdown-item py-2 px-4" title="Prime">Prime</NavLink></li>
                  <li><NavLink exact to={'/newcall/new'} onClick={() => this.newCallFunction('Crown')}  className="dropdown-item py-2 px-4" title="Crown">Crown</NavLink></li>
                </ul>
              </div> */}
            {/* <div
              className="new-call-btn"
              style={{
                width: 160,
                marginLeft: 5,
              }}
            >
              <NavLink exact to={'/intradaycall/new'} title="Intraday Call">
                New Intraday Call
              </NavLink>
            </div> */}

            {/* <div className="new-call-btn">
              <NavLink exact to={'/newcall/new'} title="New Call">
                New Call
              </NavLink>
            </div> */}
          </div>
        </div>
        <section className="table-blk">
          <ul
            className="nav nav-tabs border-bottom-0"
            id="myTab"
            role="tablist"
          >
            {this.state.plans.map((item, value) => {
              let classStyle = "nav-link ";
              if (value === 0) {
                classStyle = "nav-link active";
              }
              return (
                <li className="nav-item" role="presentation" key={value}>
                  <button
                    style={{
                      width: 150,
                    }}
                    className={classStyle}
                    id={`${item["id"]}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${item["id"]}`}
                    type="button"
                    role="tab"
                    aria-controls={item["id"]}
                    aria-selected="true"
                    onClick={() =>
                      this.setState({
                        selectedPlan: value,
                        currentPage: 1,
                        activePlan: item,
                        activeCloseCalls: false,
                        plansData: [],
                        loading:true
                      })
                    }
                  >
                    {item["name"].toLowerCase()}
                  </button>
                </li>
              );
            })}

            {/* <li className="nav-item" role="presentation" key={'intraday_calls'}>
              <button
                style={{
                  width: 140,
                }}
                className={'nav-link '}
                // id={`${item["planName"].toLowerCase()}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#intraday_calls`}
                type="button"
                role="tab"
                aria-controls={'intraday_calls'}
                aria-selected="true"
              >
                Intraday calls
              </button>
            </li> */}
            {/* <li className="nav-item" role="presentation" key={'delete_calls'}>
              <button
                style={{
                  width: 140,
                }}
                className={'nav-link '}
                // id={`${item["planName"].toLowerCase()}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#delete_calls`}
                type="button"
                role="tab"
                aria-controls={'delete_calls'}
                aria-selected="true"

                onClick={this.getDeletedCalls}
              >
                Deleted calls
              </button>
            </li>
            <li className="nav-item" role="presentation" key={'close_calls'}>
              <button
                style={{
                  width: 140,
                }}
                className={'nav-link '}
                // id={`${item["planName"].toLowerCase()}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#close_calls`}
                type="button"
                role="tab"
                aria-controls={'close_calls'}
                aria-selected="true"
                onClick={this.handleCloseCalls}
              >
                Close calls
              </button>
            </li> */}
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="free-tab"
                data-bs-toggle="tab"
                data-bs-target="#free"
                type="button"
                role="tab"
                aria-controls="free"
                aria-selected="true"
              >
                Free
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#prime"
                type="button"
                role="tab"
                aria-controls="prime"
                aria-selected="false"
              >
                Prime
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#crown"
                type="button"
                role="tab"
                aria-controls="crown"
                aria-selected="false"
              >
                Crown
              </button>
            </li> */}
          </ul>

          <div className="tab-content" id="myTabContent">

          {this.state.loading ? 
              <p style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"24px", margin:"40px"}}>Loading...</p>:
          this.state.plans.map((plan, planIndex) => {
            
              let classStyle = "tab-pane fade";
              if (planIndex === 0) {
                classStyle = 'tab-pane fade show active';
              }
              return (
                <div
                  key={planIndex}
                  className={classStyle}
                  id={plan['name'].toLowerCase()}
                  role="tabpanel"
                  aria-labelledby={`${plan['name'].toLowerCase()}-tab`}
                >
                      {!this.state.loading && this.state.plansData?.length > 0 ?
               
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th onClick={()=> this.sortData('createdAt')}>
                        <div className='th-title'>
                        Created Date &amp; Time
                        {this.getSortIcon('createdAt')}
                        </div>
                        </th>
                        <th onClick={()=> this.sortData('call_class')}>
                        <div className='th-title'>

                        Class
                        {this.getSortIcon('call_class')}
                        </div>
                        </th>
                        <th onClick={()=> this.sortData('call_type')}>
                        <div className='th-title'>
                        Type {this.getSortIcon('call_type')}
                        </div>
                        </th>
                        <th onClick={()=> this.sortData('scripname')}>
                        <div className='th-title'>
                        Scrip {this.getSortIcon('scripname')}
                        </div>
                        </th>
                        {/* <th>Call Target</th>
                        <th>Call Status</th>
                        <th>Call Close Type</th>
                        <th>Modified</th> */}
                        <th onClick={()=> this.sortData('total_credits')}>
                        <div className='th-title'>
                        Total Credits  {this.getSortIcon('total_credits')}
                        </div>
                        </th>
                        <th onClick={()=> this.sortData('total_debits')}>
                        <div className='th-title'>
                        Total Debits  {this.getSortIcon('total_debits')}
                        </div>
                        </th>
                        <th onClick={()=> this.sortData('days')}>
                        <div className='th-title'>
                        Days {this.getSortIcon('days')}
                        </div></th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                          {/* .sort(function (a, b) {
                            return (
                              new Date(b.calls_histories.at(-1)?.createdAt) -
                              new Date(a.calls_histories.at(-1)?.createdAt)
                            );
                          }) */}
                          {/* .filter((item) => item.planid === 25) */}
                        {this.state.plansData
                          .map((call, callIndex) => {
                            let modify = `/systemcall/modify_option?id=${call.id}`;
                            if (call.call_class === 'Equity') {
                              modify = `/systemcall/modify_equity?id=${call.id}`;
                            }
                            return (
                              <tr key={callIndex}>
                                <td>
                                  {moment
                                    .utc(call?.createdAt)
                                    .utcOffset('-00:00')
                                    .format(format1)}
                                  <br />
                                  {/* {moment
                                    .utc(call?.createdAt)
                                    .utcOffset('-00:00')
                                    .format('hh:mm A')} */}
                                </td>
                                <td>{call.call_class}</td>
                                <td>{call.call_type}</td>
                                <td>{call.scripname}</td>
                                {/* <td>{call.call_target}</td> */}
                                {/* <td>
                                  {call.call_status === 'view'
                                    ? 'Pending'
                                    : call.call_status}
                                </td>
                                <td>
                                  {call.close_type === null
                                    ? '---'
                                    : call.close_type}
                                </td>
                                <td>
                                  {call.calls_histories?.length > 1
                                    ? 'Yes'
                                    : 'No'}
                                </td> */}
                                <td>{call.total_credits ? call.total_credits : "- -"}</td>
                                <td>{call.total_debits ? call.total_debits : "- -"}</td>
                                <td>{call.days ? call.days : "- -"}</td>
                                <td>
                                  <NavLink
                                    exact
                                    to={{
                                      // pathname: "/newcall/calldetails",
                                      pathname: `/systemcall/calldetails?id=${call.id}`,
                                      // callDetails: null,
                                      callDetails: call,
                                    }}
                                    title="Details"
                                  >
                                    Details
                                  </NavLink>
                                  {call.call_status === 'closed' ? null : (
                                    <>
                                      {/* <NavLink
                                        exact
                                        to={`/systemcall/close?id=${call.id}`}
                                        title="Close"
                                      >
                                        Close
                                      </NavLink> */}
                                      <NavLink
                                        exact
                                        to={{
                                          pathname: modify,
                                          callDetails: call,
                                        }}
                                        title="Modify"
                                      // onClick={() => console.log(call, 'passed data from call compo')}
                                      >
                                        Modify
                                      </NavLink>
                                    </>
                                  )}
                                  {/* {(call.call_status === 'closed' ||
                                    call.call_status === 'view') && ( */}
                                      <a
                                        style={{ color: '#000' }}
                                        onClick={() => this.handleDelete(call.id)}
                                      // exact
                                      // // to={`/newcall/close?id=${call.id}`}
                                      // title="Delete"
                                      >
                                        Delete
                                      </a>
                                    {/* )} */}
                                </td>
                              </tr>
                            );
                          })} 
                    </tbody>
                         
                  </table>
                  :!this.state.loading && this.state.plansData?.length == 0 &&
                          <p style={{display:"flex", justifyContent:"center", margin:"40px", alignItems:"center", fontSize:"24px", fontWeight:"bold"}}>No data found</p>
                          }

                  {/* <div class="pagination">
                    <button
                      disabled={this.state.currentPage === 1}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handlePageChange(this.state.currentPage - 1);
                      }}
                    >
                      &laquo;
                    </button>
                    {this.pagination()}
                    <button
                      disabled={this.state.currentPage === this.state?.plansData?.totalpage}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handlePageChange(this.state.currentPage + 1);
                      }}
                    >
                      &raquo;
                    </button>
                  </div> */}
                  {this.state.plansData?.length > 0 && <div class="pagination">
                    <button
                      disabled={this.state.currentPage === 1}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handlePageChange(this.state.currentPage - 1);
                      }}
                    >
                      &laquo;
                    </button>
                    {this.pagination(this.state.plansData?.totalpage)}
                    <button
                      disabled={this.state.currentPage === this.state.plansData?.totalpage}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handlePageChange(this.state.currentPage + 1);
                      }}
                    >
                      &raquo;
                    </button>
                  </div>}

                </div>
              );
            })}
            <div
              key={'intraday_calls'}
              className={'tab-pane fade'}
              id={'intraday_calls'}
              role="tabpanel"
              aria-labelledby={`intraday_calls-tab`}
            >
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Created Date &amp; Time</th>
                    <th>Class</th>
                    <th>Type</th>
                    <th>Scrip</th>
                    <th>Call Target</th>
                    <th>Call Status</th>
                    <th>Call Close Type</th>
                    <th>Modified</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.intraday_calls.data &&
                    this.state.intraday_calls.data?.map((call, callIndex) => {
                      return (
                        <tr key={1}>
                          <td>
                            {' '}
                            {moment
                              .utc(call.createdAt)
                              .utcOffset('-00:00')
                              .format(format1, 'hh:mm A')}
                          </td>
                          <td>Options</td>
                          <td>{call.call_type}</td>
                          <td>{call.scripname}</td>
                          <td>Both</td>
                          <td>{call.status}</td>
                          <td>
                            ---
                            {/*  {call.close_type === null ? '---' : call.close_type} */}
                          </td>
                          <td>
                            {/* modified {call.calls_histories.length > 1 ? 'Yes' : 'No'} */}
                            ---
                          </td>

                          <td>
                            <NavLink
                              exact
                              to={`intracall_details?id=${call.id}`}
                              title="Details"
                            >
                              Details
                            </NavLink>

                            {call.status === 'open' ? (
                              <NavLink
                                exact
                                to={`close_intradayCall?id=${call.id}`}
                                title="Close"
                              >
                                Close
                              </NavLink>
                            ) : null}

                            {call.status === 'open' ? null : (
                              <a
                                style={{ color: '#000' }}
                                onClick={() =>
                                  this.handleIntradayCallDelete(call.id)
                                }
                              >
                                Delete
                              </a>
                            )}
                            {call.status === 'open' ? (
                              <NavLink
                                exact
                                to={`modify_intradayCall?id=${call.id}`}
                                title="Modify"
                              >
                                Modify
                              </NavLink>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                {/* <tbody>
                  {this.state.intraday_calls &&
                    this.state.intraday_calls.map((call, callIndex) => {
                      let modify = `/newcall/modify_option?id=${call.id}`;
                      return (
                        <tr key={callIndex}>
                          <td>
                            {moment
                              .utc(call.calls_histories.at(-1)?.createdAt)
                              .utcOffset('-00:00')
                              .format(format1)}
                            <br />
                            {moment
                              .utc(call.calls_histories.at(-1)?.createdAt)
                              .utcOffset('-00:00')
                              .format('hh:mm A')}
                          </td>
                          <td>{call.call_class}</td>
                          <td>{call.call_type}</td>
                          <td>{call.scripname}</td>
                          <td>{call.call_target}</td>
                          <td>
                            {call.call_status === 'open'
                              ? 'Pending'
                              : call.call_status}
                          </td>
                          <td>
                            {call.close_type === null ? '---' : call.close_type}
                          </td>
                          <td>
                            {call.calls_histories.length > 1 ? 'Yes' : 'No'}
                          </td>
                          <td>
                            <NavLink
                              exact
                              to={{
                                // pathname: "/newcall/calldetails",
                                pathname: `/newcall/calldetails?id=${call.id}`,
                                callDetails: call,
                              }}
                              title="Details"
                            >
                              Details
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>{' '} */}
              </table>
            </div>

            <div
              key={'delete_calls'}
              className={'tab-pane fade'}
              id={'delete_calls'}
              role="tabpanel"
              aria-labelledby={`delete_calls-tab`}
            >
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Created Date &amp; Time</th>
                    <th>Class</th>
                    <th>Type</th>
                    <th>Scrip</th>
                    <th>Call Target</th>
                    <th>Call Status</th>
                    <th>Call Close Type</th>
                    <th>Modified</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.deleted_calls &&
                    this.state.deleted_calls
                      .sort(function (a, b) {
                        return (
                          new Date(b.calls_histories.at(-1)?.createdAt) -
                          new Date(a.calls_histories.at(-1)?.createdAt)
                        );
                      })
                      .map((call, callIndex) => {
                        let modify = `/systemcall/modify_option?id=${call.id}`;
                        if (call.call_class === 'Equity') {
                          modify = `/systemcall/modify_equity?id=${call.id}`;
                        }
                        return (
                          <tr key={callIndex}>
                            <td>
                              {moment
                                .utc(call.calls_histories.at(-1)?.createdAt)
                                .utcOffset('-00:00')
                                .format(format1)}
                              <br />
                              {moment
                                .utc(call.calls_histories.at(-1)?.createdAt)
                                .utcOffset('-00:00')
                                .format('hh:mm A')}
                            </td>
                            <td>{call.call_class}</td>
                            <td>{call.call_type}</td>
                            <td>{call.scripname}</td>
                            <td>{call.call_target}</td>
                            <td>
                              {call.call_status === 'view'
                                ? 'Pending'
                                : call.call_status}
                            </td>
                            <td>
                              {call.close_type === null
                                ? '---'
                                : call.close_type}
                            </td>
                            <td>
                              {call.calls_histories.length > 1 ? 'Yes' : 'No'}
                            </td>
                            <td>
                              <NavLink
                                exact
                                to={{
                                  // pathname: "/newcall/calldetails",
                                  pathname: `/systemcall/calldetails?id=${call.id}`,
                                  callDetails: call,
                                }}
                                title="Details"
                              >
                                Details
                              </NavLink>
                              {/* {call.call_status === "closed" ? null : (
                                    <>
                                      <NavLink
                                        exact
                                        to={`/newcall/close?id=${call.id}`}
                                        title="Close"
                                      >
                                        Close
                                      </NavLink>
                                      <NavLink
                                        exact
                                        to={{
                                          pathname: modify,
                                          callDetails: call,
                                        }}
                                        title="Modify"
                                      >
                                        Modify
                                      </NavLink>
                                    </>
                                  )}
                                  {(call.call_status === "closed" ||
                                    call.call_status === "view") && (
                                    <a
                                      style={{ color: "#000" }}
                                      onClick={() => this.handleDelete(call.id)}
                                      // exact
                                      // // to={`/newcall/close?id=${call.id}`}
                                      // title="Delete"
                                    >
                                      Delete
                                    </a>
                                  )} */}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
            <div
              key={'close_calls'}
              className={'tab-pane fade'}
              id={'close_calls'}
              role="tabpanel"
              aria-labelledby={`close_calls-tab`}
            >
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Created Date &amp; Time</th>
                    <th>Class</th>
                    <th>Type</th>
                    <th>Scrip</th>
                    <th>Call Target</th>
                    <th>Call Status</th>
                    <th>Call Close Type</th>
                    <th>Modified</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.close_calls?.data &&
                    this.state.close_calls?.data
                      .sort(function (a, b) {
                        return (
                          new Date(b.calls_histories.at(-1)?.createdAt) -
                          new Date(a.calls_histories.at(-1)?.createdAt)
                        );
                      })
                      .map((call, callIndex) => {
                        let modify = `/systemcall/modify_option?id=${call.id}`;
                        if (call.call_class === 'Equity') {
                          modify = `/systemcall/modify_equity?id=${call.id}`;
                        }
                        return (
                          <tr key={callIndex}>
                            <td>
                              {moment
                                .utc(call.calls_histories.at(-1)?.createdAt)
                                .utcOffset('-00:00')
                                .format(format1)}
                              <br />
                              {moment
                                .utc(call.calls_histories.at(-1)?.createdAt)
                                .utcOffset('-00:00')
                                .format('hh:mm A')}
                            </td>
                            <td>{call.call_class}</td>
                            <td>{call.call_type}</td>
                            <td>{call.scripname}</td>
                            <td>{call.call_target}</td>
                            <td>
                              {call.call_status === 'view'
                                ? 'Pending'
                                : call.call_status}
                            </td>
                            <td>
                              {call.close_type === null
                                ? '---'
                                : call.close_type}
                            </td>
                            <td>
                              {call.calls_histories.length > 1 ? 'Yes' : 'No'}
                            </td>
                            <td>
                              <NavLink
                                exact
                                to={{
                                  // pathname: "/newcall/calldetails",
                                  pathname: `/systemcall/calldetails?id=${call.id}`,
                                  callDetails: call,
                                }}
                                title="Details"
                              >
                                Details
                              </NavLink>
                              {/* {call.call_status === "closed" ? null : (
                                    <>
                                      <NavLink
                                        exact
                                        to={`/newcall/close?id=${call.id}`}
                                        title="Close"
                                      >
                                        Close
                                      </NavLink>
                                      <NavLink
                                        exact
                                        to={{
                                          pathname: modify,
                                          callDetails: call,
                                        }}
                                        title="Modify"
                                      >
                                        Modify
                                      </NavLink>
                                    </>
                                  )}
                                  {(call.call_status === "closed" ||
                                    call.call_status === "view") && (
                                    <a
                                      style={{ color: "#000" }}
                                      onClick={() => this.handleDelete(call.id)}
                                      // exact
                                      // // to={`/newcall/close?id=${call.id}`}
                                      // title="Delete"
                                    >
                                      Delete
                                    </a>
                                  )} */}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              {this.state.close_calls?.data?.length > 0 && <div class="pagination">
                <button
                  disabled={this.state.currentPage === 1}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handlePageChange(this.state.currentPage - 1);
                  }}
                >
                  &laquo;
                </button>
                {this.pagination(this.state.close_calls?.totalpage)}
                <button
                  disabled={this.state.currentPage === this.state.close_calls?.totalpage}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handlePageChange(this.state.currentPage + 1);
                  }}
                >
                  &raquo;
                </button>
              </div>}
            </div>
            {/* <div
              className="tab-pane fade"
              id="prime"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Created Date &amp; Time</th>
                    <th>Class</th>
                    <th>Type</th>
                    <th>Scrip</th>
                    <th>Call Target</th>
                    <th>Call Status</th>
                    <th>Modified</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>24/12/2020 10:56AM</td>
                    <td>Equity</td>
                    <td>Order-Sell</td>
                    <td>Tata Motors</td>
                    <td>
                      Entry - Rs. 2344 <br /> Exit - Rs. 2400
                    </td>
                    <td>Open</td>
                    <td>No</td>
                    <td>
                      Details <br /> Close <br />
                      Modify
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="tab-pane fade"
              id="crown"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Created Date &amp; Time</th>
                    <th>Class</th>
                    <th>Type</th>
                    <th>Scrip</th>
                    <th>Call Target</th>
                    <th>Call Status</th>
                    <th>Modified</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>24/12/2020 10:56AM</td>
                    <td>Equity</td>
                    <td>Order-Sell</td>
                    <td>Tata Motors</td>
                    <td>
                      Entry - Rs. 2344 <br /> Exit - Rs. 2400
                    </td>
                    <td>Open</td>
                    <td>No</td>
                    <td>
                      Details <br /> Close <br />
                      Modify
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
        </section>
      </section>
    );
  }
}

export default SystemCalls;
