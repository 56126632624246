import React, { Component } from "react";
import * as API from "../../configuration/apiconfig";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Datatable from "react-bs-datatable";
import Moment from "moment";
import moment from "moment";
import Swal from "sweetalert2";
import swal from "sweetalert";
import UpdatePlanModal from "./updatePlanModal";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { downloadExcel } from 'react-export-table-to-excel';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import UserTable from "./UserTable";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class index extends Component {
  constructor(props) {
    let getDate = new Date();
    let getMonth = Moment(getDate).format("MMM");
    super(props);
    this.state = {
      email: "",
      modalstatus: false,
      fromdate: new Date(),
      todate: new Date(),
      getMonth: getMonth,
      Usertabledatas: [],
      getStartDate: "",
      getEndDate: "",
      username: "",
      rowsPerPage: 5,
      srowsPerPage: 5,
      allUsers: [],
      currentPage: 0,
      totalPages: "",
      totalRecords: '',
      limit: 10,
      city:"",
      cityOptions:[],
      selectedCity:"",
      tradingCapitalOptions:[{label:"Select", value:""}, {label:"0 - 50K", value:"0-50K"}, {label:"50K - 1 Lakh", value:"50K-1Lakh"},  {label:"1 Lakh - 2 Lakh", value:"1Lakh-2Lakh"}, {label:"2 Lakh+", value:"2Lakh+"}],
      tradingCapital:"",
      pageSizeOption: [
        {
          "title": 5,
          "value": 5
        },
        {
          "title": 10,
          "value": 10
        },
        {
          "title": 15,
          "value": 15
        },
        {
          "title": 20,
          "value": 20
        },
        {
          "title": 'All',
          "value": null
        }],
      header: [
        { title: "Name", prop: "name" },
        { title: "Email", prop: "email" },
        { title: "Mobile No.", prop: "phonenumber" },
        {
          title: "City",
          prop: "city",
          cell: (row) => {
            return (
              <>
                <p>{row.city ? row?.city : "-"}</p>
              </>
            );
          },
        },
        {
          title: "Trading Capital",
          prop: "tradingCapital",
          cell: (row) => {
            return (
              <>
                <p>{row.tradingCapital ? row?.tradingCapital : "-"}</p>
              </>
            );
          },
        },

        // {
        //   title: "Newsletter",
        //   prop: "newsletter",
        //   cell: (row) => {
        //     return (
        //       <>
        //         <p>{row.newsletter ? "Subscribed" : "Not Subscribed"}</p>
        //       </>
        //     );
        //   },
        // },
        {
          prop: "plan",
          title: "Subscription type",
          sortable: true,
        },
        { title: "Date of Registration", prop: "createdAt", sortable: true },
        {
          title: "Registered Platform",
          prop: "registered_platform",
          cell: (row) => {
            return (
              <>
                <p>
                  {row?.registered_platform == 0
                    ? "App"
                    : row?.registered_platform == 1
                      ? "Web"
                      : row?.registered_platform == 0
                        ? "CMS"
                        : ""}
                </p>
              </>
            );
          },
        },
        {
          title: "Start date",
          prop: "startdate",
          cell: (row) => {
            return (
              <>
                <p>{row.startdate ? row.startdate : "---"}</p>
              </>
            );
          },
        },
        {
          title: "End date",
          prop: "enddate",
          cell: (row) => {
            return (
              <>
                <p>
                  {row.plan.planName === "Free"
                    ? "---"
                    : row.enddate
                      ? row.enddate
                      : "---"}
                </p>
              </>
            );
          },
        },
        { title: "User Trade Experience", prop: "experience" },
        {
          title: "Track trades",
          prop: "trades",
          cell: (row) => {
            return (
              <>
                {row.trackingCount == 0 ? (
                  <p>{row.trackingCount}</p>
                ) : (
                  <a
                    onClick={() =>
                      props.history.push(`/usermanagement/trades?id=${row.id}`)
                    }
                  >
                    {row.trackingCount}
                  </a>
                )}
              </>
            );
          },
        },
        { title: "Sign Up mode", prop: "authenticationPlatform" },
        {
          prop: "actions",
          title: "Actions",
          cell: (row) => {
            return (
              <>
                {row.plan.toLowerCase() !== "free" ? (
                  <a
                    onClick={() =>
                      props.history.push(`/usermanagement/details?id=${row.id}`)
                    }
                  >
                    Payment Details
                  </a>
                ) : null}
                {/* <a onClick={() => this.handleDelete(row.id)}>Delete</a> */}
                <a onClick={() => this.handleDelete(row.email)}>Delete</a>
                <a
                  onClick={() => {
                    this.setState({ email: row.email });
                    this.showModal();
                  }}
                >
                  Update Plan
                </a>
              </>
            );
          },
        },
        {
          title: "Newsletter",
          prop: "newsletter",
          cell: (row) => {
            return (
              <>
                <p>{row.newsletter ? "Subscribed" : "Not Subscribed"}</p>
              </>
            );
          },
        },
      ],
      loading:false,
    };
  }

  showModal = () => {
    this.setState({ modalstatus: true });
    // console.log(this.state.eventlist);
  };
  hidemodal = () => {
    this.setState({ modalstatus: false, email: "" });
  };

  handleDelete = async (email) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        API.callEndpoint("POST", "Bearer", `/api/admin/del-user?email=${email}`)
          .then(async (response) => {
            await this.getUserinfoTable()
          })
          .catch((error) => {
            console.log(error.error);
            // swal({
            //   text: "An error occured, try again!",
            //   icon: "info",
            // });
          });
      }
    });
  };

  componentDidMount = async () => {
    // let date = this.state.fromdate;
    // let formatedDate = date.setMonth(date.getMonth() - 24);

    const currentYear = new Date().getFullYear();
const firstDayOfYear = new Date(currentYear, 0, 1);

// const currentDate = new Date();
// const currentYear = currentDate.getFullYear();
// const currentMonth = currentDate.getMonth();
// const threeMonthsAgoMonth = currentMonth - 3;
// const yearAdjustment = threeMonthsAgoMonth < 0 ? -1 : 0;
// const targetMonth = (threeMonthsAgoMonth + 12) % 12;
// const firstDayOfTargetMonth = new Date(currentYear + yearAdjustment, targetMonth, 1);
    this.setState({
      // fromdate: formatedDate,
      fromdate:firstDayOfYear,
    });
    await this.getUserinfoTable();

    let currentDate = new Date();
    this.setState({
      getStartDate: firstDayOfYear,
      getEndDate: moment(currentDate).format("YYYY-MM-DD")
    })
  };

  getUserinfoTable = async () => {
    // const updatedFromDate = new Date(this.state.fromdate);
    // updatedFromDate.setMonth(updatedFromDate.getMonth() - 1);
    // const fromDate = moment(updatedFromDate).format("YYYY-MM-DD");
    const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const threeMonthsAgoMonth = currentMonth - 3;
const yearAdjustment = threeMonthsAgoMonth < 0 ? -1 : 0;
const targetMonth = (threeMonthsAgoMonth + 12) % 12;
const firstDayOfTargetMonth = new Date(currentYear + yearAdjustment, targetMonth, 1);


    await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/usersinfo?type=free&limit=${this.state.limit}&offset=${this.state.currentPage + 1
      }&startdate=${moment(firstDayOfTargetMonth).format(
        "YYYY-MM-DD"
      )}&enddate=${moment(this.state.todate).format(
        "YYYY-MM-DD"
      )}`
    )
      .then((response) => {
        var arr = [];
        var indexArr = response.data.data;
        for (let index = 0; index < indexArr.length; index++) {
          if (indexArr[index]?.type === "FREEUSER" && indexArr[index]?.plan) {
            arr.push({
              value: `${indexArr[index]?.email}`,
              label: `${indexArr[index]?.email}`,
            });
          }
        }
        this.setState({
          Usertabledatas: response.data.data,
          allUsers: arr,
          totalPages: response.data.totalpages,
          totalRecords: response.data.totalrecord,
          pageSizeOption: [
            {
              "title": 5,
              "value": 5
            },
            {
              "title": 10,
              "value": 10
            },
            {
              "title": 15,
              "value": 15
            },
            {
              "title": 20,
              "value": 20
            },
            {
              "title": 'All',
              "value": response.data.totalrecord
            }]
        });
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  // getCityAPI = (name) => {

  //   API.callEndpoint(
  //     "GET",
  //     '',
  //     `/getcity?city=${name}`
  //   )
  //     .then((response) => {
  //       console.log(" response.data.data",  response);
  //       const options = response?.data?.map((cityOpt)=>{
  //         return {label:cityOpt, value:cityOpt}
  //       })
  //       this.setState({
  //         cityOptions: options,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error.error);
  //       swal({
  //         text: "An error occured, try again!",
  //         icon: "info",
  //       });
  //     });
  // };

  // componentDidUpdate = async (prevProps, prevState) => {
  //   if (this.state.city !== prevState.city && this?.state?.city?.length > 3) {
  //     await this.getCityAPI(this.state.city);
  //   }
  // };

 

  updatePlan = async (id, month) => {
    const data = {
      email: this.state.email,
      planid: id,
      month: month,
    };

    return await API.callEndpoint(
      "PATCH",
      "Bearer",
      `/api/admin/update-plan`,
      data
    )
      .then((response) => {
        this.setState({ modalstatus: false });
        this.getUserinfoTable();
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  searchBtn = () => {
    let stDate = moment(this.state.fromdate).format("YYYY-MM-DD");
    let edDate = moment(this.state.todate).format("YYYY-MM-DD");
    this.setState(
      {
        getStartDate: stDate,
        getEndDate: edDate,
      },
      () => {
        this.getDateAPI(stDate, edDate);
      }
    );
  };

  getDateAPI = (fDate, lDate) => {
    this.setState({loading:true})
    let stDate = this.state.getStartDate ? this.state?.getStartDate : fDate;
    let edDate = this.state.getEndDate ? this?.state?.getEndDate : lDate;
    API.callEndpoint(
      "GET",
      "Bearer",
      `/api/usersinfo?limit=10000&offset=1&type=free&startdate=${stDate}&enddate=${edDate}${this.state.username && this.state.city && this.state.tradingCapital ?  `&username=${this.state.username}&city=${this.state.city}&tradingCapital=${this.state.tradingCapital}` : this.state.username && this.state.city  ? `&username=${this.state.username}&city=${this.state.city}`  : this.state.username && this.state.tradingCapital ? `&username=${this.state.username}&tradingCapital=${this.state.tradingCapital}` : this.state.city && this.state.tradingCapital ?`&city=${this.state.city}&tradingCapital=${this.state.tradingCapital}`  : this.state.city  ? `&city=${this.state.city}`  : this.state.tradingCapital ? `&tradingCapital=${this.state.tradingCapital}` :this.state.username ? `&username=${this.state.username}` : ""}`
      // `/api/usersinfo?limit=100&offset=0&type=free&startdate=${stDate}&enddate=${edDate}&username=${this.state.username}`
    )
      .then((response) => {
        this.setState({
          Usertabledatas: response.data.data,
          loading:false
        });
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  excelDownload = (e) => {
    // alert();
    // e.preventDefault();
    let stDate = this.state.getStartDate;
    let edDate = this.state.getEndDate;
    API.callEndpoint(
      "GET",
      "Bearer",
      `/api/usersinfo/export?startdate=${stDate}&enddate=${edDate}&type=free`
    )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  handleDownloadExcel =() => {
    
    const filteredData = this.state.Usertabledatas?.map((row) => ({
      Name: row.name, 
      Email: row.email, 
      Phonenumber:row?.phonenumber,
      City:row?.city,
      Tradingcapital:row?.tradingCapital,
      Newsletter:row?.newsletter,
      CreatedAt:row.createdAt,
      Experience:row?.experience,
      Type:row?.type,
      AuthenticationPlatform:row?.authenticationPlatform
    }));

    console.log("user_", `user_${this.state.getMonth}`);
    
    downloadExcel({
      fileName:`user_${this.state.getMonth}`,
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header:["Name", "Email", "Phonenumber","City", "Tradingcapital", "Newsletter", "CreatedAt","Experience", "Type", "AuthenticationPlatform"],
        // accept two different data structures
        body: filteredData,
      },
    });
  }


  handlePageClick = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  handleRowsPerPageChange = (e) => {
    const selectedValue = e.target.value;
    const { Usertabledatas } = this.state;

    if (Usertabledatas.length === 0) {
      this.setState({
        limit: 5,
      });
    } else if (selectedValue === "All") {
      this.setState({
        limit: this.state.totalRecords,
      });
    } else {
      this.setState({
        limit: parseInt(selectedValue, 10),
      });
    }
  };

  // handleInputChange = (inputValue) => {

  //   this.setState({ city:inputValue }, () => {
  //     if(inputValue?.length > 3){
  //       this.getCityAPI(inputValue);
  //     }
  //   });
  // };


  render() {
    return (
      <>
        <section className={`usermanage`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">
              User Management
            </h1>
            <section className="admin-bundleTable-page">
              <aside className="add-bundle">
                <h2>
                  <NavLink
                    exact
                    activeClassName="active"
                    to={"/usermanagement/create"}
                  >
                    Create New User
                  </NavLink>
                </h2>
              </aside>
            </section>
          </div>
          <section className={`contblk`}>
            <section className="row m-0 p-0" style={{display:"flex", alignItems:"center"}}>
              <section style={{display:"flex", alignItems:"center"}}>

              <section className="col-lg-2 m-0 p-0">
                <label className={`pe-2`}>From Date</label>
                <br />
                <DatePicker
                  placeholderText="Select Date"
                  selected={this.state.fromdate}
                  value={moment(this.state.fromdate)?.format("DD/MM/YYYY")}
                  onChange={(date) => this.setState({ fromdate: date })}
                />
              </section>
              <section className="col-lg-2 m-0 p-0">
                <label className={`pe-2`}>To Date</label>
                <br />
                <DatePicker
                  placeholderText="Select Date"
                  selected={this.state.todate}
                  onChange={(date) => this.setState({ todate: date })}
                  value={moment(this.state.todate)?.format("DD/MM/YYYY")}
                />
              </section>
              <section className="col-lg-2 m-0 p-0">
                <label className={`pe-2`}>User Email</label>
                {/* <Select
                  options={this.state.allUsers}
                  onChange={(e) => {
                    this.setState({
                      username: e?.value,
                    });
                  }}
                /> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter User Email"
                  value={this.state?.username}
                  onChange={(e) => {
                    this.setState({
                      username: e.target.value,
                    });
                  }}
                />
              </section>
              <section className="col-lg-2 p-0" style={{marginLeft:"20px"}}>
                <label className={`pe-2`}>City</label>
                {/* <Select
                  options={this.state.cityOptions}
                  onInputChange={this.handleInputChange}
                  onChange={(e) => {
                    // console.log("e?.value", e?.value)
                    this.setState({
                      selectedCity: e?.value,
                    });
                  }}
                /> */}

                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter City Name"
                  value={this.state?.city}
                  onChange={(e) => {
                    this.setState({
                      city: e.target.value,
                    });
                  }}
                />
              </section>
              <section className="col-lg-2 p-0" style={{marginLeft:"20px"}}>
                <label className={`pe-2`}>Trading Capital</label>
                <Select
                  options={this.state.tradingCapitalOptions}
                  onChange={(e) => {
                    // console.log("e?.value", e?.value)
                    this.setState({
                      tradingCapital: e?.value,
                    });
                  }}
                />
              </section>
             <section className="col-lg-2 m-0 p-0 btnexport">
                <button className={`btnsearch`} onClick={this.searchBtn} style={{padding:"10px", marginTop:"15px", marginLeft:"20px"}}> 
                  Search
                </button>
              </section>
              </section>
             <section style={{marginTop:"30px", display:"flex", alignItems:"end", justifyContent:"end"}}>
              <section className="col-lg-2 m-0 p-0 btnexport">
              <button onClick={this.handleDownloadExcel}>
                      Export in Excel
                    </button>

              </section>
             </section>
            </section>
            <h2 className="head text-uppercase m-0 mt-lg-4 pt-lg-3">
              {/* Paid users */}
            </h2>
            <section className={`row m-0 p-0 mt-1`}>
              <aside className={`col-12 m-0 p-0`}>
                <span className="display-rows-placement">
                  Display rows :{" "}
                  <select
                    className="display-select"
                    onChange={this.handleRowsPerPageChange}
                    value={this.state.limit}

                  >
                    {this.state.pageSizeOption.map((item) => {
                      {/* console.log("item===>", item.value) */}
                      return <option value={item.value}>{item.title}</option>;
                    })}
                  </select>
                </span>
                {/* <Datatable
                  tableHeaders={this.state.header}
                  id="table-to-xls"
                  tableBody={this.state.Usertabledatas.filter(
                    (item) => item.type !== "FREEUSER" && item.plan
                  )}
                  rowsPerPage={this.state.rowsPerPage}
                  labels={{
                    filterPlaceholder: "Search",
                  }}
                  // onSort={this.onSort}
                /> */}
              </aside>
            </section>
            <h2 className="head text-uppercase m-0 mt-lg-4  pt-lg-3">
              Free users
            </h2>
            {this.state.loading ? 
            <section className="row m-0 p-0 mt-1">
            <span style={{textAlign:"center", fontSize:"30px", marginTop:"20px", fontWeight:"600"}}>
              Loading....
            </span>
            </section> :
            <section className={`row m-0 p-0 mt-1`}>
              <aside className={`col-12 m-0 p-0`}>
                {/* <span className="display-rows-placement">
                  Display rows :{" "}
                  <select
                    className="display-select"
                    onChange={(e) => {
                      if (this.state.Usertabledatas.length === 0) {
                        this.setState({
                          srowsPerPage: 1,
                        });
                      } else if (e.target.value === "All") {
                        this.setState({
                          srowsPerPage: this.state.Usertabledatas.length,
                        });
                      } else {
                        this.setState({ srowsPerPage: e.target.value });
                      }
                    }}
                  >
                    {[5, 10, 15, 20, "All"].map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                </span> */}
                <ReactPaginate
                  pageCount={this.state.totalPages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
                <div className="scroll-x">
                  <Datatable
                    id="table-to-xls"
                    tableHeaders={this.state.header}
                    tableBody={this.state.Usertabledatas}
                    // rowsPerPage={this.state.srowsPerPage}
                    labels={{
                      filterPlaceholder: "Search",
                    }}

                  // onSort={this.onSort}
                  />
                </div>
              </aside>
            </section>}
          </section>
        </section>
        <UpdatePlanModal
          updatePlan={this.updatePlan}
          show={this.state.modalstatus}
          onHide={this.hidemodal}
          state={this.state}
        />
      </>
    );
  }
}

export default index;
