import React, { Component } from "react";
import * as API from "../../configuration/apiconfig";
import "react-datepicker/dist/react-datepicker.css";
import Datatable from "react-bs-datatable";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Swal from "sweetalert2";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import ImageModel from "./ImageModel";
import UpdateWebinar from "./UpdateWebinar";

class LiveWebinar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updateModel: false,
      singleRowData: null,
      imgData: null,
      modalstatus: false,
      fromdate: new Date(),
      todate: new Date(),
      webinarTableDatas: [],
      getStartDate: "",
      getEndDate: "",
      username: "",
      rowsPerPage: 5,
      srowsPerPage: 5,
      allUsers: [],
      currentPage: 0,
      totalPages: "",
      totalRecords: "",
      limit: 10,
      pageSizeOption: [
        {
          title: 5,
          value: 5,
        },
        {
          title: 10,
          value: 10,
        },
        {
          title: 15,
          value: 15,
        },
        {
          title: 20,
          value: 20,
        },
        {
          title: "All",
          value: null,
        },
      ],
      header: [
        { title: "Name", prop: "subject" },
        {
          title: "Create Webinar",
          prop: "createdAt",
          sortable: true,
          cell: (row) => {
            return (
              <>
                <p>
                  {row.createdAt
                    ? moment(row.createdAt).format("DD-MM-YYYY HH:mm")
                    : "---"}
                </p>
              </>
            );
          },
        },
        {
          title: "Meet Url",
          prop: "",
          cell: (row) => {
            return (
              <>
                <a href={row?.meeturl} target="_blank">
                  {row?.meeturl}
                </a>
              </>
            );
          },
        },
        { title: "Status", prop: "status" },
        { title: "Notes", prop: "note" },
        {
          title: "Image Url",
          prop: "",
          cell: (row) => {
            return (
              <>
                <p
                  className="view-img"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleOpenImg(row)}
                >
                  View image
                </p>{" "}
              </>
            );
          },
        },
        {
          title: "Action",
          prop: "",
          cell: (row) => {
            return (
              <>
                <p
                  className="view-img"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleUpdate(row)}
                >
                  Update Webinar
                </p>{" "}
                <p
                  className="view-img"
                  style={{ cursor: "pointer", marginTop:"15px" }}
                  onClick={() => this.handleDelete(row)}
                >
                  Delete Webinar
                </p>

              </>
            );
          },
        },
      ],
    };
  }

  componentDidMount = async () => {
    await this.getLiveWebinarImg();
  };

  getLiveWebinarImg = async () => {
    await API.callEndpoint("GET", "Bearer", `/api/live-webinar`)
      .then((response) => {
        this.setState({ webinarTableDatas: response.data.data });
      })
      .catch((error) => {
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  handleOpenImg = (row) => {
    console.log("row", row);
    this.setState({ open: true, imgData: row?.imgurl });
  };

  handleUpdate = (row) => {
    console.log("row", row);
    this.setState({ updateModel: true, singleRowData: row });
  };

  handlePageClick = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  handleRowsPerPageChange = (e) => {
    const selectedValue = e.target.value;
    const { webinarTableDatas } = this.state;

    if (webinarTableDatas.length === 0) {
      this.setState({
        limit: 5,
      });
    } else if (selectedValue === "All") {
      this.setState({
        limit: this.state.totalRecords,
      });
    } else {
      this.setState({
        limit: parseInt(selectedValue, 10),
      });
    }
  };

  hidemodal = () => {
    this.setState({ open: false });
  };

  handleDelete = async (newId) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your webinar has been deleted.', 'success');
        API.callEndpoint('DELETE', 'Bearer', `/api/live-webinar/${newId?.id}`)
          .then(async (response) => {
            if (response.status === 200) {
              await this.getLiveWebinarImg();
            }
          })
          .catch((error) => {
            console.log(error.error);
          });
      }
    });
  };


  render() {
    return (
      <>
        <section className={`usermanage`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">
              Live Webinar
            </h1>
            <section className="admin-bundleTable-page">
              <aside className="add-bundle">
                <h2>
                  <NavLink
                    exact
                    activeClassName="active"
                    to={"/live-webinar/create"}
                  >
                    Create New Webinar
                  </NavLink>
                </h2>
              </aside>
            </section>
          </div>
          <section className={`contblk`}>
            <h2 className="head text-uppercase m-0 mt-lg-4 pt-lg-3"></h2>
            {/* <section className={`row m-0 p-0 mt-1`}>
              <aside className={`col-12 m-0 p-0`}>
                <span className="display-rows-placement">
                  Display rows :{" "}
                  <select
                    className="display-select"
                    onChange={this.handleRowsPerPageChange}
                    value={this.state.limit}
                  >
                    {this.state.pageSizeOption.map((item) => {
                      console.log("item===>", item.value);
                      return <option value={item.value}>{item.title}</option>;
                    })}
                  </select>
                </span>
              </aside>
            </section> */}
            {/* <h2 className="head text-uppercase m-0 mt-lg-4  pt-lg-3">
              Free users
            </h2> */}
            <section className={`row m-0 p-0 mt-1`}>
              <aside className={`col-12 m-0 p-0`}>
                {/* <ReactPaginate
                  pageCount={this.state.totalPages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                /> */}
                <div className="scroll-x">
                  <Datatable
                    id="table-to-xls"
                    tableHeaders={this.state.header}
                    tableBody={this.state.webinarTableDatas}
                    // rowsPerPage={this.state.srowsPerPage}
                    labels={{
                      filterPlaceholder: "Search",
                    }}

                    // onSort={this.onSort}
                  />
                </div>
              </aside>
            </section>
          </section>
        </section>
        <ImageModel
          show={this.state.open}
          onHide={this.hidemodal}
          imgData={this.state?.imgData}
        />
        {this.state.updateModel &&
        <UpdateWebinar
          show={this.state.updateModel}
          data={this.state.singleRowData}
          onHide={() => this.setState({ updateModel: false })}
          getLiveWebinarImg={this?.getLiveWebinarImg}
          
        />
  }
      </>
    );
  }
}

export default LiveWebinar;
