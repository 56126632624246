import React from "react";
import * as API from "../../configuration/apiconfig";
import Swal from "sweetalert2";
import LeftComponent from "./leftComponent";
import RightComponent from "./rightComponent";
import BulishPriceVolume from "./bulishPriceVolume";
import EquitySaveProceedLeft from "./equitySaveProceedLeft";
import EquitySaveProceedRight from "./equitySaveProceedRight";
import BulishRightComponent from "./bulishRightComponent";
import BulishSaveProceedLeft from "./bulishSaveProceedLeft";
import BulishSaveProceedRight from "./bulishSaveProceedRight";
import CallDetails from "./callDetails";
import CloseCall from "./closeCall";
//import tradeImg from "../../assets/images/maxresdefault.jpg";
import { createCall, updateCall, closeCall, getInstrumentDataBySeries, searchInstrumentID } from "./ApiCalls";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import { FaChevronLeft } from "react-icons/fa";
import moment from "moment";

class Newcall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experienced: false,
      newbie: false,
      common: false,
      free: false,
      prime: false,
      crown: false,
      spade: false,
      call_type: "",
      two_hr: false,
      equity: false,
      option: false,
      long: false,
      // new
      short: false,

      longWhichScripInput: false,
      longWhichScripGray: false,
      leftComponent: true,
      rightComponent: false,
      callTargetStatus: "",
      notify: true,
      callStatus: "",
      enter_status: 0,
      payoffcharturl: "",
      companyName: "",
      companyNameId: "",
      priceVal: "",
      entryPrice: "",
      entryPriceQuantity: "",
      exitPrice: "",
      exitPriceQuantity: "",
      stopLoss: "",
      capitalRequd: "",
      maxProfit: "",
      maxLoss: "",
      target: [""],
      freeChecked: false,
      crownChecked: false,
      // bonusChecked: false,
      primeChecked: false,
      bulishPriceComponent: false,
      proceedBtnActive: false,
      equitySaveProceedLeftComponent: false,
      equitySaveProceedRightComponent: false,
      seriesSelect: null,
      conditionsSelect: "",
      now: "",
      schedule: "",
      equityTradeLogic: "",
      equityNotes: "",
      equityTLPointArray: [],
      // whichScrip1: false,
      optionStrategySelect: false,
      optionStrategyGray: false,
      whichScrip1Input: false,
      whichScrip1Gray: false,
      equityData: [],
      optionsSelect: "",
      strategy: false,
      optionsBulish: false,
      bulishRightComponent: false,
      bulishSaveProceedLeft: false,
      bulishSaveProceedRight: false,
      parMaxProfit: "",
      parMaxLoss: "",
      parLotSize: "",
      parExpectedProfit: "",
      parCapitalRequied: "",
      parStopLoss: "",
      parSelectValue: "",
      legsArray: [
        {
          legSelect: "",
          legOption: "",
          enterStrikePrice: "",
          enterPrice1: "",
          enterPrice2: "",
          entryQty: "",
          series: "",
          leg_exited: 0,
          // new
          exitToggle: false,
          exitPrice: "",
          legExit: false,

          // new abhishek salvi
          showCMP: false,
          instrument_id:"",
        },
      ],
      legsSelectValue: "",
      optionCompanyName: "",
      optionPriceValue: "",
      totalCallsBlk: false,
      totalLegssBlk: false,
      optionFreeChecked: false,
      optionCrownChecked: false,
      // optionBonusChecked: false,
      optionPrimeChecked: false,
      optionNow: "",
      optionSchedule: "",
      optionData: [],
      optionTLPointArray: [],
      optionTradeLogic: "",
      optionNotes: "",
      callDetails: false,
      closeCall: false,
      closeProfitChecked: true,
      closeLossChecked: false,
      closeBrokenEvenChecked: false,
      callDetailsAPI: [],
      legs1SelectModify: false,
      parSelectModify: false,
      activeComponent: this.props.match.params.calltype,
      capitalAllocation: "",
      istarget: false,

      // old
      // equityPlan: 0,
      // optionPlan: 0,

      // new
      equityPlan: null,
      modifyEquityPlan:null,
      optionPlan: null,
      modifyOptionPlan:null,

      equityScrip: [],
      optionScrip: [],
      page2LotSize: "",
      modifyHeader: false,
      show_legs: 0,
      equityId: 0,
      optionId: 0,
      equityTLPointArrayNew: [],
      optionTLPointArrayNew: [],
      closeId: 0,
      backBtn: false,
      equityTLPointArrayLength: 0,
      optionTLPointArrayLength: 0,
      scheduleBtnDisable: false,

      // new
      stopLossTypeEquity: "",
      tempLoad: false,
      call_version: 1,
      fetchLtpLoading:false
    };
  }

  updateEquityPlan = (value) => {
    if(this.state.equityPlan == 25){
      this.setState({
        equityPlan: 25,
        modifyEquityPlan:value
      });
    }else{
    this.setState({
      equityPlan: value,
    });
  }
  };

  fetchCall = async () => {
    let path = this.props.location.search;
    let id = parseInt(path.slice(path.indexOf("id=") + 3));
    return await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/calls?id=${id}&childtables=history`
    )
      .then((response) => {
        return response.data[0];
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          title: "An error occured, try again!",
          text: error.error,
          icon: "info",
        });
        return undefined;
      });
  };

  updatePlanId = (value) => {
    if(this.state.optionPlan == 25){
      this.setState({
        optionPlan: 25,
        modifyOptionPlan:value
      });
    }else{
      this.setState({
        optionPlan: value,
      });
    }
  };

  modifyComponent = async () => {
    try {
      let call = this.props.location.callDetails;
      if (call === undefined) {
        call = await this.fetchCall();
      }
      //-------------------------------------- modify call equity flow ------------------------------------
      if (this.state.activeComponent.startsWith("modify_equity")) {
        if (call !== undefined) {
          // get trade logic
          let equityTLPointArray = {};
          await API.callEndpoint(
            "GET",
            "Bearer",
            `/api/calls/${call.id}/tradelogic`
          )
            .then((response) => {
              equityTLPointArray = response.data.map((item) => {
                return {
                  img: item.imageurl,
                  text: item.notes,
                };
              });
              this.setState({
                equityTLPointArray,
                equityTLPointArrayLength: equityTLPointArray.length,
                optionTLPointArrayLength: 0,
              });
            })
            .catch((error) => {
              console.log(error.error);
              this.setState({ plans: [] });
            });
          let legsArray = {};
          legsArray = call.info.legs.map((item) => {
            return {
              legSelect: item.action || "",
              // enterStrikePrice: item.strike_price.slice(0, -2),
              // legOption: item.strike_price.slice(item.strike_price.length - 2),
              enterStrikePrice: item.strike || "",
              legOption: item.instrument_type || "",
              enterPrice1: item.entry_price_start || "",
              enterPrice2: item.entry_price_end || "",
              entryQty: item.quantity || "",
              leg_exited: item?.leg_exited || 0,
              showCMP: item?.showCMP || false,
              series: new Date(
                moment(item.series, "Do MMM'YY").local().format("YYYY-MM-DD")
              ),

              // new
              exitToggle:
                item.exit_price !== undefined && item.exit_price !== ""
                  ? true
                  : false,
              exitPrice: item.exit_price !== undefined ? item.exit_price : "",
              legExit:
                item.exit_price !== undefined && item.exit_price !== ""
                  ? true
                  : false,
              showCMP: item?.showCMP || false,
              instrument_id:item?.instrument_id ? item?.instrument_id :"",

              // datastrcture
              id: item?.id || "",
              uuid: item?.uuid || "",
              entry_version: item?.entry_version || "",
              exit_version: item?.exit_version || "",
            };
          });
          this.setState({ modifyHeader: true });
          this.setState({
            equityId: call.id,
            experienced: call.call_target === "Experienced",
            newbie: call.call_target == "Newbie",
            common: call.call_target == "Both",
            equity: true,
            option: false,
            callStatus: call.call_status,
            notify: call?.calls_histories[0]?.notify,
            enter_status: call.enter_status,
            payoffcharturl: call?.payoffcharturl,
            long: call.call_type === "Long" ? true : false,
            short: call.call_type === "Long" ? false : true,
            longWhichScripInput: false,
            longWhichScripGray: true,
            rightComponent: true,
            companyName: call.scripname,
            legsArray,
            companyNameId: call.scripname_symbols,
            //priceVal: call.scripname_price,
            entryPrice: call.info.entry_price_start,
            entryPriceQuantity: call.info.entry_price_end,
            exitPrice: call.info.exit_price,
            exitPriceQuantity: call.info.quanity,
            stopLoss: call.info.stop_loss,

            // new
            stopLossTypeEquity: call.info.stop_loss_type,

            capitalRequd: call.capital_required,
            maxProfit: call.info.maximum_profit,
            maxLoss: call.info.max_loss,
            target: call.info.targets.map((item) => item.value),
            capitalAllocation: call.capital_allocation,
            equityPlan: call.planid,
            free: call.planid === 1 ? true : false,
            prime: call.planid === 2 ? true : false,
            crown: call.planid === 3 ? true : false,
            spade: call.planid === 6 ? true : false,
            call_type: this.getCalltype(call.planid),
            equityNotes: call.notes,
            now: call.scheduled_date === null ? true : false,
            schedule:
              call.scheduled_date !== null
                ? new Date(moment.utc(call.scheduled_date).format("l LT"))
                : "",
            callDetails: false,
            leftComponent: true,
            // equityTLPointArray,

            // new
            tempLoad: true,
          });
        }
      } else if (this.state.activeComponent.startsWith("modify_option")) {
        //------------------------------------ modify call options flow -------------------------------------
        if (call !== undefined) {
          this.setState({ modifyHeader: true });
          // get trade logic
          let optionTLPointArray = {};
          await API.callEndpoint(
            "GET",
            "Bearer",
            `/api/calls/${call.id}/tradelogic`
          )
            .then((response) => {
              optionTLPointArray = response.data.map((item) => {
                return {
                  img: item.imageurl,
                  smallImageurl: item?.smallImageurl,
                  text: item.notes,
                };
              });
              this.setState({
                optionTLPointArray,
                equityTLPointArrayLength: 0,
                optionTLPointArrayLength: optionTLPointArray.length,
              });
            })
            .catch((error) => {
              console.log(error.error);
              this.setState({ plans: [] });
            });

          //type of call
          let optionsBulish = false;
          let optionsBearish = false;
          let optionsNeutral = false;
          let optionsBidirectional = false;
          if (call.call_type === "Neutral") {
            optionsNeutral = true;
          } else if (call.call_type === "Bullish") {
            optionsBulish = true;
          } else if (call.call_type === "Bearish") {
            optionsBearish = true;
          } else if (call.call_type === "Bi-Di") {
            optionsBidirectional = true;
          }

          let legsArray = {};
          legsArray = call.info.legs.map((item) => {
            return {
              legSelect: item.action,
              // enterStrikePrice: item.strike_price.slice(0, -2),
              // legOption: item.strike_price.slice(item.strike_price.length - 2),
              enterStrikePrice: item.strike,
              legOption: item.instrument_type,
              enterPrice1: item.entry_price_start,
              enterPrice2: item.entry_price_end,
              entryQty: item.quantity,
              leg_exited: item?.leg_exited,
              showCMP: item?.showCMP,
              series: new Date(
                moment(item.series, "Do MMM'YY").local().format("YYYY-MM-DD")
              ),

              // new
              exitToggle:
                item.exit_price !== undefined && item.exit_price !== ""
                  ? true
                  : false,
              exitPrice: item.exit_price !== undefined ? item.exit_price : "",
              legExit:
                item.exit_price !== undefined && item.exit_price !== ""
                  ? true
                  : false,
              showCMP: item?.showCMP || false,
              instrument_id:item?.instrument_id ? item?.instrument_id :"",

              // datastrcture
              id: item?.id,
              uuid: item?.uuid,
              entry_version: item?.entry_version,
              exit_version: item?.exit_version,
            };
          });
          this.setState({
            optionId: call.id,
            experienced: call.call_target === "Experienced",
            newbie: call.call_target == "Newbie",
            common: call.call_target == "Both",
            option: true,
            equity: false,
            callStatus: call.call_status,
            enter_status: call.enter_status,
            payoffcharturl: call?.payoffcharturl,
            optionsBidirectional,
            optionsNeutral,
            optionsBearish,
            optionsBulish,
            optionStrategySelect: false,
            optionStrategyGray: true,
            optionsSelect: call.strategy,
            whichScrip1Input: false,
            whichScrip1Gray: true,
            optionCompanyName: call.scripname,
            optionCompanyNameId: call.scripname_symbols,
            //optionPriceValue: call.scripname_price,
            bulishRightComponent: true,
            legsArray,
            parMaxProfit: call.info.max_profit,
            parMaxLoss: call.info.max_loss,
            parLotSize: call.info.lot_size,
            parExpectedProfit: call.info.expected_profit,
            parCapitalRequied: call.capital_required,
            parStopLoss: call.info.stop_loss,
            parSelectValue: call.info.stop_loss_type,
            seriesSelect: call.series,
            conditionsSelect: call.iv_condition,

            // old
            // page2LotSize: call.lot_size,

            //new and update
            page2LotSize: call.lot_size,

            optionNotes: call.notes,
            optionPlan: call.planid,
            free: call.planid === 1 ? true : false,
            prime: call.planid === 2 ? true : false,
            crown: call.planid === 3 ? true : false,
            spade: call.planid === 6 ? true : false,
            call_type: this.getCalltype(call.planid),
            optionNow: call.scheduled_date === null ? true : false,
            optionSchedule:
              call.scheduled_date !== null
                ? new Date(moment.utc(call.scheduled_date).format("l LT"))
                : "",
            legs1SelectModify: true,
            parSelectModify: true,
            callDetails: false,
            leftComponent: true,

            // new
            tempLoad: true,
            call_version: call.call_version,
          });
        }
      } else if (this.state.activeComponent.startsWith("calldetails")) {
        this.setState({
          leftComponent: false,
          callDetails: true,
          closeCall: false,

          // new
          tempLoad: true,
        });
      } else if (this.state.activeComponent.startsWith("close")) {
        this.setState({
          leftComponent: false,
          callDetails: true,
          closeCall: true,
          closeId: call.id,

          // new
          tempLoad: true,
        });
      } else if (this.state.activeComponent === "new") {
        this.setState({ modifyHeader: false, tempLoad: true });
      }
    } catch (e) {
      swal({
        text: "An error occured, try again!",
        icon: "info",
      });
    }
  };

  componentDidMount = async () => {
    this.modifyComponent();
    //this.getCallDetailsAPI();
  };

  getCallDetailsAPI = () => {
    API.callEndpoint("GET", "Bearer", "/api/calls")
      .then((response) => {
        this.setState({
          callDetailsAPI: response.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
  };
  getCalltype(id) {
    let name;
    console.log(id, 'id check in switch');
    // eslint-disable-next-line default-case
    switch (id) {
      case 1:
        name = "Free"
        break;
      case 2:
        name = "Prime"
        break;
      case 3:
        name = "Crown"
        break;
      case 5:
        name = "2HR Trader"
        break;
        case 6:
          name = "Spades"
          break;  
    }
    return name;
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.match.params.calltype !== prevProps.match.params.calltype) {
      this.setState(
        {
          activeComponent: this.props.match.params.calltype,
        },
        () => {
          this.modifyComponent();
        }
      );
    }
  };

  callUserType = (txt) => {
    
    if(this.state.optionPlan !== null && this.state.optionPlan == 25){
      this.setState({ 
        free: false,
        prime: false,
        crown: false,
        two_hr: false,
        spade:false,})
      this.setState({
        [txt]: true,
      });
    }else{
      this.setState({
        free: false,
        prime: false,
        crown: false,
        two_hr: false,
        spade:false,
        experienced: false,
        newbie: false,
        common: false,
        equity: false,
        longWhichScripInput: false,
        longWhichScripGray: false,
        rightComponent: false,
        option: false,
        optionStrategySelect: false,
        optionStrategyGray: false,
        whichScrip1Input: false,
        whichScrip1Gray: false,
        bulishRightComponent: false,
        companyName: "",
      });
      this.setState({
        [txt]: true,
      });
    }
  };

  callTarget = (txt) => {
    this.setState({
      free: this.state.free,
      prime: this.state.prime,
      crown: this.state.crown,
      spade: this.state.spade,

      equityPlan: this.state.equityPlan,
      optionPlan: this.state.optionPlan,

      // new
      optionsBulish: false,
      optionsBearish: false,
      optionsNeutral: false,
      optionsBidirectional: false,

      experienced: false,
      newbie: false,
      common: false,
      equity: false,

      option: false,
      //new
      long: false,
      short: false,

      longWhichScripInput: false,
      longWhichScripGray: false,

      // new
      leftComponent: true,

      rightComponent: false,

      // new
      callStatus: "",
      enter_status: 0,
      payoffcharturl: "",
      companyNameId: "",
      priceVal: "",
      entryPrice: "",
      entryPriceQuantity: "",
      exitPrice: "",
      exitPriceQuantity: "",
      stopLoss: "",
      capitalRequd: "",
      maxProfit: "",
      target: [""],
      freeChecked: false,
      crownChecked: false,
      primeChecked: false,
      bulishPriceComponent: false,
      proceedBtnActive: false,
      equitySaveProceedLeftComponent: false,
      equitySaveProceedRightComponent: false,
      seriesSelect: null,
      conditionsSelect: "",
      now: "",
      schedule: "",
      equityTradeLogic: "",
      equityNotes: "",
      equityTLPointArray: [],
      optionStrategyGray: false,
      equityData: [],
      optionsSelect: "",
      strategy: false,
      optionsBulish: false,
      bulishSaveProceedLeft: false,
      bulishSaveProceedRight: false,
      parMaxProfit: "",
      parMaxLoss: "",
      parLotSize: "",
      parExpectedProfit: "",
      parCapitalRequied: "",
      parStopLoss: "",
      parSelectValue: "",
      legsArray: [
        {
          legSelect: "",
          legOption: "",
          enterStrikePrice: "",
          enterPrice1: "",
          enterPrice2: "",
          entryQty: "",
          series: "",
          exitToggle: false,
          exitPrice: "",
          legExit: false,
        },
      ],
      legsSelectValue: "",
      optionCompanyName: "",
      optionPriceValue: "",
      totalCallsBlk: false,
      totalLegssBlk: false,
      optionFreeChecked: false,
      optionCrownChecked: false,
      optionPrimeChecked: false,
      optionNow: "",
      optionSchedule: "",
      optionData: [],
      optionTLPointArray: [],
      optionTradeLogic: "",
      optionNotes: "",
      callDetails: false,
      closeCall: false,
      closeProfitChecked: true,
      closeLossChecked: false,
      closeBrokenEvenChecked: false,
      callDetailsAPI: [],
      legs1SelectModify: false,
      parSelectModify: false,
      activeComponent: this.props.match.params.calltype,
      capitalAllocation: "",
      istarget: false,
      equityScrip: [],
      optionScrip: [],
      page2LotSize: "",
      modifyHeader: false,
      equityId: 0,
      optionId: 0,
      equityTLPointArrayNew: [],
      optionTLPointArrayNew: [],
      closeId: 0,
      backBtn: false,
      equityTLPointArrayLength: 0,
      optionTLPointArrayLength: 0,
      scheduleBtnDisable: false,
      stopLossTypeEquity: "",
      tempLoad: true,

      optionStrategySelect: false,
      whichScrip1Input: false,
      whichScrip1Gray: false,
      bulishRightComponent: false,
      companyName: "",
    });
    this.setState({
      [txt]: true,
    });
  };

  callClass = (txt) => {
    this.setState({
      equity: false,
      option: false,
      long: false,
      rightComponent: false,
      bulishPriceComponent: false,
      proceedBtnActive: false,
      strategy: false,
      optionsBulish: false,
      bulishRightComponent: false,
      whichScrip1Gray: false,
      whichScrip1Input: false,
      optionStrategySelect: false,
      optionStrategyGray: false,
      longWhichScripGray: false,
      longWhichScripInput: false,
      companyName: "",

      // new
      optionsBearish: false,
      optionsNeutral: false,
      optionsBidirectional: false,
      short: false,
      leftComponent: true,
      notify: true,
      callStatus: "",
      enter_status: 0,
      payoffcharturl: "",
      companyNameId: "",
      priceVal: "",
      entryPrice: "",
      entryPriceQuantity: "",
      exitPrice: "",
      exitPriceQuantity: "",
      stopLoss: "",
      capitalRequd: "",
      maxProfit: "",
      target: [""],
      freeChecked: false,
      crownChecked: false,
      primeChecked: false,
      equitySaveProceedLeftComponent: false,
      equitySaveProceedRightComponent: false,
      seriesSelect: null,
      conditionsSelect: "",
      now: "",
      schedule: "",
      equityTradeLogic: "",
      equityNotes: "",
      equityTLPointArray: [],
      equityData: [],
      optionsSelect: "",
      bulishSaveProceedLeft: false,
      bulishSaveProceedRight: false,
      parMaxProfit: "",
      parMaxLoss: "",
      parLotSize: "",
      parExpectedProfit: "",
      parCapitalRequied: "",
      parStopLoss: "",
      parSelectValue: "",
      legsArray: [
        {
          legSelect: "",
          legOption: "",
          enterStrikePrice: "",
          enterPrice1: "",
          enterPrice2: "",
          entryQty: "",
          series: "",
          exitToggle: false,
          exitPrice: "",
          legExit: false,
        },
      ],
      legsSelectValue: "",
      optionCompanyName: "",
      optionPriceValue: "",
      totalCallsBlk: false,
      totalLegssBlk: false,
      optionFreeChecked: false,
      optionCrownChecked: false,
      optionPrimeChecked: false,
      optionNow: "",
      optionSchedule: "",
      optionData: [],
      optionTLPointArray: [],
      optionTradeLogic: "",
      optionNotes: "",
      callDetails: false,
      closeCall: false,
      closeProfitChecked: true,
      closeLossChecked: false,
      closeBrokenEvenChecked: false,
      callDetailsAPI: [],
      legs1SelectModify: false,
      parSelectModify: false,
      activeComponent: this.props.match.params.calltype,
      capitalAllocation: "",
      istarget: false,
      equityPlan: this.state.equityPlan,
      optionPlan: this.state.optionPlan,
      equityScrip: [],
      optionScrip: [],
      page2LotSize: "",
      modifyHeader: false,
      equityId: 0,
      optionId: 0,
      equityTLPointArrayNew: [],
      optionTLPointArrayNew: [],
      closeId: 0,
      backBtn: false,
      equityTLPointArrayLength: 0,
      optionTLPointArrayLength: 0,
      scheduleBtnDisable: false,
      stopLossTypeEquity: "",
      tempLoad: true,
    });
    this.setState({
      [txt]: true,
    });
    try {
      const searchElem = document.getElementById("search");
      searchElem.style.display = "block";
      searchElem.value = "";
      const autoCompleteElem = document.getElementById("autocomplete");
      autoCompleteElem.style.display = "block";
      autoCompleteElem.innerHTML = "";
      document.getElementById("which-scrip-show").style.display = "none";
    } catch (e) {
      console.log(e.error);
    }
  };

  typeofCall = (txt) => {
    let long = false;
    let short = false;
    if (txt === "long") {
      long = true;
    } else {
      short = true;
    }

    let longWhichScripInput = true;
    if (this.state.modifyHeader) {
      longWhichScripInput = false;
    } else if (this.state.companyName !== "") {
      longWhichScripInput = false;
    }

    this.setState({
      long,
      short,
      longWhichScripInput,
    });
  };

  optionsTypeofCall = (txt) => {
    let optionsBulish = false;
    let optionsBearish = false;
    let optionsNeutral = false;
    let optionsBidirectional = false;

    if (txt === "optionsBulish") {
      optionsBulish = true;
    } else if (txt === "optionsBearish") {
      optionsBearish = true;
    } else if (txt === "optionsNeutral") {
      optionsNeutral = true;
    } else {
      optionsBidirectional = true;
    }
    let optionStrategySelect;
    let optionStrategyGray;

    if (this.state.optionsSelect === "") {
      optionStrategySelect = true;
      optionStrategyGray = false;
    } else {
      optionStrategySelect = false;
      optionStrategyGray = true;
    }
    this.setState({
      optionsBearish,
      optionsBulish,
      optionsNeutral,
      optionsBidirectional,
      // optionsSelect: "",
      optionStrategySelect,
      optionStrategyGray,
    });
  };

  updatePayOffChart = ()=>{
    this.setState({
      payoffcharturl:""
    })
  }


  // old
  // rightComponentShowFun = (event) => {
  //   const whichScripText = event.target.innerHTML;
  //   document.getElementById("which-scrip-append").innerHTML = whichScripText;

  //   const whichScripChild =
  //     document.getElementById("which-scrip-append").childNodes;
  //   const child1 = whichScripChild[0].textContent;
  //   const child2 = whichScripChild[1].textContent;

  //   this.setState({
  //     rightComponent: true,
  //     companyName: child1,
  //     companyNameId: event.target.id,
  //     priceVal: child2,
  //     optionsSelect: "",
  //     longWhichScripInput: false,
  //     longWhichScripGray: true,
  //   });
  //   try {
  //     document.getElementById("search").style.display = "none";
  //     document.getElementById("autocomplete").style.display = "none";
  //     document.getElementById("which-scrip-show").style.display = "block";

  //   } catch (e) {
  //     // console.log(e);
  //   }
  // };

  // new
  rightComponentShowFun = (event, value) => {
    try {
      this.setState({
        rightComponent: true,
        companyName: value,
        companyNameId: event.target.id,
        priceVal: "",
        optionsSelect: "",
        longWhichScripInput: false,
        longWhichScripGray: true,
      });

      document.getElementById("search").style.display = "none";
      document.getElementById("autocomplete").style.display = "none";
      document.getElementById("which-scrip-show").style.display = "block";
    } catch (e) {
      console.log(e);
    }
  };

  // Autocomplete function > bulish input field
  autoCompleteFun = async (e) => {
    // new
    this.setState({ equityScrip: [] });

    let data = [];
    let price = [];
    try {
      if (e.target.value.length >= 3) {
        await API.callEndpoint(
          "GET",
          "Bearer",
          "/api/search?text=" + e.target.value
        )
          .then((response) => {
            try {
              this.setState({ equityScrip: response.data });
            } catch (error) {
              console.log(error);
              this.setState({ equityScrip: [] });
            }
          })
          .catch((e) => {
            console.log(e.error);
            this.setState({ equityScrip: [] });
          });
      } else {
        this.setState({ equityScrip: [] });
      }
    } catch (e) {
      this.setState({ equityScrip: [] });
    }
  };

  // Autocomplete function > option input field
  autoCompleteFun1 = async (e) => {
    let data = [];
    let price = [];
    try {
      if (e.target.value.length >= 3) {
        await API.callEndpoint(
          "GET",
          "Bearer",
          "/api/search?text=" + e.target.value
        )
          .then((response) => {
            try {
              this.setState({ optionScrip: response.data });
            } catch (error) {
              console.log(error);
              this.setState({ optionScrip: [] });
            }
          })
          .catch((e) => {
            console.log(e.error);
            this.setState({ optionScrip: [] });
          });
      } else {
        this.setState({ optionScrip: [] });
      }
    } catch (e) {
      this.setState({ optionScrip: [] });
    }
  };

  // LeftComponent > edit function
  editBtn = () => {
    try {
      const searchElem = document.getElementById("search");
      searchElem.style.display = "block";
      searchElem.value = "";
      searchElem.focus();
      document.getElementById("autocomplete").style.display = "block";
      document.getElementById("which-scrip-show").style.display = "none";
      this.autoCompleteFun();
      this.setState({
        // rightComponent: false,
        bulishPriceComponent: false,
        proceedBtnActive: false,
        longWhichScripInput: true,
        longWhichScripGray: false,
      });
    } catch (e) {
      console.log(e.error);
    }
  };

  // RightComponent > save Proceed button > click functon
  saveProceedBtn = (event) => {
    this.setState({
      leftComponent: false,
      rightComponent: false,
      equitySaveProceedLeftComponent: true,
      bulishPriceComponent: false,
      equitySaveProceedRightComponent: true,
      backBtn: true,
    });
  };

  backBtn = (event) => {
    this.setState({
      leftComponent: true,
      rightComponent: true,
      equitySaveProceedLeftComponent: false,
      bulishPriceComponent: false,
      equitySaveProceedRightComponent: false,
      backBtn: false,
    });
  };

  // RightComponent > input onChange > function
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
        .split("")
        .filter((item) => item.match(/[0-9\\.]/i))
        .join(""),
    });
  };

  // new
  // RightComponent > input onChange > function
  handleChangeSelect = (value) => {
    this.setState({
      stopLossTypeEquity: value.value,
    });
  };

  // equitySaveProceedRight > input onChange > function
  capitalOnChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  // bulish price volume > component > exit click function
  priceExitBtn = () => {
    this.setState({
      rightComponent: true,
      bulishPriceComponent: false,
      proceedBtnActive: false,
    });
  };

  TargetField = (e) => {
    const getTarget = [...this.state.target];
    getTarget.push("");
    if (getTarget.length > 0) {
      this.setState({ istarget: false });
    } else {
      this.setState({ istarget: true });
    }
    console.log(e.target.name);
    this.setState({
      target: getTarget,
    });
  };

  inputOnchange = (e, index) => {
    this.state.target[index] = e.target.value;
    this.setState({
      target: this.state.target,
    });
  };

  // Equity > Trade logic and Notes > onchange > function
  tradeNoteOnchange = (e, parTxt) => {
    this.setState({
      [parTxt]: e.target.value,
    });
  };

  // Option > series selected
  select1OnChange = (seriesSelect) => {
    this.setState({ seriesSelect: seriesSelect });
    console.log(`Option selected:`, seriesSelect);
  };

  // Option > VI Condition selected
  select2OnChange = (conditionsSelect) => {
    this.setState({ conditionsSelect: conditionsSelect.value });
  };

  toggleClass = (txt, value) => {
    this.setState({
      now: "",
      schedule: "",
    });
    this.setState({
      [txt]: value,
    });
  };

  resetNow = () => {
    this.setState({
      now: "",
    });
  };

  resetOptionNow = () => {
    this.setState({
      optionNow: "",
    });
  };

  setScheduleDisable = (value) => {
    this.setState({ scheduleBtnDisable: value });
  };

  // old
  // scheduleBtnClick = async () => {
  //   let data = {
  //     equityData: [
  //       {
  //         experienced: this.state.experienced ? "Experienced" : "",
  //         newbie: this.state.newbie ? "Newbie" : "",
  //         both: this.state.common ? "Both" : "",
  //         equity: this.state.equity ? "Equity" : "",
  //         long: this.state.long ? "Long" : "",
  //         capitalRequd: this.state.capitalRequd,
  //         maxProfit: this.state.maxProfit,
  //         equityPlan: this.state.equityPlan,
  //         whichscripName: this.state.companyName,
  //         whichScripSymbol: this.state.companyNameId,
  //         whichscripPrice: this.state.priceVal,
  //         entryPrice: this.state.entryPrice,
  //         entryPriceQuantity: this.state.entryPriceQuantity,
  //         exitPrice: this.state.exitPrice,
  //         exitPriceQuantity: this.state.exitPriceQuantity,
  //         stopLoss: this.state.stopLoss,
  //         target: [this.state.target],
  //         now: this.state.now,
  //         schedule: this.state.schedule,
  //         equityTLPointArray: [this.state.equityTLPointArrayNew],
  //         equityNotes: this.state.equityNotes,
  //         capitalAllocation: this.state.capitalAllocation,
  //         callStatus: this.state.callStatus,
  //       },
  //     ],
  //   };
  //   this.setState({ equityData: data.equityData, scheduleBtnDisable: true });

  //   if (
  //     !this.state.modifyHeader &&
  //     (await createCall(data.equityData, this.setScheduleDisable))
  //   ) {
  //     this.props.history.push("/calls");
  //   }
  //   if (
  //     this.state.modifyHeader &&
  //     (await updateCall(
  //       data.equityData,
  //       this.state.equityId,
  //       this.setScheduleDisable
  //     ))
  //   ) {
  //     this.props.history.push("/calls");
  //   }
  // };

  // new
  scheduleBtnClick = async () => {
    if (this.state.equityPlan === null) {
      swal({
        text: "Select a plan",
        icon: "error",
      });
    } else if (this.state.now === "" && this.state.schedule === "") {
      swal({
        text: "Select a publish type",
        icon: "error",
      });
    } else {
      let data = {
        equityData: [
          {
            experienced: this.state.experienced ? "Experienced" : "",
            newbie: this.state.newbie ? "Newbie" : "",
            both: this.state.common ? "Both" : "",
            equity: this.state.equity ? "Equity" : "",
            long: this.state.long ? "Long" : "",
            capitalRequd: this.state.capitalRequd,
            maxProfit: this.state.maxProfit,
            maxLoss: this.state.maxLoss,
            equityPlan: this.state.modifyEquityPlan ? this.state.modifyEquityPlan :  this.state.equityPlan,
            whichscripName: this.state.companyName,
            whichScripSymbol: this.state.companyNameId,
            legsArray: [this.state.legsArray],
            whichscripPrice: this.state.priceVal,
            entryPrice: this.state.entryPrice,
            entryPriceQuantity: this.state.entryPriceQuantity,
            exitPrice: this.state.exitPrice,
            exitPriceQuantity: this.state.exitPriceQuantity,
            stopLoss: this.state.stopLoss,
            target: [this.state.target],
            now: this.state.now,
            schedule: this.state.schedule,
            equityTLPointArray: [this.state.equityTLPointArrayNew],
            equityNotes: this.state.equityNotes,
            capitalAllocation: this.state.capitalAllocation,
            notify: this.state.notify,
            callStatus: this.state.callStatus,
            enter_status: this.state.enter_status,
            payoffcharturl: this.state?.payoffcharturl,
            show_legs: this.state.show_legs,
            stopLossTypeEquity: this.state.stopLossTypeEquity,
          },
        ],
      };
      this.setState({ equityData: data.equityData, scheduleBtnDisable: true });

      if (
        !this.state.modifyHeader &&
        (await createCall(data.equityData, this.setScheduleDisable))
      ) {
        this.props.history.push("/calls");
      }
      if (
        this.state.modifyHeader &&
        (await updateCall(
          data.equityData,
          this.state.equityId,
          this.setScheduleDisable
        ))
      ) {
        this.props.history.push("/calls");
      }
    }
  };

  // Disabled button > function
  checkDisabled = () => {
    var ifDisabled = true;
    if (
      this.state.entryPrice.length === 0 ||
      this.state.entryPriceQuantity.length === 0 ||
      this.state.exitPrice.length === 0 ||
      this.state.exitPriceQuantity.length === 0 ||
      this.state.stopLoss.length === 0 ||
      this.state.capitalRequd.length === 0 ||
      this.state.maxProfit.length === 0
    ) {
      ifDisabled = true;
    } else {
      ifDisabled = false;
      for (var i = 0; i < this.state.target.length; i++) {
        var targetItem = this.state.target[i];
        if (targetItem.length === 0) {
          ifDisabled = true;
          break;
        } else {
          ifDisabled = false;
        }
      }
    }
    return ifDisabled;
  };

  // Equity radio button > function
  forCheckedFields = (e, value) => {
    console.log(value);
    this.setState({
      equityPlan: value,
    });
  };

  // Equity target delete button > click function
  targetDeleteBtn = (index) => {
    const getArray = [...this.state.target];
    getArray.splice(index, 1);
    if (getArray.length > 0) {
      this.setState({ istarget: false });
    } else {
      this.setState({ istarget: true });
    }
    console.log(getArray.length);
    this.setState({
      target: getArray,
    });
  };

  // Options radio button > function
  optionForCheckedFields = (e, value) => {
    this.setState({
      optionPlan: value,
    });
  };

  // Options > now shedule > toggle class
  optionToggleClass = (txt, value) => {
    this.setState({
      optionNow: "",
      optionSchedule: "",
    });
    this.setState({
      [txt]: value,
    });
  };

  // Options > select onchange
  optionsSelectOnChange = (optionsSelect) => {
    this.setState({
      optionsSelect: optionsSelect.value,
      // strategy: true,
      // optionsBulish: true,
      optionStrategySelect: false,
      optionStrategyGray: true,
      whichScrip1Input: true,
    });
  };

  // new
  optionsSelectType = (e) => {
    this.setState({
      optionsSelect: e.target.value,
      optionStrategySelect: false,
      optionStrategyGray: true,
      whichScrip1Input: true,
    });
  };
  optionsDeselect = () => {
    this.setState({
      optionsSelect: "",
      optionStrategyGray: false,
      optionStrategySelect: true,
    });
  };

  // Options > Legs > select onchange
  legsSelectOnchange = (index, getParameter) => (legsSelectValue) => {
    this.state.legsArray[index][getParameter] = legsSelectValue.value;
    const getValue = legsSelectValue.value;

    // old
    // this.setState({
    //   legsArray: this.state.legsArray,
    //   getValue,
    // });

    // new
    this.setState(
      {
        legsArray: this.state.legsArray,
        getValue,
      },
      () => {
        console.log(index);
        console.log(JSON.stringify(this.state.legsArray));
      }
    );
  };

  // Options > Legs > select onchange
  legsSelectOptionOnchange = (index, getParameter) => (legsSelectValue) => {
    console.log(index, getParameter, legsSelectValue);
    this.state.legsArray[index][getParameter] = legsSelectValue.value;

    const getValue = legsSelectValue.value;
    this.setState({
      legsArray: this.state.legsArray,
      getValue,
    });
  };

  legsSelectOptionOnchange1 = (index, getParameter) => (legsSelectValue) => {
    console.log(index, getParameter, legsSelectValue);
    this.state.legsArray[index][getParameter] = legsSelectValue;
    const getValue = legsSelectValue;
    this.setState({
      legsArray: this.state.legsArray,
      getValue,
    });
  };

  // Participation Data > select value
  parSelectOnChange = (parSelectValue) => {
    this.setState({
      parSelectValue: parSelectValue.value,
    });
  };

  // Option > RightComponent > input onChange > function
  legFieldsOnchange = (e, index) => {
    const { name, value } = e.target;
    this.state.legsArray[index][name] = value
      .split("")
      .filter((item) => item.match(/[0-9\\.]/i))
      .join("");
    this.setState({
      legsArray: this.state.legsArray,
      value,
    });
  };

  //new
  // Option > RightComponent > input onChange > function
  // legFieldsOnchangeBoolean = (e, index) => {
  //   const { name, checked } = e.target;
  //   let call_version = this.state.call_version;
  //   this.state.legsArray[index][name] = checked;
  //   if (!checked) {
  //     this.state.legsArray[index]["exitPrice"] = "";
  //     this.state.legsArray[index]["exit_version"] = null;
  //   } else {
  //     // this.state.legsArray[index]["exit_version"] =
  //     //   parseInt(this.state.call_version) + 1;
  //     // call_version = parseInt(call_version) + 1;
  //   }
  //   // this.setState({
  //   //   legsArray: this.state.legsArray,
  //   //   call_version,
  //   // });
  // };
  legFieldsOnchangeBoolean = (e, index) => {
    const { name, checked } = e.target;
    let call_version = this.state.call_version;
    this.state.legsArray[index][name] = checked;
    if (!checked) {
      this.state.legsArray[index]["exitPrice"] = "";
      this.state.legsArray[index]["exit_version"] = null;
    } else {
      this.state.legsArray[index]["exitPrice"] =
        this.state.legsArray[index]["exitPrice"];
    }
    this.setState({
      legsArray: this.state.legsArray,
      call_version,
    });
  };

  showCmpOnchangeBoolean = (e, index) => {
    const { name, checked } = e.target;
    this.state.legsArray[index][name] = checked;
    this.setState({
      legsArray: this.state.legsArray,
    });
  };
  changeshowlegs = (e, index) => {
    const { name, checked } = e.target;
    this.setState({
      show_legs: checked == true ? 0 : 1,
    });
  };
  // Legs delete button
  legDeleteClick = (index) => {
    const getArray = [...this.state.legsArray];
    getArray.splice(index, 1);
    this.setState({
      legsArray: getArray,
    });
  };

  updateCallStatus = (value) => {
    this.setState({ callStatus: value });
  };
  updateNotify = (value) => {
    // console.log(value, 'value of notify');
    this.setState({ notify: value })
  }
  updateCallEnterStatus = (value) => {
    this.setState({ enter_status: value });
  };
  updateCallTarget = (value) => {
    this.setState({ callTargetStatus: value });
  };

  //old
  // option right component > show function
  // optionRightComponentShowFun = (event) => {
  //   // console.log(event.target.textContent);
  //   // console.log(event.target.innerHTML);
  //   // console.log(event.target.tagName);

  //   const whichScripText = event.target.innerHTML;
  //   document.getElementById("which-scrip-append1").innerHTML = whichScripText;

  //   const whichScripChild = document.getElementById(
  //     "which-scrip-append1"
  //   ).childNodes;
  //   const child1 = whichScripChild[0].textContent;
  //   const child2 = whichScripChild[1].textContent;
  //   console.log(child1, child2);

  //   this.setState({
  //     bulishRightComponent: true,
  //     optionCompanyName: child1,
  //     optionCompanyNameId: event.target.id,
  //     optionPriceValue: child2,
  //     whichScrip1Input: false,
  //     whichScrip1Gray: true,
  //     optionScrip: [],
  //   });
  //   // document.getElementById('search1').style.display = 'none';
  //   // document.getElementById('autocomplete1').style.display = 'none';

  //   const searchElem = document.getElementById("search1");
  //   searchElem.style.display = "block";
  //   searchElem.value = "";
  //   // const autoCompleteElem = document.getElementById("autocomplete1");
  //   // autoCompleteElem.style.display = "block";
  //   // autoCompleteElem.innerHTML = "";
  //   // document.getElementById("which-scrip-show1").style.display = "block";
  // };

  //new
  // option right component > show function
  optionRightComponentShowFun = (event, value) => {
    this.setState({
      bulishRightComponent: true,
      optionCompanyName: value,
      optionCompanyNameId: event.target.id,
      // optionPriceValue: child2,
      whichScrip1Input: false,
      whichScrip1Gray: true,
      optionScrip: [],
    });

    const searchElem = document.getElementById("search1");
    searchElem.style.display = "block";
    searchElem.value = "";
    // const autoCompleteElem = document.getElementById("autocomplete1");
    // autoCompleteElem.style.display = "block";
    // autoCompleteElem.innerHTML = "";
    // document.getElementById("which-scrip-show1").style.display = "block";
  };

  // option > call > edit button
  optionCallEdit = () => {
    this.setState({
      strategy: false,
      optionStrategySelect: true,
      optionStrategyGray: false,
    });
  };

  // option > add button > function
  optionAddBtn = (e) => {
    // this.setState({
    //   legsArray: [...this.state.legsArray, '']
    // })

    this.setState({
      legsArray: [
        ...this.state.legsArray,
        {
          legSelect: "",
          enterStrikePrice: "",
          enterPrice1: "",
          enterPrice2: "",
          entryQty: "",
          series: "",
          leg_exited: 0,
          showCMP: false
        },
      ],
    });
  };

  // option > which scrip > edit button
  optionWSEdit = () => {
    this.setState({
      whichScrip1Gray: false,
      whichScrip1Input: true,
    });

    // const searchElem = document.getElementById("search1");
    // searchElem.style.display = "block";
    // searchElem.value = "";
    // const autoCompleteElem = document.getElementById("autocomplete1");
    // autoCompleteElem.style.display = "block";
    // autoCompleteElem.innerHTML = "";
  };

  // option > legs > edit button
  legsEdit = () => {
    this.setState({
      totalCallsBlk: false,
    });
  };

  totalCallEditBtn = () => {
    this.setState({
      totalCallsBlk: false,
      totalLegssBlk: true,
    });
  };

  legsDoneBtn = (e) => {
    e.preventDefault();
    this.setState({
      totalCallsBlk: true,
      totalLegssBlk: false,
    });
  };

  parDataOnChange = (e, index) => {
    // const { name, value } = e.target;
    // this.state.parData[index][name] = value
    // this.setState({
    //   parData: this.state.parData, value
    // });

    const { name, value } = e.target;
    this.setState({
      [name]: value
        .split("")
        .filter((item) => item.match(/[0-9\\.]/i))
        .join(""),
    });
  };

  //old
  // option save & proceed > buttom button
  // optionSaveProceedBtn = () => {
  //   this.setState({
  //     bulishSaveProceedLeft: true,
  //     bulishSaveProceedRight: true,
  //     leftComponent: false,
  //     bulishRightComponent: false,
  //     backBtn: true,
  //   });
  // };

  //new
  // option save & proceed > buttom button
  optionSaveProceedBtn = async () => {
    let error = false;

    await this.state.legsArray.map((item, index) => {
      if (
        item.exitToggle &&
        (item.exitPrice === "" || item.exitPrice === undefined)
      ) {
        swal({
          title: `Enter Exit Price for Leg ${index + 1}`,
          text: "Leg cannot be closed without Exit Price",
          icon: "info",
        });
        error = true;
      }
    });

    if (!error) {
      this.setState({
        bulishSaveProceedLeft: true,
        bulishSaveProceedRight: true,
        leftComponent: false,
        bulishRightComponent: false,
        backBtn: true,
      });
    }
  };

  optionBackBtn = () => {
    this.setState({
      bulishSaveProceedLeft: false,
      bulishSaveProceedRight: false,
      leftComponent: true,
      bulishRightComponent: true,
      backBtn: false,
    });
  };

  optionsPage2LotSize = (e) => {
    this.setState({
      page2LotSize: e.target.value
        .split("")
        .filter((item) => item.match(/[0-9\\.]/i))
        .join(""),
    });
  };

  //old
  // Option bulish schudule > button
  // bulishScheduleBtn = async () => {
  //   let data = {
  //     optionData: [
  //       {
  //         experienced: this.state.experienced ? "Experienced" : "",
  //         newbie: this.state.newbie ? "Newbie" : "",
  //         both: this.state.common ? "Both" : "",
  //         option: this.state.option ? "Option" : "",
  //         optionsBulish: this.state.optionsBulish ? "Bullish" : "",
  //         optionsBearish: this.state.optionsBearish ? "Bearish" : "",
  //         optionsNeutral: this.state.optionsNeutral ? "Neutral" : "",
  //         optionsSelect: this.state.optionsSelect,
  //         optionCompanyName: this.state.optionCompanyName,
  //         optionCompanySymbol: this.state.optionCompanyNameId,
  //         optionPriceValue: this.state.optionPriceValue,
  //         legsArray: [this.state.legsArray],
  //         parMaxProfit: this.state.parMaxProfit,
  //         page2LotSize: this.state.page2LotSize,
  //         parMaxLoss: this.state.parMaxLoss,
  //         parLotSize: this.state.parLotSize,
  //         parExpectedProfit: this.state.parExpectedProfit,
  //         parCapitalRequied: this.state.parCapitalRequied,
  //         parStopLoss: this.state.parStopLoss,
  //         parSelectValue: this.state.parSelectValue,
  //         seriesSelect: this.state.seriesSelect,
  //         conditionsSelect: this.state.conditionsSelect,
  //         optionPlan: this.state.optionPlan,
  //         optionNow: this.state.optionNow,
  //         optionSchedule: this.state.optionSchedule,
  //         optionTLPointArray: [this.state.optionTLPointArrayNew],
  //         optionNotes: this.state.optionNotes,
  //         callStatus: this.state.callStatus,
  //       },
  //     ],
  //   };
  //   this.setState({
  //     optionData: data.optionData,
  //     scheduleBtnDisable: true,
  //   });

  //   if (
  //     !this.state.modifyHeader &&
  //     (await createCall(data.optionData, this.setScheduleDisable))
  //   ) {
  //     this.props.history.push("/calls");
  //   }
  //   if (
  //     this.state.modifyHeader &&
  //     (await updateCall(
  //       data.optionData,
  //       this.state.optionId,
  //       this.setScheduleDisable
  //     ))
  //   ) {
  //     this.props.history.push("/calls");
  //   }
  // };

  //new
  // Option bulish schudule > button
  bulishScheduleBtn = async () => {
    console.log(this.state.optionPlan);
    if (this.state.optionPlan === null) {
      swal({
        text: "Select a plan",
        icon: "error",
      });
    } else if (
      this.state.optionNow === "" &&
      this.state.optionSchedule === ""
    ) {
      swal({
        text: "Select a publish type",
        icon: "error",
      });
    } else {
      let data = {
        optionData: [
          {
            experienced: this.state.experienced ? "Experienced" : "",
            newbie: this.state.newbie ? "Newbie" : "",
            both: this.state.common ? "Both" : "",
            option: this.state.option ? "Option" : "",
            optionsBulish: this.state.optionsBulish ? "Bullish" : "",
            optionsBearish: this.state.optionsBearish ? "Bearish" : "",
            optionsNeutral: this.state.optionsNeutral ? "Neutral" : "",
            optionsBidirectional: this.state.optionsBidirectional
              ? "Bi-Di"
              : "",
            optionsSelect: this.state.optionsSelect,
            optionCompanyName: this.state.optionCompanyName,
            optionCompanySymbol: this.state.optionCompanyNameId,
            optionPriceValue: this.state.optionPriceValue,
            legsArray: [this.state.legsArray],
            parMaxProfit: this.state.parMaxProfit,
            page2LotSize: this.state.page2LotSize,
            parMaxLoss: this.state.parMaxLoss,
            parLotSize: this.state.parLotSize,
            parExpectedProfit: this.state.parExpectedProfit,
            parCapitalRequied: this.state.parCapitalRequied,
            parStopLoss: this.state.parStopLoss,
            parSelectValue: this.state.parSelectValue,
            seriesSelect: this.state.seriesSelect,
            conditionsSelect: this.state.conditionsSelect,
            optionPlan: this.state.modifyOptionPlan ? this.state.modifyOptionPlan : this.state.optionPlan,
            optionNow: this.state.optionNow,
            optionSchedule: this.state.optionSchedule,
            optionTLPointArray: [this.state.optionTLPointArrayNew],
            optionNotes: this.state.optionNotes,
            notify: this.state.notify,
            callStatus: this.state.callStatus,
            enter_status: this.state.enter_status,
            payoffcharturl: this?.state?.payoffcharturl,
            show_legs: this.state.show_legs,
            call_version: this.state.call_version,
          },
        ],
      };
      this.setState({
        optionData: data.optionData,
        scheduleBtnDisable: true,
      });

      if (
        !this.state.modifyHeader &&
        (await createCall(data.optionData, this.setScheduleDisable))
      ) {
        this.props.history.push("/calls");
      }
      if (
        this.state.modifyHeader &&
        (await updateCall(
          data.optionData,
          this.state.optionId,
          this.setScheduleDisable
        ))
      ) {
        this.props.history.push('/calls');
      }
    }
  };

  // Close call > component
  callOnChange = (parameterTxt) => {
    this.setState({
      closeProfitChecked: false,
      closeLossChecked: false,
      closeBrokenEvenChecked: false,
    });
    this.setState({
      [parameterTxt]: true,
    });
  };

  equityTradeLogicEdit = () => {
    let equityTLPointArray = this.state.equityTLPointArray;
    let equityTLPointArrayNew = this.state.equityTLPointArrayNew;

    equityTLPointArray.splice(
      equityTLPointArray.indexOf(equityTLPointArrayNew[0])
    );
    this.setState({ equityTLPointArray, equityTLPointArrayNew: [] });
    return equityTLPointArrayNew;
  };

  equityTradeLogicSubmit = (img, smallImage) => {
    if (this.state.equityTradeLogic !== "") {
      this.setState({
        equityTLPointArray: [
          ...this.state.equityTLPointArray,
          {
            img: img,
            smallImageurl: smallImage,
            text: this.state.equityTradeLogic,
          },
        ],
        equityTLPointArrayNew: [
          {
            img: img,
            smallImageurl: smallImage,
            text: this.state.equityTradeLogic,
          },
        ],
      });
      document.getElementById("trade-note").value = "";
      this.state.equityTradeLogic = "";
    }
  };

  optionTradeLogicEdit = () => {
    let optionTLPointArray = this.state.optionTLPointArray;
    let optionTLPointArrayNew = this.state.optionTLPointArrayNew;

    optionTLPointArray.splice(
      optionTLPointArray.indexOf(optionTLPointArrayNew[0])
    );
    this.setState({ optionTLPointArray, optionTLPointArrayNew: [] });
    return optionTLPointArrayNew;
  };
  onPayChartEdit = (url) => {
    this.setState({
      payoffcharturl: url,
    });
  };

  optionTradeLogicSubmit = (img, smallImage) => {
    if (this.state.optionTradeLogic !== "") {
      this.setState({
        // optionTLPointArray: [...this.state.optionTLPointArray, this.state.optionTradeLogic],
        optionTLPointArray: [
          ...this.state.optionTLPointArray,
          {
            img: img,
            smallImageurl: smallImage,
            text: this.state.optionTradeLogic,
          },
        ],
        optionTLPointArrayNew: [
          {
            img: img,
            smallImageurl: smallImage,
            text: this.state.optionTradeLogic,
          },
        ],
      });
      document.getElementById("trade-note").value = "";
      this.state.optionTradeLogic = "";
    }
  };

  submitDisabled = () => {
    var ifDisabled = true;
    if (
      this.state.equityTradeLogic.length === 0 &&
      this.state.optionTradeLogic.length === 0
    ) {
      ifDisabled = true;
    } else {
      ifDisabled = false;
    }
    return ifDisabled;
  };

  callClosePopup = async (notes, total = 0) => {
    await Swal.fire({
      title: "Are you sure you want close?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let close_type = "Break Even";
        if (this.state.closeProfitChecked) {
          close_type = "Profit";
        } else if (this.state.closeLossChecked) {
          close_type = "Loss";
        }
        let data = {
          id: this.state.closeId,
          close_type,
          close_total: total,
          close_notes: notes,
        };

        if (await closeCall(data)) {
          this.props.history.push("/calls");
          Swal.fire("Closed", "Your file has been closed.", "success");
        }
      }
    });
  };

  
  getLtpPrice = async (name, type, legsArray) => {
    // if
    // console.log("legsInfo", this.state.optionCompanyName);
    // if(type == 'equity'){

    //   let response = await searchInstrumentID(name);
    //   this.setState({
    //     entryPrice:response?.last_price,
    //     entryPriceQuantity:response?.last_price
    //   })
    // }else{

    const updatedLegs = await Promise.all(
      legsArray.map(async (elem) => {
          // Check if the element with this id exists in the legsArray
          // const existingElem = legsArray?.find(e => e.id == elem.id);

          // console.log("existingElem", existingElem);
          
          
          // // If the element exists, return it without modification
          // if (existingElem) {
          //     return existingElem;
          // }

          if(elem?.id){
            return elem
          }else{

            let response = await getInstrumentDataBySeries(
                name,
                moment(elem?.series).format("Do MMM'YY"),
                `${elem?.enterStrikePrice + elem?.legOption}`
            );
  
            return {
                ...elem,
                enterPrice1: response?.last_price, 
                enterPrice2: response?.last_price 
            };
          }
          // Otherwise, modify the element
      })
  );

  this.setState({
      legsArray: updatedLegs
  });

  console.log("updatedLegs", updatedLegs);
      // if(legsInfo?.legOption  == "FUTURES"){
      //   if (this.state.optionCompanyName && legsInfo?.series && legsInfo?.legOption) {
      //     this.setState({fetchLtpLoading:true})
      //     let response = await getInstrumentDataBySeries(
      //       name,
      //       moment(legsInfo?.series).format("Do MMM'YY"),
      //       `${legsInfo?.enterStrikePrice + legsInfo?.legOption}`
      //     );
      
          
      //     const addData = {...legsInfo, enterPrice1:response?.last_price, enterPrice2:response.last_price }; 
      //     const findIndex = this.state.legsArray?.findIndex((elem)=> elem?.enterStrikePrice == addData?.enterStrikePrice && elem?.legSelect == addData?.legSelect)
      //     console.log("response",findIndex, addData, response?.last_price);
      //     this.setState({fetchLtpLoading:false})
      //   if (findIndex !== -1) {
      //     const updatedLegsArray = [
      //       ...this.state.legsArray.slice(0, findIndex),
      //       { ...this.state.legsArray[findIndex], ...addData },
      //       ...this.state.legsArray.slice(findIndex + 1)
      //     ];
      
      //     console.log("updatedLegsArray", updatedLegsArray);
          
      //     this.setState({
      //       legsArray: updatedLegsArray
      //     });
      //   } else {
      //     // If the object is not found, you can decide whether to add it or handle the error
      //     this.setState({
      //       legsArray: [...this.state.legsArray, addData]
      //     });
      //   }
      //   }else{
      //     swal({
      //       text: !legsInfo?.series ? `Series field cannot be empty` : !legsInfo?.enterStrikePrice ?`Strike price field cannot be empty` : !legsInfo?.legOption ? `Option field cannot be empty` : 'field cannot be empty' ,
      //       icon: "error",
      //     });
      //   }
      // }else{
      //   if (this.state.optionCompanyName && legsInfo?.series && legsInfo?.enterStrikePrice && legsInfo?.legOption) {
      //     this.setState({fetchLtpLoading:true})
      //     let response = await getInstrumentDataBySeries(
      //       this.state.optionCompanyName,
      //       moment(legsInfo?.series).format("Do MMM'YY"),
      //       `${legsInfo?.enterStrikePrice + legsInfo?.legOption}`
      //     );
      
          
      //     const addData = {...legsInfo, enterPrice1:response?.last_price, enterPrice2:response.last_price }; 
      //     const findIndex = this.state.legsArray?.findIndex((elem)=> elem?.enterStrikePrice == addData?.enterStrikePrice && elem?.legSelect == addData?.legSelect)
      //     console.log("response",findIndex, addData, response?.last_price);
      //     this.setState({fetchLtpLoading:false})
      //   if (findIndex !== -1) {
      //     const updatedLegsArray = [
      //       ...this.state.legsArray.slice(0, findIndex),
      //       { ...this.state.legsArray[findIndex], ...addData },
      //       ...this.state.legsArray.slice(findIndex + 1)
      //     ];
      
      //     console.log("updatedLegsArray", updatedLegsArray);
          
      //     this.setState({
      //       legsArray: updatedLegsArray
      //     });
      //   } else {
      //     // If the object is not found, you can decide whether to add it or handle the error
      //     this.setState({
      //       legsArray: [...this.state.legsArray, addData]
      //     });
      //   }
      //   }else{
      //     swal({
      //       text: !legsInfo?.series ? `Series field cannot be empty` : !legsInfo?.enterStrikePrice ?`Strike price field cannot be empty` : !legsInfo?.legOption ? `Option field cannot be empty` : 'field cannot be empty' ,
      //       icon: "error",
      //     });
      //   }
      // }
    // }
    
  
  };
  

  render() {
    return this.state.tempLoad ? (
      <>
        <section className="newcall-parent">
          {/* <h1 className="head text-uppercase m-0">New call</h1> */}
          <h1
            className={`head text-uppercase m-0 ${this.state.closeCall ? "d-none" : "d-block"
              } ${this.state.callDetails ? "d-none" : ""}`}
          >
            <>
              {this.state.backBtn ? (
                <>
                  <FaChevronLeft
                    className="back-btn"
                    onClick={() => {
                      if (this.state.equity) {
                        this.backBtn();
                      } else {
                        this.optionBackBtn();
                      }
                    }}
                  />
                  &nbsp;
                </>
              ) : null}
              {this.state.modifyHeader ? "Modify Call" : "new call"}
            </>
          </h1>
          <h1
            className={`head text-uppercase m-0 ${this.state.callDetails ? "d-block" : "d-none"
              } ${this.state.closeCall ? "d-none" : ""}`}
          >
            call details
          </h1>
          <h1
            className={`head text-uppercase m-0 ${this.state.closeCall ? "d-block" : "d-none"
              }`}
          >
            Calls
          </h1>

          <section className="row">
            {/* old  */}
            {/* <section className="col-lg-4 leftComponentWidth"> */}
            {/* {this.state.leftComponent ? (
                <LeftComponent
                  state={this.state}
                  updatePlanId={this.updatePlanId}
                  updateEquityPlan={this.updateEquityPlan}
                  callClass={this.callClass}
                  typeofCall={this.typeofCall}
                  autoCompleteFun={this.autoCompleteFun}
                  autoCompleteFun1={this.autoCompleteFun1}
                  rightComponentShowFun={this.rightComponentShowFun}
                  editBtn={this.editBtn}
                  callTarget={this.callTarget}
                  callUserType={this.callUserType}
                  optionsSelectOnChange={this.optionsSelectOnChange}
                  optionRightComponentShowFun={this.optionRightComponentShowFun}
                  optionCallEdit={this.optionCallEdit}
                  optionWSEdit={this.optionWSEdit}
                  optionsTypeofCall={this.optionsTypeofCall}
                  updateCallStatus={this.updateCallStatus}
                  updateCallTarget={this.updateCallTarget}
                />
              ) : (
                ""
              )} */}

            {/* new  */}
            {this.state.callDetails ? (
              <section className="col-lg-4 leftComponentWidth1">
                <CallDetails state={this.state} props={this.props} />
              </section>
            ) : (
              <section className="col-lg-4 leftComponentWidth">
                {this.state.leftComponent ? (
                  <LeftComponent
                    state={this.state}
                    callClass={this.callClass}
                    typeofCall={this.typeofCall}
                    updatePlanId={this.updatePlanId}
                    autoCompleteFun={this.autoCompleteFun}
                    autoCompleteFun1={this.autoCompleteFun1}
                    updateEquityPlan={this.updateEquityPlan}
                    rightComponentShowFun={this.rightComponentShowFun}
                    editBtn={this.editBtn}
                    callTarget={this.callTarget}
                    optionsSelectOnChange={this.optionsSelectOnChange}
                    optionsSelectType={this.optionsSelectType}
                    optionsDeselect={this.optionsDeselect}
                    optionRightComponentShowFun={
                      this.optionRightComponentShowFun
                    }
                    callUserType={this.callUserType}
                    optionCallEdit={this.optionCallEdit}
                    optionWSEdit={this.optionWSEdit}
                    optionsTypeofCall={this.optionsTypeofCall}
                    updateNotify={this.updateNotify}
                    updateCallStatus={this.updateCallStatus}
                    updateCallEnterStatus={this.updateCallEnterStatus}
                    updateCallTarget={this.updateCallTarget}
                  />
                ) : (
                  ""
                )}
                {this.state.equitySaveProceedLeftComponent ? (
                  <EquitySaveProceedLeft state={this.state} />
                ) : (
                  ""
                )}
                {this.state.bulishSaveProceedLeft ? (
                  <BulishSaveProceedLeft state={this.state} />
                ) : (
                  ""
                )}
              </section>
            )}

            {/* old  */}
            {/* {this.state.equitySaveProceedLeftComponent ? (
                <EquitySaveProceedLeft state={this.state} />
              ) : (
                ""
              )}
              {this.state.bulishSaveProceedLeft ? (
                <BulishSaveProceedLeft state={this.state} />
              ) : (
                ""
              )}
              {this.state.callDetails ? (
                <CallDetails state={this.state} props={this.props} />
              ) : (
                ""
              )} */}

            {/* old  */}
            {/* </section> */}
            {/* <section className="col-lg-7 rightComponentWidth">
              {this.state.rightComponent ? (
                <RightComponent
                  state={this.state}
                  handleChange={this.handleChange}
                  TargetField={this.TargetField}
                  inputOnchange={this.inputOnchange}
                  checkDisabled={this.checkDisabled}
                  targetDeleteBtn={this.targetDeleteBtn}
                />
              ) : (
                ""
              )}
              {this.state.bulishPriceComponent ? (
                <BulishPriceVolume
                  state={this.state}
                  priceExitBtn={this.priceExitBtn}
                />
              ) : (
                ""
              )}
              {this.state.equitySaveProceedRightComponent ? (
                <EquitySaveProceedRight
                  state={this.state}
                  toggleClass={this.toggleClass}
                  resetNow={this.resetNow}
                  forCheckedFields={this.forCheckedFields}
                  tradeNoteOnchange={this.tradeNoteOnchange}
                  equityTradeLogicSubmit={this.equityTradeLogicSubmit}
                  equityTradeLogicEdit={this.equityTradeLogicEdit}
                  submitDisabled={this.submitDisabled}
                  capitalOnChange={this.capitalOnChange}
                  fetchAndPolupateEquityTLPointArray={
                    this.fetchAndPolupateEquityTLPointArray
                  }
                />
              ) : (
                ""
              )}
              {this.state.bulishRightComponent ? (
                <BulishRightComponent
                  state={this.state}
                  callsPutsSelectOnchange={this.callsPutsSelectOnchange}
                  legsSelectOnchange={this.legsSelectOnchange}
                  legsSelectOptionOnchange={this.legsSelectOptionOnchange}
                  legsSelectOptionOnchange1={this.legsSelectOptionOnchange1}
                  legFieldsOnchange={this.legFieldsOnchange}
                  legsEdit={this.legsEdit}
                  legsDoneBtn={this.legsDoneBtn}
                  parDataOnChange={this.parDataOnChange}
                  totalCallEditBtn={this.totalCallEditBtn}
                  optionAddBtn={this.optionAddBtn}
                  legDeleteClick={this.legDeleteClick}
                  parSelectOnChange={this.parSelectOnChange}
                />
              ) : (
                ""
              )}
              {this.state.bulishSaveProceedRight ? (
                <BulishSaveProceedRight
                  state={this.state}
                  select1OnChange={this.select1OnChange}
                  resetOptionNow={this.resetOptionNow}
                  select2OnChange={this.select2OnChange}
                  optionForCheckedFields={this.optionForCheckedFields}
                  optionToggleClass={this.optionToggleClass}
                  tradeNoteOnchange={this.tradeNoteOnchange}
                  optionTradeLogicSubmit={this.optionTradeLogicSubmit}
                  submitDisabled={this.submitDisabled}
                  optionsPage2LotSize={this.optionsPage2LotSize}
                  optionTradeLogicEdit={this.optionTradeLogicEdit}
                />
              ) : (
                ""
              )}
              {this.state.closeCall ? (
                <CloseCall
                  state={this.state}
                  callOnChange={this.callOnChange}
                  callClosePopup={this.callClosePopup}
                  props={this.props}
                />
              ) : (
                ""
              )}
            </section> */}

            {/* new  */}
            {this.state.closeCall ? (
              <section className="col-lg-7 rightComponentWidth">
                <CloseCall
                  state={this.state}
                  callOnChange={this.callOnChange}
                  callClosePopup={this.callClosePopup}
                  props={this.props}
                />
              </section>
            ) : (
              <section className="col-lg-7 rightComponentWidth">
                {this.state.rightComponent ? (
                  <RightComponent
                    state={this.state}
                    handleChange={this.handleChange}
                    handleChangeSelect={this.handleChangeSelect}
                    TargetField={this.TargetField}
                    inputOnchange={this.inputOnchange}
                    checkDisabled={this.checkDisabled}
                    targetDeleteBtn={this.targetDeleteBtn}
                    updateNotify={this.updateNotify}
                    // getLtpPrice={this.getLtpPrice}
                  />
                ) : (
                  ""
                )}
                {this.state.bulishPriceComponent ? (
                  <BulishPriceVolume
                    state={this.state}
                    priceExitBtn={this.priceExitBtn}
                  />
                ) : (
                  ""
                )}
                {this.state.equitySaveProceedRightComponent ? (
                  <EquitySaveProceedRight
                    state={this.state}
                    toggleClass={this.toggleClass}
                    resetNow={this.resetNow}
                    forCheckedFields={this.forCheckedFields}
                    tradeNoteOnchange={this.tradeNoteOnchange}
                    equityTradeLogicSubmit={this.equityTradeLogicSubmit}
                    equityTradeLogicEdit={this.equityTradeLogicEdit}
                    submitDisabled={this.submitDisabled}
                    capitalOnChange={this.capitalOnChange}
                    fetchAndPolupateEquityTLPointArray={
                      this.fetchAndPolupateEquityTLPointArray
                    }
                  />
                ) : (
                  ""
                )}
                {this.state.bulishRightComponent ? (
                  <BulishRightComponent
                    state={this.state}
                    callsPutsSelectOnchange={this.callsPutsSelectOnchange}
                    legsSelectOnchange={this.legsSelectOnchange}
                    legsSelectOptionOnchange={this.legsSelectOptionOnchange}
                    legsSelectOptionOnchange1={this.legsSelectOptionOnchange1}
                    legFieldsOnchange={this.legFieldsOnchange}
                    legFieldsOnchangeBoolean={this.legFieldsOnchangeBoolean}
                    showCmpOnchangeBoolean={this.showCmpOnchangeBoolean}
                    changeshowlegs={this.changeshowlegs}
                    legsEdit={this.legsEdit}
                    legsDoneBtn={this.legsDoneBtn}
                    parDataOnChange={this.parDataOnChange}
                    totalCallEditBtn={this.totalCallEditBtn}
                    optionAddBtn={this.optionAddBtn}
                    legDeleteClick={this.legDeleteClick}
                    updateNotify={this.updateNotify}
                    parSelectOnChange={this.parSelectOnChange}
                    getLtpPrice={this.getLtpPrice}
                  />
                ) : (
                  ""
                )}
                {this.state.bulishSaveProceedRight ? (
                  <BulishSaveProceedRight
                    state={this.state}
                    select1OnChange={this.select1OnChange}
                    resetOptionNow={this.resetOptionNow}
                    select2OnChange={this.select2OnChange}
                    optionForCheckedFields={this.optionForCheckedFields}
                    optionToggleClass={this.optionToggleClass}
                    tradeNoteOnchange={this.tradeNoteOnchange}
                    optionTradeLogicSubmit={this.optionTradeLogicSubmit}
                    submitDisabled={this.submitDisabled}
                    optionsPage2LotSize={this.optionsPage2LotSize}
                    optionTradeLogicEdit={this.optionTradeLogicEdit}
                    onPayChartEdit={this.onPayChartEdit}
                    updatePayOffChart={this?.updatePayOffChart}
                  />
                ) : (
                  ""
                )}
              </section>
            )}
          </section>

          {/* Equity Bottom button > start */}
          <section
            className={`btm-strip ${this.state.rightComponent ? "d-block" : "d-none"
              }`}
          >
            <aside className="proceed-btn-group">
              <a
                onClick={(e) => this.saveProceedBtn(e)}
                className={`${this.checkDisabled() ? "disabled" : ""}`}
                title="SAVE &amp; PROCEED"
              >
                save &amp; proceed
              </a>
            </aside>
          </section>
          <section
            className={`btm-strip ${this.state.equitySaveProceedRightComponent ? "d-block" : "d-none"
              }`}
          >
            <aside className="proceed-btn-group">
              <a
                onClick={this.scheduleBtnClick}
                title="SCHEDULE"
                className={this.state.scheduleBtnDisable ? "disabled" : ""}
              >
                Schedule
              </a>
            </aside>
          </section>
          {/* Equity Bottom button > end */}

          {/* Option Bottom button > start */}
          <section
            className={`btm-strip ${this.state.bulishRightComponent ? "d-block" : "d-none"
              }`}
          >
            <aside className="proceed-btn-group">
              <a title="SAVE &amp; PROCEED" onClick={this.optionSaveProceedBtn}>
                Save &amp; proceed
              </a>
            </aside>
          </section>
          <section
            className={`btm-strip ${this.state.bulishSaveProceedLeft ? "d-block" : "d-none"
              } ${this.state.bulishSaveProceedRight ? "d-block" : "d-none"}`}
          >
            <aside className="proceed-btn-group">
              <a
                title="SCHEDULE"
                onClick={this.bulishScheduleBtn}
                className={this.state.scheduleBtnDisable ? "disabled" : ""}
              >
                Schedule
              </a>
            </aside>
          </section>
          {/* Option Bottom button > end */}
        </section>
      </>
    ) : null;
  }
}

export default Newcall;
