const constant = {
  baseUrl: "https://api.production.hedged.in",
  // baseUrl: "https://api.staging.hedged.in",
  // baseUrl: "http://localhost:5001",
  // "proxy": "http://localhost:5001/"
  // baseUrl: "http://192.168.1.34:5001",
  username_cinema_app: "appuser@injin.com",
  password_cinema_app: "N_w?Z92w%-BuLvKX",
  // swaggerUrl: "https://staging-dot-orders-hedged-in.appspot.com/",
  swaggerUrl: "https://orders.hedged.in/",
  swaggerSystemToken: "1d015957f3874ede91ed7ba1325494db1bafc9d45fa04152a382d772d5ce4166"
};
export default constant;

export const vimeoBearerToken = "40fb8d6ce2255a0b615a31ea441138d8";
