import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import Form from 'react-bootstrap/Form';
import * as API from "../../configuration/apiconfig";

const callsPutsValue = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

const legsValue = [
  { value: "BUY", label: "BUY" },
  { value: "SELL", label: "SELL" },
];

const legsOption = [
  { value: "CE", label: "CE" },
  { value: "PE", label: "PE" },
  { value: "FUTURES", label: "FUTURES" },
];

const stopLossTypeSelect = [
  { value: "Value", label: "Value" },
  { value: "Price", label: "Price" },
];

const DateCustomInput = ({ onClick, value }) => {
  //console.log(value, moment(value).format("Do MMM'YY"));
  return (
    <React.Fragment>
      <div className="form-group form-group1  ">
        <input
          value={value === "" ? "" : moment(value).format("Do MMM'YY")}
          className="legsItemWidth1 legs-series"
          onClick={onClick}
          placeholder="Select Date"
        ></input>
      </div>
    </React.Fragment>
  );
};

class SystemBulishRightComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: true,
      exitCMP: 0,
    };
    this.timeoutId = null;
  }

  enableCronForExitPrice() {
    this.fetchCMPForStock();
    // console.log("enabled", new Date().toLocaleTimeString());

    // this.timeoutId = setInterval(() => {
    //   console.log("timeout", new Date().toLocaleTimeString());
    //   this.fetchCMPForStock();
    // }, 5000); // 2 seconds delay
  }
  clearCronJob() {
    // clearInterval(this.timeoutId);
  }
  componentWillUnmount() {
    // clearInterval(this.timeoutId);
  }

  fetchCMPForStock(legindex) {
    API.callEndpoint(
      "GET",
      "Bearer",
      `/api/calls/getCMP?stock=${this.props?.state?.optionCompanyNameId}`
    )
      .then((response) => {
        console.log(response?.data?.price);
        this.setState({
          exitCMP: response?.data?.price,
        });
        this.props.state.legsArray.map((leg, index) => {
          if (leg?.exitToggle && legindex == index) {
            this.props.legFieldsOnchange(
              {
                target: {
                  name: "exitPrice",
                  value: response?.data?.price,
                },
              },
              index
            );
          }
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
  }
  componentDidMount() {
    console.log(this.props?.state?.optionCompanyNameId);
  }
  render() {
    return (
      <section className="option-rgt-blk">

        <section className="add-legs-blk">
          <p>Add Legs</p>
          <aside
            className="plus-btn"
            onClick={(e) => this.props.optionAddBtn(e)}
          >
            <span>&nbsp;</span>
          </aside>
        </section>


        <section className="leg-blk">
          {this.props.state.legsArray.map((legs, index) => {
            // index = this.props.state.legsArray.length - (index + 1);
            return (
              <section
                className={`form-blk ${legs.exitToggle ? `gray-leg-bg ` : ``}`}
                key={index}
              >
                <h2 className={` ${legs.exitToggle ? `head-gray-bg` : ``}`}>
                  Leg {index + 1}
                </h2>
                <div
                  className={`form-group ps-0 colmb ${legs.exitToggle ? `call-leg-grey` : ``
                    }`}
                >
                  <div
                    className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                      }`}
                  >
                    Action
                  </div>
                  <Select
                    isDisabled={legs.exitToggle}
                    className="legsItemWidth"
                    onChange={this.props.legsSelectOnchange(index, "legSelect")}
                    options={legsValue}
                    placeholder="Select"
                    value={legsValue.filter(
                      (item) => item.value === legs.legSelect
                    )}
                  //menuIsOpen={this.state.menuIsOpen}
                  />
                </div>

                <div className="form-group colmb">
                  <div
                    className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                      } ${legs.legOption === "FUTURES" && `head-gray-bg`}`}
                  >
                    Strike Price
                  </div>
                  <input
                    disabled={legs.exitToggle || legs.legOption === "FUTURES"}
                    type="text"
                    className={`form-control legsItemWidth ${legs.exitToggle
                      ? `legs-option-call-grey`
                      : `legs-option-call-black`
                      }`}
                    name="enterStrikePrice"
                    onChange={(e) => this.props.legFieldsOnchange(e, index)}
                    placeholder="Strike Price"
                    autoComplete="off"
                    value={legs.enterStrikePrice}
                  />
                </div>
                <div
                  className={`form-group  colmb ${legs.exitToggle ? `call-leg-grey` : ``
                    }`}
                >
                  <div
                    className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                      }`}
                  >
                    Option
                  </div>
                  <Select
                    isDisabled={legs.exitToggle}
                    className="legsItemWidth"
                    onChange={this.props.legsSelectOptionOnchange(
                      index,
                      "legOption"
                    )}
                    options={legsOption}
                    placeholder="Select"
                    value={legsOption.filter(
                      (item) => item.value === legs.legOption
                    )}
                  />
                </div>
                <div
                  className={`form-group form-group1  colmb ${legs.exitToggle
                    ? `legs-option-call-grey`
                    : `legs-option-call-black`
                    }`}
                >
                  <div
                    className={`call-field-header ${legs.exitToggle ? `head-gray-bg ` : ``
                      }`}
                  >
                    Series
                  </div>
                  {/* <Select
                    className="legsItemWidth1 "
                    onChange={this.props.legsSelectOptionOnchange(
                      index,
                      "series"
                    )}
                   
                    options={this.props.listOfLastThursdays}
                    placeholder="Select"
                    value={this.props.listOfLastThursdays.filter(
                      (item) => item.value === legs.series
                    )}
                  /> */}
                  <DatePicker
                    readOnly={legs.exitToggle}
                    className="legsItemWidth1"
                    selected={legs.series}
                    showMonthDropdown
                    showYearDropdown
                    onChange={this.props.legsSelectOptionOnchange1(
                      index,
                      "series"
                    )}
                    customInput={<DateCustomInput toggle={legs.exitToggle} />}
                  />
                </div>
                <div className="form-group colmb">
                  <div
                    className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                      }`}
                  >
                    Min Price
                  </div>
                  <input
                    disabled={legs.exitToggle}
                    type="text"
                    className={`form-control legsItemWidth ${legs.exitToggle
                      ? `legs-option-call-grey`
                      : `legs-option-call-black`
                      }`}
                    name="enterPrice1"
                    onChange={(e) => this.props.legFieldsOnchange(e, index)}
                    placeholder="Min Price"
                    autoComplete="off"
                    value={legs.enterPrice1}
                  />
                </div>
                <div className="form-group colmb">
                  <div
                    className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                      }`}
                  >
                    Max Price
                  </div>
                  <input
                    disabled={legs.exitToggle}
                    type="text"
                    className={`form-control legsItemWidth ${legs.exitToggle
                      ? `legs-option-call-grey`
                      : `legs-option-call-black`
                      }`}
                    name="enterPrice2"
                    onChange={(e) => this.props.legFieldsOnchange(e, index)}
                    placeholder="Max Price"
                    autoComplete="off"
                    value={legs.enterPrice2}
                  />
                </div>
                <div className="form-group  colmb">
                  <div
                    className={`call-field-header ${legs.exitToggle ? `head-gray-bg` : ``
                      }`}
                  >
                    Quantity
                  </div>
                  <input
                    disabled={legs.exitToggle}
                    type="text"
                    className={`form-control legsItemWidth ${legs.exitToggle
                      ? `legs-option-call-grey`
                      : `legs-option-call-black`
                      }`}
                    name="entryQty"
                    onChange={(e) => this.props.legFieldsOnchange(e, index)}
                    placeholder="Quantity"
                    autoComplete="off"
                    value={legs.entryQty}
                  />
                </div>
                <div className="form-group w-6 colmb">
                  <div
                    className="close-btn"
                    onClick={() => {
                      if (!legs.exitToggle) {
                        this.props.legDeleteClick(index);
                      }
                    }}
                  >
                    <span>&nbsp;</span>
                  </div>
                </div>
                <div
                  className={`form-group exit-width colmb ${legs.legExit ? `legs-option-call-grey ` : ``
                    }`}
                >
                  <div className={`call-field-header1 `}>Exit</div>
                  <label className="switch">
                    <input
                      disabled={legs.legExit}
                      type="checkbox"
                      name="exitToggle"
                      onChange={(e) => {
                        this.props.legFieldsOnchangeBoolean(e, index);
                        // this.fetchCMPForStock(index)
                        // if (legs.exitToggle) {
                        //   this.enableCronForExitPrice();
                        // } else {
                        //   this.clearCronJob();
                        // }
                      }}
                      checked={legs.exitToggle}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                {legs.exitToggle ? (
                  <div className={`form-group colmb `}>
                    <div
                      className={`call-field-header ${legs.legExit ? `legs-option-call-grey` : ``
                        }`}
                    >
                      Exit Price
                    </div>
                    <input
                      disabled={legs.legExit}
                      type="text"
                      className={`form-control legsItemWidth ${legs.legExit
                        ? `legs-option-call-grey`
                        : `white-bg black-text`
                        }`}
                      name="exitPrice"
                      onChange={(e) => this.props.legFieldsOnchange(e, index)}
                      placeholder="Exit Price"
                      autoComplete="off"
                      value={legs.exitPrice}
                    />
                  </div>
                ) : null}

                <div
                  className={`form-group exit-width colmb ${legs.legExit ? `legs-option-call-grey ` : ``
                    }`}
                >
                  <div className={`call-field-header1 `}>Show CMP Text</div>
                  <label className="switch">
                    <input
                      // disabled={legs.legExit}
                      type="checkbox"
                      name="showCMP"
                      onChange={(e) => {
                        this.props.showCmpOnchangeBoolean(e, index);
                      }}
                      checked={legs.showCMP}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
              </section>
            );
          })}
          {this.props?.state?.modifyHeader ? (
            <div className={`form-group exit-width colmb `}>
              <h6>Will show this legs on the trade updates</h6>
              <label className="switch">
                <input
                  type="checkbox"
                  name="show_legs"
                  onChange={(e) => {
                    this.props.changeshowlegs(e);
                  }}
                  checked={this.props?.state?.show_legs == 0 ? true : false}
                />
                <span className="slider"></span>
              </label>
            </div>
          ) : null}
        </section>

        <section className="call-participat-blk">
          <section className="list-blk">
            <h2>Participation Data</h2>
            <section className="participt-blk">
              {/* <div className="form-group">
                <div className="call-field-header">Sets</div>
                <input
                  type="text"
                  className="form-control"
                  name="sets"
                  onChange={this.props.parDataOnChange}
                  placeholder="Define Sets"
                  value={this.props.state.sets}
                />
              </div> */}
              <div className="form-group">
                <div className="call-field-header">Max Profit</div>
                <input
                  type="text"
                  className="form-control"
                  name="parMaxProfit"
                  onChange={this.props.parDataOnChange}
                  placeholder="Max Profit"
                  value={this.props.state.parMaxProfit}
                />
              </div>
              <div className="form-group">
                <div className="call-field-header">Max Loss</div>
                <input
                  type="text"
                  className="form-control"
                  name="parMaxLoss"
                  onChange={this.props.parDataOnChange}
                  placeholder="Max Loss"
                  value={this.props.state.parMaxLoss}
                />
              </div>
              <div className="form-group">
                <div className="call-field-header">Lot Size</div>
                <input
                  type="text"
                  className="form-control"
                  name="parLotSize"
                  onChange={this.props.parDataOnChange}
                  placeholder="Lot Size"
                  value={this.props.state.parLotSize}
                />
              </div>
              <div className="form-group">
                <div className="call-field-header">Expected Profit</div>
                <input
                  type="text"
                  className="form-control"
                  name="parExpectedProfit"
                  onChange={this.props.parDataOnChange}
                  placeholder="Expected Profit"
                  value={this.props.state.parExpectedProfit}
                />
              </div>
              <div className="form-group">
                <div className="call-field-header">Capital Required</div>
                <input
                  type="text"
                  className="form-control"
                  name="parCapitalRequied"
                  onChange={this.props.parDataOnChange}
                  placeholder="Capital Requied"
                  value={this.props.state.parCapitalRequied}
                />
              </div>
              <div className="form-group">
                <div className="call-field-header">Stop Loss</div>
                <input
                  type="text"
                  className="form-control"
                  name="parStopLoss"
                  onChange={this.props.parDataOnChange}
                  placeholder="Stop Loss"
                  value={this.props.state.parStopLoss}
                />
              </div>
              <div className="form-group">
                <div className="call-field-header">Stop Loss Type</div>
                <Select
                  onChange={this.props.parSelectOnChange}
                  options={stopLossTypeSelect}
                  placeholder="Stop Loss Type"
                  value={stopLossTypeSelect.filter(
                    (item) => item.value === this.props.state.parSelectValue
                  )}
                //menuIsOpen={this.state.menuIsOpen}
                />
              </div>
            </section>
          </section>
          <section className="list-blk">
            <section className="participt-blk">
              {/* <h2>Notify User</h2> */}
              <Form.Check
                onChange={(event) => {
                  this.props.updateNotify(event.target.checked);
                  console.log(event.target.checked, 'check value')
                }}
                label="Notify User"
                name="group1"
                type={"checkbox"}
                checked={this.props.state.notify}
                id={`reverse-checkbox-1`}
                className="notifycheck"
              />

            </section>

          </section>
        </section>
      </section>
    );
  }
}

export default SystemBulishRightComponent;
